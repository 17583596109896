import React, { FormEvent, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { AccountLayout } from 'components';
import setNewPassword from 'services/api/setPassword';
import { Button, H1, MediaQueries, P } from '../../libraries/ui';
import TextInput, { InputType } from '../../libraries/ui/TextInput';

const useStyles = createUseStyles({
  pageTitle: {
    marginTop: 32,
  },
  form: {
    marginTop: 20,
    overflow: 'hidden',
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  [MediaQueries.DESKTOP]: {
    pageTitle: {
      marginTop: 136,
    },
  },
});

function ResetPassword() {
  const intl = useIntl();
  const history = useHistory();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState(false);
  const [matchingError, setMatchingError] = useState(false);
  const { email, token } = useParams<{ email: string; token: string }>();
  const classes = useStyles();

  const confirm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setMatchingError(true);
      return;
    }

    setNewPassword(email, password, token)
      .then(() => history.push('/logout'))
      .catch(() => setError(true));
  };

  return (
    <AccountLayout>
      <H1 className={classes.pageTitle} variant="jumbo" theme="bold">
        <FormattedMessage id="user.setPassword" defaultMessage="Set password" />
      </H1>
      <form className={classes.form} onSubmit={confirm}>
        {error && (
          <P theme="error">
            <FormattedMessage
              id="user.problemSettingPassword"
              defaultMessage="There was a problem setting your password, please try again."
            />
          </P>
        )}
        {matchingError && (
          <P theme="error">
            <FormattedMessage
              id="user.passwordsDontMatch"
              defaultMessage="Passwords don't match, please double check and try again."
            />
          </P>
        )}

        <TextInput
          label={intl.formatMessage({
            id: 'login.password',
            defaultMessage: 'Password',
          })}
          name="password"
          className={classes.input}
          type={InputType.PASSWORD}
          defaultValue={password}
          onChange={(event: any) => setPassword(event.target.value)}
        />

        <TextInput
          label={intl.formatMessage({
            id: 'user.confirmPassword',
            defaultMessage: 'Confirm password',
          })}
          name="password"
          className={classes.input}
          type={InputType.PASSWORD}
          defaultValue={confirmPassword}
          onChange={(event: any) => setConfirmPassword(event.target.value)}
        />

        <Button
          className={classes.button}
          disabled={!email.length}
          type="submit"
        >
          <FormattedMessage
            id="user.setPassword"
            defaultMessage="Set password"
          />
        </Button>
      </form>
    </AccountLayout>
  );
}

export default ResetPassword;
