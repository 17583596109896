import React, { FormEvent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useUnitSystem } from '@hummingbirdtechgroup/wings-unit-conversion';
import authenticate from 'services/api/authenticate';
import { loadState, saveState } from 'services/statePersist/localStore';
import { EmailContext } from 'services/email/EmailProvider';
import { LanguageContext } from 'services/i18n';
import hasTokenExpired from 'services/user/hasTokenExpired';
import { AccountLayout, LegalLinks } from 'components';
import { TOKEN_KEY, USER_KEY } from '../../constants/constants';
import redirect from '../../services/redirect/redirect';
import {
  H1,
  P,
  Li,
  Button,
  SquareLink,
  SquareButton,
  MediaQueries,
} from '../../libraries/ui';
import TextInput, { InputType } from '../../libraries/ui/TextInput';
import { Bubble } from '../../components/icons';

function showIntercomMessenger() {
  if (window.Intercom) {
    window.Intercom('show');
  }
}

const useStyles = createUseStyles({
  pageContent: {
    paddingBottom: 96,
  },
  pageTitle: {
    marginTop: 32,
  },
  form: {
    marginTop: 20,
    overflow: 'hidden',
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  helpLinks: {
    marginTop: 24,
    textAlign: 'center',
  },
  helpLink: {
    marginTop: 8,
  },
  bubbleIcon: {
    position: 'relative',
    height: 15,
    top: 3,
  },
  footer: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
  },
  [MediaQueries.TABLET]: {
    helpLinks: {
      marginTop: 44,
      display: 'flex',
      justifyContent: 'center',
    },
    helpLink: {
      marginTop: 0,
    },
  },
  [MediaQueries.DESKTOP]: {
    pageContent: {},
    pageTitle: {
      marginTop: 136,
    },
    bubbleIcon: {
      height: 19,
      top: 4,
    },
  },
});

function Login(): React.ReactElement {
  const intl = useIntl();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useContext(EmailContext);
  const [language] = useContext(LanguageContext);
  const [error, setError] = useState(false);
  const token = loadState(TOKEN_KEY) || '';
  const userId = loadState(USER_KEY) || null;
  const classes = useStyles();
  const unitSystem = useUnitSystem();

  const handleAuthenticate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    authenticate(email, password)
      .then(res => {
        saveState(TOKEN_KEY, res.auth_token);
        saveState(USER_KEY, res.user.id);
        redirect(res.auth_token, res.user.id, language, unitSystem);
      })
      .catch(() => setError(true));
  };

  if (!hasTokenExpired(token) && userId) {
    redirect(token, userId, language, unitSystem);
    return <>redirecting...</>;
  }

  return (
    <AccountLayout>
      <div className={classes.pageContent}>
        <H1 variant="jumbo" theme="bold" className={classes.pageTitle}>
          <FormattedMessage
            id="login.header"
            defaultMessage="Sign into your account"
          />
        </H1>
        <form className={classes.form} onSubmit={handleAuthenticate}>
          {error && (
            <P theme="error">
              <FormattedMessage
                id="login.invalidEmailAddressOrPassword"
                defaultMessage="Invalid email address or password, please check and try again."
              />
            </P>
          )}

          <TextInput
            label={intl.formatMessage({
              id: 'login.emailPlaceholder',
              defaultMessage: 'Email Address',
            })}
            className={classes.input}
            name="email"
            type={InputType.EMAIL}
            defaultValue={email}
            onChange={(event: any) => setEmail(event.target.value)}
          />
          <TextInput
            label={intl.formatMessage({
              id: 'login.password',
              defaultMessage: 'Password',
            })}
            name="password"
            className={classes.input}
            type={InputType.PASSWORD}
            defaultValue={password}
            onChange={(event: any) => setPassword(event.target.value)}
          />
          <Button
            className={classes.button}
            disabled={!email.length || !password.length}
            type="submit"
          >
            <FormattedMessage id="login.loginAction" defaultMessage="Sign in" />
          </Button>
        </form>

        <ul className={classes.helpLinks}>
          <Li>
            <SquareLink className={classes.helpLink} to="/forgotten-password">
              Forgot password?
            </SquareLink>
          </Li>
          <Li>
            <SquareLink
              className={classes.helpLink}
              href="mailto:help@hummingbirdtech.com"
            >
              Trouble Signing in?
            </SquareLink>
          </Li>
          <Li>
            <SquareButton
              className={classes.helpLink}
              onClick={showIntercomMessenger}
            >
              Chat <Bubble className={classes.bubbleIcon} />
            </SquareButton>
          </Li>
        </ul>
        <footer className={classes.footer}>
          <LegalLinks />
        </footer>
      </div>
    </AccountLayout>
  );
}

export default Login;
