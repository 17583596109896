// code copied from Intercom docs. No need to type or lint them
/* eslint-disable */
// @ts-nocheck

export type IntercomMessengerAttributes = {
  /** The app_id of your Intercom app which will indicate where to store any data */
  app_id: string;
  /**
   * The CSS selector of an element to trigger Intercom("show") in order to activate the messenger (See docs).
   * To target an element by ID: "#id_of_element".
   * To target elements by class `.classname_of_elements`
   */
  custom_launcher_selector?: string;
  /** Dictate the alignment of the default launcher icon to be on the left/right. */
  alignment?: 'left' | 'right';
  /**
   * Move the default launcher icon vertically. Padding from bottom of screen.
   * Minimum value: 20.
   * Does not work on mobile.
   */
  vertical_padding?: number;
  /**
   * Move the default launcher icon horizontally.
   * Padding from right side of screen Minimum value: 20.
   * Does not work on mobile.
   */
  horizontal_padding?: number;
  /** Hide the default launcher icon. Setting to false will forcefully show the launcher icon */
  hide_default_launcher?: boolean;
  /**
   * Time in milliseconds for the Intercom session to be considered active.
   * A value of 5 * 60 * 1000 would set the expiry time to be 5 minutes
   */
  session_duration?: number;
  /** Used in button links and more to highlight and emphasise.  The color string can be any valid CSS Color Name HEX or RGB */
  action_color?: string;
  /** Used behind your team profile and other attributes.  The color string can be any valid CSS Color Name HEX or RGB */
  background_color?: string;
};

export function initialiseIntercom(settings: IntercomMessengerAttributes) {
  const existingScript = document.getElementById('intercom');

  if (!existingScript) {
    window.intercomSettings = settings;

    const intercomScript = document.createElement('script');
    intercomScript.id = 'intercom';
    document.body.append(intercomScript);

    intercomScript.innerText = (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${settings.app_id}`;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
}
