import { useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import getCompanyFromEmail from '../user/getCompanyFromEmail';
import { LanguageContext } from '../i18n';
import { EmailContext } from '../email/EmailProvider';

function SentryScope(): null {
  const [email] = useContext(EmailContext);
  const [language] = useContext(LanguageContext);

  useEffect(() => {
    const company = getCompanyFromEmail(email);
    Sentry.configureScope(scope => {
      scope.setUser({ email });
      scope.setUser({ email });
      scope.setTag('page_locale', language);
      scope.setExtra('user_company', company);
    });
  }, [email, language]);

  return null;
}

export default SentryScope;
