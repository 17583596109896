/**
 * Hook makes use of the JS API from Intercom
 * Documentation founds here: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#
 * Adding Custom attributes: https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
 */

/* eslint-disable new-cap */
import { useEffect } from 'react';
import { intercomAction } from './actions';
import { initialiseIntercom } from './initialiseIntercom';

// todo: Add the appId to process.env - SFR 2021-03-01
const appId = 'e475mi00';
function useIntercomMessenger(): null {
  useEffect(() => {
    initialiseIntercom({
      app_id: appId,
      alignment: 'right',
      background_color: '#24b9a9',
      action_color: '#24b9a9',
      hide_default_launcher: true,
    });

    return () => intercomAction('shutdown');
  }, []);

  return null;
}

export default useIntercomMessenger;
