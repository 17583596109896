import React from 'react';

export enum ContentTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  LI = 'li',
  LABEL = 'label',
  SPAN = 'span',
}

interface ElementProps {
  children: React.ReactNode;
  type: ContentTag;
  className: string;
  htmlFor: string | null;
}

const Element = (props: ElementProps) => {
  const { type = ContentTag.P, className, children, htmlFor } = props;
  return React.createElement(type, { className, htmlFor }, children);
};

export default Element;
