import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  a: {
    fill: '#24b9a9',
  },
  b: {
    fill: '#fff',
  },
});

type Props = {
  className?: string;
};

function Bubble({ className = '' }: Props) {
  const classes = useStyles();

  return (
    <svg className={className} viewBox="0 0 18 17">
      <g transform="translate(0 -0.5)">
        <path
          className={classes.a}
          d="M9,.5c-4.97,0-9,3.134-9,7a6.533,6.533,0,0,0,3.3,5.417A10.982,10.982,0,0,1,1,17.5a9.571,9.571,0,0,0,5.858-3.208A11.428,11.428,0,0,0,9,14.5c4.97,0,9-3.134,9-7S13.97.5,9,.5Z"
        />
        <circle
          className={classes.b}
          cx="1"
          cy="1"
          r="1"
          transform="translate(8 6.5)"
        />
        <circle
          className={classes.b}
          cx="1"
          cy="1"
          r="1"
          transform="translate(12 6.5)"
        />
        <circle
          className={classes.b}
          cx="1"
          cy="1"
          r="1"
          transform="translate(4 6.5)"
        />
      </g>
    </svg>
  );
}

export default Bubble;
