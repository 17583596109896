import React, { Dispatch, SetStateAction, useState } from 'react';

export const EmailContext = React.createContext<
  [string, Dispatch<SetStateAction<string>>]
>(['', () => {}]);
EmailContext.displayName = 'EmailContext';

function EmailProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [email, setEmail] = useState<string>('');

  return (
    <EmailContext.Provider value={[email, setEmail]}>
      {children}
    </EmailContext.Provider>
  );
}

export default EmailProvider;
