import config from '../../config';

// todo: Type the Promises with a generic - SFR 2021-02-22

export default function request(
  method: string,
  url: string,
  data: Record<string, unknown> = {},
): Promise<any> {
  return fetch(`${config.apiUrl}${url}`, {
    method,
    body: JSON.stringify(data),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
}

export function get(
  url: string,
  data: Record<string, unknown> = {},
): Promise<any> {
  return request('GET', url, data).then(res => res.json());
}
export function put(
  url: string,
  data: Record<string, unknown> = {},
): Promise<any> {
  return request('PUT', url, data).then(res => res.json());
}
export function post(
  url: string,
  data: Record<string, unknown> = {},
): Promise<any> {
  return request('POST', url, data).then(res => res.json());
}
