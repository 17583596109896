import React from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  className?: string;
};

const useStyles = createUseStyles({
  a: {
    fill: '#fff',
  },
  c: {
    fill: 'none',
    stroke: '#05051b',
    strokeMiterlimit: 10,
  },
});

function Satellite({ className = '' }: Props) {
  const classes = useStyles();
  return (
    <svg
      className={className}
      width="238.318"
      height="227.939"
      viewBox="0 0 238.318 227.939"
    >
      <g transform="matrix(0.259, 0.966, -0.966, 0.259, 187.383, 6.08)">
        <path
          className={classes.a}
          d="M-.366,14.241l.161-.578,15.187,5.392L13.472-.307l.495-.087L15.55,19.9-.366,14.241Z"
          transform="translate(117.709 139.957)"
        />
        <path
          className={classes.c}
          d="M-.366,14.241l.161-.578,15.187,5.392L13.472-.307l.495-.087L15.55,19.9-.366,14.241Z"
          transform="translate(117.709 139.957)"
        />
        <path
          className={classes.a}
          d="M3.42,4.579a1.972,1.972,0,0,1-1.8.6A2.09,2.09,0,0,1,.162,3.891,3.155,3.155,0,0,1,.452.974,2.008,2.008,0,0,1,2.972.108,2.58,2.58,0,0,1,4.248,2.753,3.017,3.017,0,0,1,3.42,4.579"
          transform="translate(130.919 156.853)"
        />
        <path
          className={classes.c}
          d="M3.42,4.579a1.972,1.972,0,0,1-1.8.6A2.09,2.09,0,0,1,.162,3.891,3.155,3.155,0,0,1,.452.974,2.008,2.008,0,0,1,2.972.108,2.58,2.58,0,0,1,4.248,2.753,3.017,3.017,0,0,1,3.42,4.579Z"
          transform="translate(130.919 156.853)"
        />
        <path
          className={classes.a}
          d="M.378-.011l-.388.4L8.536,13.059l.387-.4Z"
          transform="translate(124.245 146.559)"
        />
        <path
          className={classes.c}
          d="M.378-.011l-.388.4L8.536,13.059l.387-.4Z"
          transform="translate(124.245 146.559)"
        />
        <path
          className={classes.a}
          d="M1.615,2.143a.9.9,0,0,1-1.128.182A1.247,1.247,0,0,1-.025,1.133,1.3,1.3,0,0,1,.709.038.871.871,0,0,1,1.784.392a1.452,1.452,0,0,1,.089,1.367,1.319,1.319,0,0,1-.258.385"
          transform="translate(127.262 151.209)"
        />
        <path
          className={classes.c}
          d="M1.615,2.143a.9.9,0,0,1-1.128.182A1.247,1.247,0,0,1-.025,1.133,1.3,1.3,0,0,1,.709.038.871.871,0,0,1,1.784.392a1.452,1.452,0,0,1,.089,1.367,1.319,1.319,0,0,1-.258.385h0Z"
          transform="translate(127.262 151.209)"
        />
        <path
          className={classes.a}
          d="M1.616,2.143a.892.892,0,0,1-1.129.183A1.244,1.244,0,0,1-.025,1.133,1.3,1.3,0,0,1,.71.038.871.871,0,0,1,1.786.392a1.452,1.452,0,0,1-.17,1.752"
          transform="translate(128.938 153.687)"
        />
        <path
          className={classes.c}
          d="M1.616,2.143a.892.892,0,0,1-1.129.183A1.244,1.244,0,0,1-.025,1.133,1.3,1.3,0,0,1,.71.038.871.871,0,0,1,1.786.392a1.452,1.452,0,0,1-.17,1.752Z"
          transform="translate(128.938 153.687)"
        />
        <path
          className={classes.a}
          d="M62.567-1.763-1.628,63.365.2,66.09,64.391.96,62.567-1.764Z"
          transform="translate(97.183 19.513)"
        />
        <path
          className={classes.c}
          d="M62.567-1.763-1.628,63.365.2,66.09,64.391.96,62.567-1.764Z"
          transform="translate(97.183 19.513)"
        />
        <path
          className={classes.a}
          d="M37.061,14.574,9.806,42.406a.889.889,0,0,1-1.124.183,1,1,0,0,1-.3-.294l-8.9-13.182a1.45,1.45,0,0,1,.175-1.745L26.915-.465A.882.882,0,0,1,28.04-.65a.99.99,0,0,1,.3.3l8.89,13.183a1.452,1.452,0,0,1-.169,1.745"
          transform="translate(139.341 18.234)"
        />
        <path
          className={classes.c}
          d="M37.061,14.574,9.806,42.406a.889.889,0,0,1-1.124.183,1,1,0,0,1-.3-.294l-8.9-13.182a1.45,1.45,0,0,1,.175-1.745L26.915-.465A.882.882,0,0,1,28.04-.65a.99.99,0,0,1,.3.3l8.89,13.183a1.452,1.452,0,0,1-.169,1.745Z"
          transform="translate(139.341 18.234)"
        />
        <path
          className={classes.a}
          d="M0,0,1,1.494,1.683.8,1.215.1Z"
          transform="translate(149.347 57.92)"
        />
        <path
          className={classes.c}
          d="M0,0,1,1.494,1.683.8,1.215.1Z"
          transform="translate(149.347 57.92)"
        />
        <path
          className={classes.a}
          d="M.443.66,1.009.084,0,0Z"
          transform="translate(145.768 57.631)"
        />
        <path
          className={classes.c}
          d="M.443.66,1.009.084,0,0Z"
          transform="translate(145.768 57.631)"
        />
        <path
          className={classes.a}
          d="M26.529,6.529,24.392,3.366,26.4,1.332l2.131,3.163ZM23.84,9.241l-2.131-3.17,2-2.034,2.137,3.17Zm-2.683,2.712-2.136-3.17,2-2.033,2.137,3.17Zm-2.682,2.712L16.339,11.5l2.005-2.034,2.137,3.163Zm-2.682,2.712-2.137-3.17,2.006-2.033,2.131,3.17ZM13.105,20.09l-2.132-3.163,2-2.034,2.137,3.163Zm8.067-14.8-2.137-3.17,2-2.034,2.137,3.17ZM18.49,8l-2.137-3.17,2-2.034,2.137,3.17L18.49,8Zm-2.683,2.712L13.685,7.629l2-2.034,2.132,3.163Zm-2.688,2.713-2.131-3.17,2-2.034,2.136,3.17Zm-2.682,2.712L8.3,12.975l2.005-2.034L12.442,14.1ZM7.754,18.883l-2.137-3.17,2.005-2.034,2.137,3.17ZM33.889,5.557l.678-.679L32.429,1.715l.9-.77-1.215-.1-.195.2L31.771.818,30.556.727l.688,1.016-2,2.033L27.1.606l.155-.162-1.5-.116.125.181L23.883,2.543,22.189.027,19.976-.149,18.532,1.329,17.4-.359l-1.215-.1,1.668,2.478-2,2.033L13.712.885l-.683.679,2.136,3.163-2,2.034L11.024,3.6l-.677.678,2.136,3.17-2,2.033L8.342,6.309l-.678.678L9.8,10.15l-2,2.035L5.659,9.023,4.976,9.7l2.137,3.17-2,2.033-2.137-3.17-.683.679,2.137,3.17L2.425,17.616.288,14.446l-.677.678,2.136,3.17L.637,19.413l1.215.1.417-.427.328.485,1.215.1-.859-1.295,2-2.033L7.089,19.5l-.378.393,1.895.151L8.3,19.6l2-2.034,1.876,2.782,1.809.146,1.667-1.709,1.308,1.941,1.215.1L16.338,18.08l2.005-2.034,2.131,3.17.683-.679L19.02,15.375l2-2.034L23.162,16.5l.678-.678-2.131-3.17,2-2.034,2.137,3.17.678-.678L24.39,9.951,26.4,7.917l2.131,3.163.683-.678L27.073,7.239l2-2.035,2.131,3.163.683-.678L29.755,4.526l2-2.034,2.128,3.064Z"
          transform="translate(140.628 29.038)"
        />
        <path
          className={classes.c}
          d="M26.529,6.529,24.392,3.366,26.4,1.332l2.131,3.163ZM23.84,9.241l-2.131-3.17,2-2.034,2.137,3.17Zm-2.683,2.712-2.136-3.17,2-2.033,2.137,3.17Zm-2.682,2.712L16.339,11.5l2.005-2.034,2.137,3.163Zm-2.682,2.712-2.137-3.17,2.006-2.033,2.131,3.17ZM13.105,20.09l-2.132-3.163,2-2.034,2.137,3.163Zm8.067-14.8-2.137-3.17,2-2.034,2.137,3.17ZM18.49,8l-2.137-3.17,2-2.034,2.137,3.17L18.49,8Zm-2.683,2.712L13.685,7.629l2-2.034,2.132,3.163Zm-2.688,2.713-2.131-3.17,2-2.034,2.136,3.17Zm-2.682,2.712L8.3,12.975l2.005-2.034L12.442,14.1ZM7.754,18.883l-2.137-3.17,2.005-2.034,2.137,3.17ZM33.889,5.557l.678-.679L32.429,1.715l.9-.77-1.215-.1-.195.2L31.771.818,30.556.727l.688,1.016-2,2.033L27.1.606l.155-.162-1.5-.116.125.181L23.883,2.543,22.189.027,19.976-.149,18.532,1.329,17.4-.359l-1.215-.1,1.668,2.478-2,2.033L13.712.885l-.683.679,2.136,3.163-2,2.034L11.024,3.6l-.677.678,2.136,3.17-2,2.033L8.342,6.309l-.678.678L9.8,10.15l-2,2.035L5.659,9.023,4.976,9.7l2.137,3.17-2,2.033-2.137-3.17-.683.679,2.137,3.17L2.425,17.616.288,14.446l-.677.678,2.136,3.17L.637,19.413l1.215.1.417-.427.328.485,1.215.1-.859-1.295,2-2.033L7.089,19.5l-.378.393,1.895.151L8.3,19.6l2-2.034,1.876,2.782,1.809.146,1.667-1.709,1.308,1.941,1.215.1L16.338,18.08l2.005-2.034,2.131,3.17.683-.679L19.02,15.375l2-2.034L23.162,16.5l.678-.678-2.131-3.17,2-2.034,2.137,3.17.678-.678L24.39,9.951,26.4,7.917l2.131,3.163.683-.678L27.073,7.239l2-2.035,2.131,3.163.683-.678L29.755,4.526l2-2.034,2.128,3.064Z"
          transform="translate(140.628 29.038)"
        />
        <path
          className={classes.a}
          d="M5.235,5.936,3.1,2.774,5.1.739,7.241,3.9Zm7.357-.827.677-.678L11.138,1.268l.333-.339L9.663.783l.261.388-2,2.034L6.084.481,4.189.33,2.567,1.984,1.29.1.076,0,1.884,2.682-.121,4.716.4,5.506l2-2.034,2.131,3.17-2,2.034.089.129,1.009.084L5.073,7.417,6.2,9.092l1.215.1L5.75,6.727,7.756,4.692,9.893,7.854l.677-.678L8.439,4.007l2-2.034,2.154,3.136Z"
          transform="translate(143.127 48.82)"
        />
        <path
          className={classes.c}
          d="M5.235,5.936,3.1,2.774,5.1.739,7.241,3.9Zm7.357-.827.677-.678L11.138,1.268l.333-.339L9.663.783l.261.388-2,2.034L6.084.481,4.189.33,2.567,1.984,1.29.1.076,0,1.884,2.682-.121,4.716.4,5.506l2-2.034,2.131,3.17-2,2.034.089.129,1.009.084L5.073,7.417,6.2,9.092l1.215.1L5.75,6.727,7.756,4.692,9.893,7.854l.677-.678L8.439,4.007l2-2.034,2.154,3.136Z"
          transform="translate(143.127 48.82)"
        />
        <path
          className={classes.a}
          d="M2.1.073.514,1.692-.023.9.883-.025Z"
          transform="translate(140.361 48.681)"
        />
        <path
          className={classes.c}
          d="M2.1.073.514,1.692-.023.9.883-.025Z"
          transform="translate(140.361 48.681)"
        />
        <path
          className={classes.a}
          d="M.818,1.216,1.5.525,1.209.1,0,0Z"
          transform="translate(157.585 49.969)"
        />
        <path
          className={classes.c}
          d="M.818,1.216,1.5.525,1.209.1,0,0Z"
          transform="translate(157.585 49.969)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(148.976 40.159)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(148.976 40.159)"
        />
        <path
          className={classes.a}
          d="M2.085,5.162-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(146.292 42.898)"
        />
        <path
          className={classes.c}
          d="M2.085,5.162-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(146.292 42.898)"
        />
        <path
          className={classes.a}
          d="M2.08,5.155-.051,1.992,1.954-.055H1.949l-2,2.047Z"
          transform="translate(154.348 34.675)"
        />
        <path
          className={classes.c}
          d="M2.08,5.155-.051,1.992,1.954-.055H1.949l-2,2.047Z"
          transform="translate(154.348 34.675)"
        />
        <path
          className={classes.a}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(151.659 37.414)"
        />
        <path
          className={classes.c}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(151.659 37.414)"
        />
        <path
          className={classes.a}
          d="M.688,1.015,0,0,.688,1.016Z"
          transform="translate(171.157 29.765)"
        />
        <path
          className={classes.c}
          d="M.688,1.015,0,0,.688,1.016Z"
          transform="translate(171.157 29.765)"
        />
        <path
          className={classes.a}
          d="M.152,0,0,.159Z"
          transform="translate(167.707 29.5)"
        />
        <path
          className={classes.c}
          d="M.152,0,0,.159Z"
          transform="translate(167.707 29.5)"
        />
        <path
          className={classes.a}
          d="M.126.181,0,0Z"
          transform="translate(166.362 29.4)"
        />
        <path
          className={classes.c}
          d="M.126.181.006,0H0Z"
          transform="translate(166.363 29.38)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055l2.132,3.17L1.954-.055-.051,1.992Z"
          transform="translate(157.031 31.93)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055l2.132,3.17L1.954-.055-.051,1.992Z"
          transform="translate(157.031 31.93)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055-.051,1.992l.86,1.275Z"
          transform="translate(143.609 45.643)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Zm0,0,.86,1.275Z"
          transform="translate(143.609 45.643)"
        />
        <path
          className={classes.a}
          d="M0,0,1.271,1.889h.006L0,0Z"
          transform="translate(144.418 48.909)"
        />
        <path
          className={classes.c}
          d="M0,0,1.271,1.889h.006L0,0Z"
          transform="translate(144.418 48.909)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(157.015 38.634)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(157.015 38.634)"
        />
        <path
          className={classes.a}
          d="M1.954-.055,4.091,3.108,1.954-.055-.051,1.992Z"
          transform="translate(162.382 33.15)"
        />
        <path
          className={classes.c}
          d="M1.954-.055,4.091,3.108Zm0,0L-.051,1.992Z"
          transform="translate(162.382 33.15)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055l2.137,3.17L1.954-.055-.051,1.992l2.136,3.17Z"
          transform="translate(159.699 35.889)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055l2.137,3.17L1.954-.055-.051,1.992l2.136,3.17Z"
          transform="translate(159.699 35.889)"
        />
        <path
          className={classes.a}
          d="M1.954-.048,4.085,3.115,1.949-.055l-2,2.047V2L1.954-.048Z"
          transform="translate(165.071 30.405)"
        />
        <path
          className={classes.c}
          d="M1.954-.048,4.085,3.115,1.949-.055l-2,2.047V2L1.954-.048Z"
          transform="translate(165.071 30.405)"
        />
        <path
          className={classes.a}
          d="M1.954-.055,4.091,3.108,1.954-.055-.051,1.992Z"
          transform="translate(159.714 29.192)"
        />
        <path
          className={classes.c}
          d="M1.954-.055,4.091,3.108Zm0,0L-.051,1.992Z"
          transform="translate(159.714 29.192)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.046,1.992l2-2.047L-.051,1.992Z"
          transform="translate(151.644 44.118)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.046,1.992l2-2.047L-.051,1.992Z"
          transform="translate(151.644 44.118)"
        />
        <path
          className={classes.a}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(154.332 41.373)"
        />
        <path
          className={classes.c}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(154.332 41.373)"
        />
        <path
          className={classes.a}
          d="M1.954-.055-.051,1.992,2.085,5.154-.051,1.992Z"
          transform="translate(146.277 49.602)"
        />
        <path
          className={classes.c}
          d="M1.954-.055-.051,1.992,2.085,5.154-.051,1.992Z"
          transform="translate(146.277 49.602)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055-.051,1.992l.3.446Z"
          transform="translate(148.961 46.856)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Zm0,0,.3.446Z"
          transform="translate(148.961 46.856)"
        />
        <path
          className={classes.a}
          d="M0,0,1.834,2.723Z"
          transform="translate(149.212 49.295)"
        />
        <path
          className={classes.c}
          d="M0,0,1.834,2.723Z"
          transform="translate(149.212 49.295)"
        />
        <path
          className={classes.a}
          d="M-.012.475l.578.05L.841.543.466-.013-.012.475Z"
          transform="translate(171.394 24.09)"
        />
        <path
          className={classes.c}
          d="M.841.543.466-.013-.012.475l.578.05Z"
          transform="translate(171.394 24.09)"
        />
        <path
          className={classes.a}
          d="M2.161,2.206.666-.019-.017.673.946,2.109l1.214.1Z"
          transform="translate(161.701 21.76)"
        />
        <path
          className={classes.c}
          d="M2.161,2.206.666-.019-.017.673.946,2.109l1.214.1Z"
          transform="translate(161.701 21.76)"
        />
        <path
          className={classes.a}
          d="M2.656,4.638l.4.6,1.215.1L3.334,3.946,5.339,1.893,4.8,1.1l-2,2.047L.666-.019l-.683.7L2.119,3.842.925,5.062l1.215.1.517-.522Z"
          transform="translate(164.384 19.015)"
        />
        <path
          className={classes.c}
          d="M2.656,4.638l.4.6,1.215.1L3.334,3.946,5.339,1.893,4.8,1.1l-2,2.047L.666-.019l-.683.7L2.119,3.842.925,5.062l1.215.1.517-.522Z"
          transform="translate(164.384 19.015)"
        />
        <path
          className={classes.a}
          d="M.66-.019-.017.673l.464.692,1.214.1Z"
          transform="translate(156.334 27.244)"
        />
        <path
          className={classes.c}
          d="M.66-.019-.017.673l.464.692,1.214.1Z"
          transform="translate(156.334 27.244)"
        />
        <path
          className={classes.a}
          d="M1.544,4.947l2.213.175-.443-.653L5.32,2.421,7.331,5.41l1.5.116,1.85-1.884L12.125,5.8l1.215.091L11.354,2.949,13.36.9,13.2.663,12.928.644,12.345.595,10.823,2.154,9.625.375,8.41.278l1.729,2.575L8.134,4.9,6,1.729,7.492.2,6.277.114,5.466.94,4.83,0,3.616-.1,4.788,1.632,2.783,3.679.646.516V.509l-.678.7L2.1,4.377l-.561.569Z"
          transform="translate(159.032 23.97)"
        />
        <path
          className={classes.c}
          d="M1.544,4.947l2.213.175-.443-.653L5.32,2.421,7.331,5.41l1.5.116,1.85-1.884L12.125,5.8l1.215.091L11.354,2.949,13.36.9,13.2.663,12.928.644,12.345.595,10.823,2.154,9.625.375,8.41.278l1.729,2.575L8.134,4.9,6,1.729,7.492.2,6.277.114,5.466.94,4.83,0,3.616-.1,4.788,1.632,2.783,3.679.646.516V.509l-.678.7L2.1,4.377l-.561.569Z"
          transform="translate(159.032 23.97)"
        />
        <path
          className={classes.a}
          d="M-.047,1.834,1.8-.051,3.251,2.1,1.8-.051Z"
          transform="translate(167.906 27.662)"
        />
        <path
          className={classes.c}
          d="M-.047,1.834,1.8-.051,3.251,2.1,1.8-.051Z"
          transform="translate(167.906 27.662)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992l2-2.04L3.965,2.933,1.954-.055Z"
          transform="translate(162.397 26.447)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992l2-2.04L3.965,2.933,1.954-.055Z"
          transform="translate(162.397 26.447)"
        />
        <path
          className={classes.a}
          d="M1.451-.041-.038,1.484Z"
          transform="translate(165.073 24.215)"
        />
        <path
          className={classes.c}
          d="M1.451-.041-.038,1.484Z"
          transform="translate(165.073 24.215)"
        />
        <path
          className={classes.a}
          d="M1.729,2.575,0,0Z"
          transform="translate(167.442 24.247)"
        />
        <path
          className={classes.c}
          d="M1.729,2.575,0,0Z"
          transform="translate(167.442 24.247)"
        />
        <path
          className={classes.a}
          d="M.275.018,0,0Z"
          transform="translate(171.96 24.615)"
        />
        <path
          className={classes.c}
          d="M.275.018,0,0Z"
          transform="translate(171.96 24.615)"
        />
        <path
          className={classes.a}
          d="M0,.007,1.214.1,0,0Z"
          transform="translate(162.648 23.862)"
        />
        <path
          className={classes.c}
          d="M1.215.1,0,0,1.215.1Z"
          transform="translate(162.647 23.869)"
        />
        <path
          className={classes.a}
          d="M0,0,1.107.094,0,.033Z"
          transform="translate(167.442 24.248)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(167.442 24.248)"
        />
        <path
          className={classes.a}
          d="M1.168,1.9,2.3.739,1.765-.05-.046,1.8l1.215.1Z"
          transform="translate(172.763 28.085)"
        />
        <path
          className={classes.c}
          d="M1.168,1.9,2.3.739,1.765-.05-.046,1.8l1.215.1Z"
          transform="translate(172.763 28.085)"
        />
        <path
          className={classes.a}
          d="M37.057,14.6,9.8,42.434a.888.888,0,0,1-1.425-.118L-.512,29.134a1.371,1.371,0,0,1-.209-.9,1.414,1.414,0,0,1,.383-.848L26.917-.469a.89.89,0,0,1,1.124-.183.988.988,0,0,1,.3.294l8.89,13.183a1.371,1.371,0,0,1,.209.9,1.414,1.414,0,0,1-.383.848"
          transform="translate(124.936 -3.152)"
        />
        <path
          className={classes.c}
          d="M37.057,14.6,9.8,42.434a.888.888,0,0,1-1.425-.118L-.512,29.134a1.371,1.371,0,0,1-.209-.9,1.414,1.414,0,0,1,.383-.848L26.917-.469a.89.89,0,0,1,1.124-.183.988.988,0,0,1,.3.294l8.89,13.183a1.357,1.357,0,0,1,.219.911,1.4,1.4,0,0,1-.395.86Z"
          transform="translate(124.936 -3.152)"
        />
        <path
          className={classes.a}
          d="M-.017.673l1.4,2.083,1.215.1L.661-.019Z"
          transform="translate(144.614 3.14)"
        />
        <path
          className={classes.c}
          d="M-.017.673l1.4,2.083,1.215.1L.661-.019Z"
          transform="translate(144.614 3.14)"
        />
        <path
          className={classes.a}
          d="M.292.433.664.054,0,0,.292.433Z"
          transform="translate(157.129 15.68)"
        />
        <path
          className={classes.c}
          d="M.292.433.664.054,0,0,.292.433Z"
          transform="translate(157.129 15.68)"
        />
        <path
          className={classes.a}
          d="M-.008.323.57.366.314-.009Z"
          transform="translate(159.811 6.68)"
        />
        <path
          className={classes.c}
          d="M.314-.009-.008.324.57.367.314-.008Z"
          transform="translate(159.811 6.679)"
        />
        <path
          className={classes.a}
          d="M1.51,8.394,2.582,7.3l.844,1.248,1.214.1L3.265,6.6l2-2.047L7.4,7.727,6.363,8.785l1.214.1.361-.366.281.421,1.209.091-.813-1.2,2.006-2.047-.537-.8-2,2.047L5.949,3.866,7.954,1.819l-.537-.8L5.411,3.07,3.28-.092,2.6.6l2.136,3.17L2.728,5.816.592,2.646l-.677.7L2.05,6.507.3,8.3l1.215.1Z"
          transform="translate(147.366 -2.27)"
        />
        <path
          className={classes.c}
          d="M1.51,8.394,2.582,7.3l.844,1.248,1.214.1L3.265,6.6l2-2.047L7.4,7.727,6.363,8.785l1.214.1.361-.366.281.421,1.209.091-.813-1.2,2.006-2.047-.537-.8-2,2.047L5.949,3.866,7.954,1.819l-.537-.8L5.411,3.07,3.28-.092,2.6.6l2.136,3.17L2.728,5.816.592,2.646l-.677.7L2.05,6.507.3,8.3l1.215.1Z"
          transform="translate(147.366 -2.27)"
        />
        <path
          className={classes.a}
          d="M7.984,6.98,5.853,3.817l2-2.035L9.989,4.945Zm7.362-.827.677-.678L13.887,2.3l1.227-1.253L13.9.953l-.555.562L12.917.869,11.7.771,12.666,2.2l-2,2.034L8.525,1.064,9.036.549,6.909.379l.4.588L5.305,3,3.33.077,1.754-.049l-1.8,1.837.521.789,2-2.034,2.137,3.17-2,2.034.52.789L5.137,4.5l2.136,3.17-2,2.034.521.789,2-2.034,2.137,3.17.683-.679L8.471,7.789l2.005-2.034,2.137,3.163.677-.678-2.136-3.17,2-2.034,2.187,3.115Z"
          transform="translate(125.971 26.413)"
        />
        <path
          className={classes.c}
          d="M7.984,6.98,5.853,3.817l2-2.035L9.989,4.945Zm7.362-.827.677-.678L13.887,2.3l1.227-1.253L13.9.953l-.555.562L12.917.869,11.7.771,12.666,2.2l-2,2.034L8.525,1.064,9.036.549,6.909.379l.4.588L5.305,3,3.33.077,1.754-.049l-1.8,1.837.521.789,2-2.034,2.137,3.17-2,2.034.52.789L5.137,4.5l2.136,3.17-2,2.034.521.789,2-2.034,2.137,3.17.683-.679L8.471,7.789l2.005-2.034,2.137,3.163.677-.678-2.136-3.17,2-2.034,2.187,3.115Z"
          transform="translate(125.971 26.413)"
        />
        <path
          className={classes.a}
          d="M0,0,1.522,2.264l.678-.7L1.215.1Z"
          transform="translate(142.478 27.562)"
        />
        <path
          className={classes.c}
          d="M0,0,1.522,2.264l.678-.7L1.215.1Z"
          transform="translate(142.478 27.562)"
        />
        <path
          className={classes.a}
          d="M.666-.019l-.683.7L.671,1.695l1.215.1Z"
          transform="translate(128.504 19.585)"
        />
        <path
          className={classes.c}
          d="M.666-.019l-.683.7L.671,1.695l1.215.1Z"
          transform="translate(128.504 19.585)"
        />
        <path
          className={classes.a}
          d="M13.436,8.269,11.3,5.1l2-2.033,2.137,3.17ZM8.058,7.049,5.921,3.88,7.927,1.846l2.137,3.17L8.058,7.049ZM23.454,4.7l.677-.678L22.266,1.257,20.441,1.1l-1.667,1.7L17.473.865l-1.215-.1,1.878,2.709-2,2.034L14,2.34,15.627.679l-1.215-.1-.95.969-.741-1.1-1.214-.1,1.249,1.91-2,2.034L8.615,1.128,9.564.211,8.355.113,8.122.351,7.939.079l-1.215-.1.7,1.061L5.417,3.077,3.28-.092,2.6.585l2.131,3.17L2.729,5.789.6,2.626-.085,3.3l2.137,3.17-.839.861,1.215.091.161-.163.125.188,1.215.1-.662-.976,2-2.035L7.408,7.7l-.127.129,1.435.117-.1-.149,2-2.033,1.672,2.484,2.268.179,1.416-1.443,1.109,1.7,1.215.091L16.656,6.32l2.006-2.034L20.8,7.448l.677-.678L19.339,3.6l2.005-2.034L23.454,4.7Z"
          transform="translate(131.256 14.162)"
        />
        <path
          className={classes.c}
          d="M13.436,8.269,11.3,5.1l2-2.033,2.137,3.17ZM8.058,7.049,5.921,3.88,7.927,1.846l2.137,3.17L8.058,7.049ZM23.454,4.7l.677-.678L22.266,1.257,20.441,1.1l-1.667,1.7L17.473.865l-1.215-.1,1.878,2.709-2,2.034L14,2.34,15.627.679l-1.215-.1-.95.969-.741-1.1-1.214-.1,1.249,1.91-2,2.034L8.615,1.128,9.564.211,8.355.113,8.122.351,7.939.079l-1.215-.1.7,1.061L5.417,3.077,3.28-.092,2.6.585l2.131,3.17L2.729,5.789.6,2.626-.085,3.3l2.137,3.17-.839.861,1.215.091.161-.163.125.188,1.215.1-.662-.976,2-2.035L7.408,7.7l-.127.129,1.435.117-.1-.149,2-2.033,1.672,2.484,2.268.179,1.416-1.443,1.109,1.7,1.215.091L16.656,6.32l2.006-2.034L20.8,7.448l.677-.678L19.339,3.6l2.005-2.034L23.454,4.7Z"
          transform="translate(131.256 14.162)"
        />
        <path
          className={classes.a}
          d="M18.706,2.537,16.7,4.585l1.146,1.7-1.215-.1-.615-.906-.778.793-1.215-.1,1.461-1.491L13.349,1.325,11.344,3.371,13.053,5.9l-1.215-.1-1.172-1.74L9.171,5.594,7.045,5.423,5.309,2.849l-2,2.047.156.24L1.889,5.009l.206-.21L-.042,1.629.636.938,2.772,4.1,4.778,2.054,3.329-.094,4.544,0l.912,1.358L6.623.169l1.215.1L5.993,2.151l2.137,3.17,2-2.047L8.123.291l1.215.1,1.475,2.187L12.69.657l1.436.117L16.164,3.8l2.005-2.047L17.7,1.063l2.268.18-.589.6,2.137,3.17-.683.691-2.131-3.17Z"
          transform="translate(125.845 21.374)"
        />
        <path
          className={classes.c}
          d="M18.706,2.537,16.7,4.585l1.146,1.7-1.215-.1-.615-.906-.778.793-1.215-.1,1.461-1.491L13.349,1.325,11.344,3.371,13.053,5.9l-1.215-.1-1.172-1.74L9.171,5.594,7.045,5.423,5.309,2.849l-2,2.047.156.24L1.889,5.009l.206-.21L-.042,1.629.636.938,2.772,4.1,4.778,2.054,3.329-.094,4.544,0l.912,1.358L6.623.169l1.215.1L5.993,2.151l2.137,3.17,2-2.047L8.123.291l1.215.1,1.475,2.187L12.69.657l1.436.117L16.164,3.8l2.005-2.047L17.7,1.063l2.268.18-.589.6,2.137,3.17-.683.691-2.131-3.17Z"
          transform="translate(125.845 21.374)"
        />
        <path
          className={classes.a}
          d="M1.7.822l-.683.691L0,0,1.215.1Z"
          transform="translate(148.345 22.822)"
        />
        <path
          className={classes.c}
          d="M1.7.822l-.683.691L0,0,1.215.1Z"
          transform="translate(148.345 22.822)"
        />
        <path
          className={classes.a}
          d="M0,0,2.136,3.163l-.127.129.127-.128L0,0Z"
          transform="translate(136.527 18.739)"
        />
        <path
          className={classes.c}
          d="M0,0,2.136,3.163l-.127.129.127-.128L0,0Z"
          transform="translate(136.527 18.739)"
        />
        <path
          className={classes.a}
          d="M-.048,1.867v.007L1.83-.052Z"
          transform="translate(136.706 22.082)"
        />
        <path
          className={classes.c}
          d="M-.048,1.867v.007L1.83-.052Z"
          transform="translate(136.706 22.082)"
        />
        <path
          className={classes.a}
          d="M1.939.545-.067,2.592,1.939.552l-.4-.6.4.588Z"
          transform="translate(131.352 26.84)"
        />
        <path
          className={classes.c}
          d="M1.939.545-.067,2.592,1.939.552l-.4-.6.4.588Z"
          transform="translate(131.352 26.84)"
        />
        <path
          className={classes.a}
          d="M1.735,2.575,0,0Z"
          transform="translate(131.155 24.223)"
        />
        <path
          className={classes.c}
          d="M1.735,2.575,0,0Z"
          transform="translate(131.155 24.223)"
        />
        <path
          className={classes.a}
          d="M.222-.006-.006.224V.231L.227-.006Z"
          transform="translate(139.362 14.282)"
        />
        <path
          className={classes.c}
          d="M-.006.231.227-.006H.222L-.006.231Z"
          transform="translate(139.362 14.282)"
        />
        <path
          className={classes.a}
          d="M-.024.937h.006L.925-.026Z"
          transform="translate(144.732 14.79)"
        />
        <path
          className={classes.c}
          d="M-.024.937h.006L.925-.026Z"
          transform="translate(144.732 14.79)"
        />
        <path
          className={classes.a}
          d="M-.043,1.656l1.661-1.7Z"
          transform="translate(150.106 15.291)"
        />
        <path
          className={classes.c}
          d="M-.043,1.656l1.661-1.7Z"
          transform="translate(150.106 15.291)"
        />
        <path
          className={classes.a}
          d="M1.9,1.878l-2,2.04v.007L1.9,1.878.628-.018Z"
          transform="translate(142.125 14.547)"
        />
        <path
          className={classes.c}
          d="M1.9,1.878l-2,2.04v.007Zm0,0L.628-.018Z"
          transform="translate(142.125 14.547)"
        />
        <path
          className={classes.a}
          d="M1.883,2.721-.122,4.769,1.883,2.722.044,0Z"
          transform="translate(147.503 14.917)"
        />
        <path
          className={classes.c}
          d="M1.883,2.721-.122,4.769,1.883,2.722Zm0,0L.044,0Z"
          transform="translate(147.503 14.917)"
        />
        <path
          className={classes.a}
          d="M.131,5.209,2.136,3.163,0,0,2.136,3.163Z"
          transform="translate(139.21 16.001)"
        />
        <path
          className={classes.c}
          d="M.131,5.209,2.136,3.163,0,0,2.136,3.163Z"
          transform="translate(139.21 16.001)"
        />
        <path
          className={classes.a}
          d="M0,0,.126.19Z"
          transform="translate(133.843 21.478)"
        />
        <path
          className={classes.c}
          d="M0,0,.126.19Z"
          transform="translate(133.843 21.478)"
        />
        <path
          className={classes.a}
          d="M2.006,2.982l-2,2.047,2-2.047L0,0Z"
          transform="translate(133.968 21.665)"
        />
        <path
          className={classes.c}
          d="M2.006,2.982l-2,2.047Zm0,0L0,0Z"
          transform="translate(133.968 21.665)"
        />
        <path
          className={classes.a}
          d="M0,0,2.131,3.164l-2,2.047v.006l2-2.047L0,0Z"
          transform="translate(133.828 28.181)"
        />
        <path
          className={classes.c}
          d="M0,0,2.131,3.164l-2,2.047v.006l2-2.047L0,0Z"
          transform="translate(133.828 28.181)"
        />
        <path
          className={classes.a}
          d="M0,0,1.673,2.484Z"
          transform="translate(141.878 19.953)"
        />
        <path
          className={classes.c}
          d="M0,0,1.673,2.484Z"
          transform="translate(141.878 19.953)"
        />
        <path
          className={classes.a}
          d="M1.936.644-.069,2.691,1.936.644,1.472-.042l.464.685Z"
          transform="translate(142.078 22.478)"
        />
        <path
          className={classes.c}
          d="M1.936.644-.069,2.691Zm0,0L1.472-.042l.464.685Z"
          transform="translate(142.078 22.478)"
        />
        <path
          className={classes.a}
          d="M.131,5.216l2-2.047L0,0,2.131,3.17Z"
          transform="translate(144.567 17.214)"
        />
        <path
          className={classes.c}
          d="M.131,5.216l2-2.047L0,0,2.131,3.17Z"
          transform="translate(144.567 17.214)"
        />
        <path
          className={classes.a}
          d="M1.911,1.4l-2,2.047L1.916,1.4.952-.02V-.027Z"
          transform="translate(136.732 27.203)"
        />
        <path
          className={classes.c}
          d="M1.911,1.4l-2,2.047L1.916,1.4.952-.02V-.027Z"
          transform="translate(136.732 27.203)"
        />
        <path
          className={classes.a}
          d="M1.172,1.74,0,0Z"
          transform="translate(136.511 25.436)"
        />
        <path
          className={classes.c}
          d="M1.172,1.74,0,0Z"
          transform="translate(136.511 25.436)"
        />
        <path
          className={classes.a}
          d="M-.014.541V.548L.53-.015Z"
          transform="translate(139.34 27.367)"
        />
        <path
          className={classes.c}
          d="M-.014.541V.548L.53-.015Z"
          transform="translate(139.34 27.367)"
        />
        <path
          className={classes.a}
          d="M.676,4.654,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(139.194 22.698)"
        />
        <path
          className={classes.c}
          d="M.676,4.654,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(139.194 22.698)"
        />
        <path
          className={classes.a}
          d="M.661-.019-.017.673,1.405,2.781l1.209.1Z"
          transform="translate(136.559 11.363)"
        />
        <path
          className={classes.c}
          d="M.661-.019-.017.673,1.405,2.781l1.209.1Z"
          transform="translate(136.559 11.363)"
        />
        <path
          className={classes.a}
          d="M13.431,8.243,11.294,5.08,13.3,3.045l2.131,3.17ZM8.053,7.03,5.916,3.86l2-2.034L10.058,5Zm12.713.386.678-.678-2.137-3.17,2.006-2.034-.276-.414-.578-.043L18.791,2.792,17.473.852l-1.215-.1,1.889,2.689-2,2.033-2.137-3.17L15.671.626l-1.215-.1-.967.989L12.735.392,11.52.294l1.291,1.941-2,2.034L8.67,1.107,9.6.151,8.388.053l-.25.257L7.941.02l-1.215-.1.729,1.087-2,2.035L3.3-.093,2.614.585,4.75,3.755,2.745,5.789.593,2.619-.085,3.3l2.137,3.17L.279,8.277l1.209.1L2.6,7.256l.859,1.295,1.215.1L3.282,6.578l2-2.035,2.131,3.17L6.363,8.8l1.215.091.378-.379.3.439,1.215.1L8.633,7.811l2-2.034,2.137,3.17-.344.346,1.825.153-.266-.4,2-2.034,1.845,2.736L18.5,9.8l.311-.312-2.137-3.17,2-2.033Z"
          transform="translate(139.295 5.96)"
        />
        <path
          className={classes.c}
          d="M13.431,8.243,11.294,5.08,13.3,3.045l2.131,3.17ZM8.053,7.03,5.916,3.86l2-2.034L10.058,5Zm12.713.386.678-.678-2.137-3.17,2.006-2.034-.276-.414-.578-.043L18.791,2.792,17.473.852l-1.215-.1,1.889,2.689-2,2.033-2.137-3.17L15.671.626l-1.215-.1-.967.989L12.735.392,11.52.294l1.291,1.941-2,2.034L8.67,1.107,9.6.151,8.388.053l-.25.257L7.941.02l-1.215-.1.729,1.087-2,2.035L3.3-.093,2.614.585,4.75,3.755,2.745,5.789.593,2.619-.085,3.3l2.137,3.17L.279,8.277l1.209.1L2.6,7.256l.859,1.295,1.215.1L3.282,6.578l2-2.035,2.131,3.17L6.363,8.8l1.215.091.378-.379.3.439,1.215.1L8.633,7.811l2-2.034,2.137,3.17-.344.346,1.825.153-.266-.4,2-2.034,1.845,2.736L18.5,9.8l.311-.312-2.137-3.17,2-2.033Z"
          transform="translate(139.295 5.96)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(150.131 8.251)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(150.131 8.251)"
        />
        <path
          className={classes.a}
          d="M.244-.007l-.25.257Z"
          transform="translate(147.418 6.034)"
        />
        <path
          className={classes.c}
          d="M.244-.007l-.25.257Z"
          transform="translate(147.418 6.034)"
        />
        <path
          className={classes.a}
          d="M.86,1.274.005,0H0Z"
          transform="translate(141.893 13.255)"
        />
        <path
          className={classes.c}
          d="M.86,1.274.005,0H0Z"
          transform="translate(141.893 13.255)"
        />
        <path
          className={classes.a}
          d="M.755,1.125,0,0,.755,1.125l.967-.99Z"
          transform="translate(152.007 6.379)"
        />
        <path
          className={classes.c}
          d="M.755,1.125,0,0Zm0,0,.967-.99Z"
          transform="translate(152.007 6.379)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.17l2-2.047-2,2.047L0,0Z"
          transform="translate(153.299 8.293)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.17l2-2.047-2,2.047L0,0Z"
          transform="translate(153.299 8.293)"
        />
        <path
          className={classes.a}
          d="M1.318,1.953,3,.238H3L1.318,1.953,0,0,1.318,1.953Z"
          transform="translate(156.801 6.764)"
        />
        <path
          className={classes.c}
          d="M1.318,1.953,3,.238H3Zm0,0L0,0Z"
          transform="translate(156.801 6.764)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(152.799 12.209)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(152.799 12.209)"
        />
        <path
          className={classes.a}
          d="M.132,5.216,2.137,3.17,0,0,2.131,3.17Z"
          transform="translate(147.265 7.772)"
        />
        <path
          className={classes.c}
          d="M.132,5.216,2.137,3.17,0,0,2.131,3.17Z"
          transform="translate(147.265 7.772)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.17l-.344.345.344-.346L0,0Z"
          transform="translate(149.933 11.73)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.17l-.344.345.344-.346L0,0Z"
          transform="translate(149.933 11.73)"
        />
        <path
          className={classes.a}
          d="M0,0,2.131,3.163,1.076,4.248,2.137,3.163Z"
          transform="translate(144.582 10.517)"
        />
        <path
          className={classes.c}
          d="M0,0,2.131,3.163,1.076,4.248,2.137,3.163Z"
          transform="translate(144.582 10.517)"
        />
        <path
          className={classes.a}
          d="M0,0,.3.44Z"
          transform="translate(147.25 14.475)"
        />
        <path
          className={classes.c}
          d="M.3.44,0,0Z"
          transform="translate(147.25 14.475)"
        />
        <path
          className={classes.a}
          d="M0,0,.578.043Z"
          transform="translate(159.802 7.003)"
        />
        <path
          className={classes.c}
          d="M.578.043,0,0Z"
          transform="translate(159.802 7.003)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(150.792 6.282)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(150.792 6.282)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(153.729 6.515)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(153.729 6.515)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(147.661 6.027)"
        />
        <path
          className={classes.c}
          d="M1.215.1,0,0,1.215.1Z"
          transform="translate(147.662 6.027)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1,1.209.091Z"
          transform="translate(155.586 6.667)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1V.091Z"
          transform="translate(155.587 6.667)"
        />
        <path
          className={classes.a}
          d="M.652-.018-.017.665l24.547,36.4.669-.683L.652-.018Z"
          transform="translate(147.292 0.403)"
        />
        <path
          className={classes.c}
          d="M.652-.018-.017.665l24.547,36.4.669-.683L.652-.018Z"
          transform="translate(147.292 0.403)"
        />
        <path
          className={classes.a}
          d="M.652-.018-.017.665l24.547,36.4.669-.683L.652-.018Z"
          transform="translate(125.824 22.335)"
        />
        <path
          className={classes.c}
          d="M.652-.018-.017.665l24.547,36.4.669-.683L.652-.018Z"
          transform="translate(125.824 22.335)"
        />
        <path
          className={classes.a}
          d="M37.029,14.575,9.807,42.407A.887.887,0,0,1,8.382,42.3l-8.9-13.189a1.449,1.449,0,0,1,.175-1.745L26.915-.472a.889.889,0,0,1,1.426.111l8.89,13.19a1.455,1.455,0,0,1-.169,1.745"
          transform="translate(107.902 50.37)"
        />
        <path
          className={classes.c}
          d="M37.029,14.575,9.807,42.407A.887.887,0,0,1,8.382,42.3l-8.9-13.189a1.449,1.449,0,0,1,.175-1.745L26.915-.472a.889.889,0,0,1,1.426.111l8.89,13.19a1.453,1.453,0,0,1-.2,1.745Z"
          transform="translate(107.902 50.37)"
        />
        <path
          className={classes.a}
          d="M0,0,1,1.488,1.684.8,1.215.1Z"
          transform="translate(117.882 90.056)"
        />
        <path
          className={classes.c}
          d="M0,0,1,1.488,1.684.8,1.215.1Z"
          transform="translate(117.882 90.056)"
        />
        <path
          className={classes.a}
          d="M.443.66,1.009.083,0,0Z"
          transform="translate(114.303 89.768)"
        />
        <path
          className={classes.c}
          d="M.443.66,1.009.083,0,0Z"
          transform="translate(114.303 89.768)"
        />
        <path
          className={classes.a}
          d="M26.55,6.739l-2.137-3.17,2-2.033,2.131,3.17ZM23.862,9.451,21.731,6.289l2-2.034,2.137,3.169Zm-2.683,2.712-2.137-3.17L21.048,6.96l2.136,3.17ZM18.5,14.876l-2.137-3.17,2.005-2.033L20.5,12.842Zm-2.682,2.712-2.137-3.163,2-2.034,2.132,3.163Zm-2.689,2.745-2.131-3.17,2-2.034,2.137,3.17ZM21.2,5.407,19.06,2.237l2-2.033L23.2,3.373ZM18.514,8.119l-2.137-3.17,2.005-2.034,2.137,3.17Zm-2.682,2.712-2.137-3.17L15.7,5.628,17.831,8.8Zm-2.688,2.712-2.132-3.163,2-2.034,2.137,3.163ZM10.46,16.289l-2.137-3.17,2-2.034,2.137,3.17ZM7.777,19,5.641,15.838l2-2.034,2.137,3.163ZM33.913,5.681,34.59,5l-2.137-3.17.877-.888-1.215-.1-.194.2L31.771.818,30.556.72l.688,1.016-2,2.033L27.1.607l.156-.162-1.5-.123.125.188L23.882,2.543,22.189.02,19.976-.155,18.532,1.323,17.4-.359l-1.215-.1,1.667,2.478-2,2.033L13.712.885l-.683.679,2.136,3.17-2,2.034L11.024,3.6l-.678.678,2.137,3.163-2,2.034L8.341,6.31l-.677.678L9.8,10.158l-2,2.034L5.659,9.022,4.976,9.7l2.137,3.163-2,2.034L2.977,11.734l-.683.679,2.137,3.169L2.425,17.616.288,14.447l-.677.678,2.136,3.17L.637,19.42l1.215.1.417-.427.328.485,1.215.1-.859-1.295,2-2.034,2.131,3.17L6.71,19.9l1.895.157-.3-.446,2-2.034,1.876,2.775,1.809.146,1.667-1.708,1.308,1.941,1.215.1-1.845-2.736,2-2.034,2.131,3.163.683-.679-2.137-3.17,2-2.034,2.137,3.17.678-.678-2.132-3.163,2-2.034L25.844,13.8l.677-.678L24.39,9.957,26.4,7.923l2.131,3.17.683-.678L27.073,7.252l2-2.034,2.131,3.164.683-.679L29.755,4.54l2-2.034,2.153,3.176Z"
          transform="translate(109.141 60.964)"
        />
        <path
          className={classes.c}
          d="M26.55,6.739l-2.137-3.17,2-2.033,2.131,3.17ZM23.862,9.451,21.731,6.289l2-2.034,2.137,3.169Zm-2.683,2.712-2.137-3.17L21.048,6.96l2.136,3.17ZM18.5,14.876l-2.137-3.17,2.005-2.033L20.5,12.842Zm-2.682,2.712-2.137-3.163,2-2.034,2.132,3.163Zm-2.689,2.745-2.131-3.17,2-2.034,2.137,3.17ZM21.2,5.407,19.06,2.237l2-2.033L23.2,3.373ZM18.514,8.119l-2.137-3.17,2.005-2.034,2.137,3.17Zm-2.682,2.712-2.137-3.17L15.7,5.628,17.831,8.8Zm-2.688,2.712-2.132-3.163,2-2.034,2.137,3.163ZM10.46,16.289l-2.137-3.17,2-2.034,2.137,3.17ZM7.777,19,5.641,15.838l2-2.034,2.137,3.163ZM33.913,5.681,34.59,5l-2.137-3.17.877-.888-1.215-.1-.194.2L31.771.818,30.556.72l.688,1.016-2,2.033L27.1.607l.156-.162-1.5-.123.125.188L23.882,2.543,22.189.02,19.976-.155,18.532,1.323,17.4-.359l-1.215-.1,1.667,2.478-2,2.033L13.712.885l-.683.679,2.136,3.17-2,2.034L11.024,3.6l-.678.678,2.137,3.163-2,2.034L8.341,6.31l-.677.678L9.8,10.158l-2,2.034L5.659,9.022,4.976,9.7l2.137,3.163-2,2.034L2.977,11.734l-.683.679,2.137,3.169L2.425,17.616.288,14.447l-.677.678,2.136,3.17L.637,19.42l1.215.1.417-.427.328.485,1.215.1-.859-1.295,2-2.034,2.131,3.17L6.71,19.9l1.895.157-.3-.446,2-2.034,1.876,2.775,1.809.146,1.667-1.708,1.308,1.941,1.215.1-1.845-2.736,2-2.034,2.131,3.163.683-.679-2.137-3.17,2-2.034,2.137,3.17.678-.678-2.132-3.163,2-2.034L25.844,13.8l.677-.678L24.39,9.957,26.4,7.923l2.131,3.17.683-.678L27.073,7.252l2-2.034,2.131,3.164.683-.679L29.755,4.54l2-2.034,2.153,3.176Z"
          transform="translate(109.141 60.964)"
        />
        <path
          className={classes.a}
          d="M5.234,5.976,3.1,2.806,5.1.773,7.239,3.942Zm7.357-.866.678-.678-2.131-3.17.333-.339L9.663.776l.261.388L7.918,3.2,6.084.482,4.189.324,2.567,1.985,1.29.1.075,0,1.884,2.676l-2,2.034.52.8L2.4,3.472,4.536,6.635l-2,2.034.089.136,1.009.077L5.073,7.418,6.2,9.093l1.215.1L5.751,6.72l2-2.034,2.137,3.17.678-.678L8.454,4.02l2-2.034Z"
          transform="translate(111.663 80.918)"
        />
        <path
          className={classes.c}
          d="M5.234,5.976,3.1,2.806,5.1.773,7.239,3.942Zm7.357-.866.678-.678-2.131-3.17.333-.339L9.663.776l.261.388L7.918,3.2,6.084.482,4.189.324,2.567,1.985,1.29.1.075,0,1.884,2.676l-2,2.034.52.8L2.4,3.472,4.536,6.635l-2,2.034.089.136,1.009.077L5.073,7.418,6.2,9.093l1.215.1L5.751,6.72l2-2.034,2.137,3.17.678-.678L8.454,4.02l2-2.034Z"
          transform="translate(111.663 80.918)"
        />
        <path
          className={classes.a}
          d="M2.1.073.513,1.693-.023.9.882-.025Z"
          transform="translate(108.896 80.818)"
        />
        <path
          className={classes.c}
          d="M2.1.073.513,1.693-.023.9.882-.025Z"
          transform="translate(108.896 80.818)"
        />
        <path
          className={classes.a}
          d="M.824,1.222l.683-.7L1.215.1,0,0Z"
          transform="translate(126.114 82.099)"
        />
        <path
          className={classes.c}
          d="M.824,1.222l.683-.7L1.215.1,0,0Z"
          transform="translate(126.114 82.099)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(117.511 72.29)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(117.511 72.29)"
        />
        <path
          className={classes.a}
          d="M2.085,5.155-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(114.827 75.035)"
        />
        <path
          className={classes.c}
          d="M2.085,5.155-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(114.827 75.035)"
        />
        <path
          className={classes.a}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(122.882 66.806)"
        />
        <path
          className={classes.c}
          d="M2.085,5.162-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(122.882 66.806)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.046,1.991l2-2.047L-.051,1.992Z"
          transform="translate(120.194 69.551)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.046,1.991l2-2.047L-.051,1.992Z"
          transform="translate(120.194 69.551)"
        />
        <path
          className={classes.a}
          d="M.688,1.016,0,0Z"
          transform="translate(139.692 61.896)"
        />
        <path
          className={classes.c}
          d="M.688,1.016,0,0Z"
          transform="translate(139.692 61.896)"
        />
        <path
          className={classes.a}
          d="M.152,0,0,.159Z"
          transform="translate(136.242 61.637)"
        />
        <path
          className={classes.c}
          d="M.152,0,0,.152V.16L.152,0Z"
          transform="translate(136.242 61.637)"
        />
        <path
          className={classes.a}
          d="M0,0,.126.188Z"
          transform="translate(134.898 61.51)"
        />
        <path
          className={classes.c}
          d="M.126.188,0,0Z"
          transform="translate(134.898 61.51)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.955-.055l2.137,3.17L1.955-.055-.051,1.992Z"
          transform="translate(125.565 64.067)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.955-.055l2.137,3.17L1.955-.055-.051,1.992Z"
          transform="translate(125.565 64.067)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055H1.949l-2,2.047.86,1.281Z"
          transform="translate(112.144 77.773)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055H1.949Zm0,0,.86,1.281Z"
          transform="translate(112.144 77.773)"
        />
        <path
          className={classes.a}
          d="M0,0,1.271,1.889Z"
          transform="translate(112.953 81.046)"
        />
        <path
          className={classes.c}
          d="M0,0,1.271,1.889Z"
          transform="translate(112.953 81.046)"
        />
        <path
          className={classes.a}
          d="M2.085,5.162-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(125.55 70.764)"
        />
        <path
          className={classes.c}
          d="M2.085,5.162-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(125.55 70.764)"
        />
        <path
          className={classes.a}
          d="M1.955-.055l2.136,3.17L1.955-.055-.051,1.992-.045,2Z"
          transform="translate(130.917 65.281)"
        />
        <path
          className={classes.c}
          d="M1.955-.055l2.136,3.17Zm0,0L-.051,1.992-.045,2Z"
          transform="translate(130.917 65.281)"
        />
        <path
          className={classes.a}
          d="M2.086,5.162-.051,1.992,1.955-.055,4.091,3.108,1.955-.055-.051,1.992Z"
          transform="translate(128.234 68.026)"
        />
        <path
          className={classes.c}
          d="M2.086,5.162-.051,1.992,1.955-.055,4.091,3.108,1.955-.055-.051,1.992Z"
          transform="translate(128.234 68.026)"
        />
        <path
          className={classes.a}
          d="M1.954-.055l2.131,3.17L1.954-.055-.051,1.992Z"
          transform="translate(133.606 62.542)"
        />
        <path
          className={classes.c}
          d="M1.954-.055l2.131,3.17Zm0,0L-.051,1.992Z"
          transform="translate(133.606 62.542)"
        />
        <path
          className={classes.a}
          d="M1.954-.049,4.091,3.114,1.954-.055-.051,1.992V2Z"
          transform="translate(128.249 61.322)"
        />
        <path
          className={classes.c}
          d="M1.954-.049,4.091,3.114,1.954-.055-.051,1.992V2Z"
          transform="translate(128.249 61.322)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(120.179 76.249)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(120.179 76.249)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.954-.055H1.949l-2,2.047Z"
          transform="translate(122.867 73.51)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.954-.055H1.949l-2,2.047Z"
          transform="translate(122.867 73.51)"
        />
        <path
          className={classes.a}
          d="M1.955-.055-.051,1.992l2.137,3.17L-.051,1.992Z"
          transform="translate(114.812 81.732)"
        />
        <path
          className={classes.c}
          d="M1.955-.055-.051,1.992l2.137,3.17L-.051,1.992Z"
          transform="translate(114.812 81.732)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055-.051,1.992l.3.446Z"
          transform="translate(117.495 78.994)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055Zm0,0,.3.446Z"
          transform="translate(117.495 78.994)"
        />
        <path
          className={classes.a}
          d="M0,0,1.834,2.717Z"
          transform="translate(117.746 81.431)"
        />
        <path
          className={classes.c}
          d="M0,0,1.834,2.717Z"
          transform="translate(117.746 81.431)"
        />
        <path
          className={classes.a}
          d="M-.012.481.571.525.846.549.471-.013l-.483.5Z"
          transform="translate(139.924 56.22)"
        />
        <path
          className={classes.c}
          d="M.846.549.471-.013l-.483.5L.571.525Z"
          transform="translate(139.924 56.22)"
        />
        <path
          className={classes.a}
          d="M2.161,2.207.661-.019l-.678.7.964,1.43Z"
          transform="translate(130.236 53.89)"
        />
        <path
          className={classes.c}
          d="M2.161,2.207.661-.019l-.678.7.964,1.43Z"
          transform="translate(130.236 53.89)"
        />
        <path
          className={classes.a}
          d="M2.657,4.632l.4.6,1.215.1L3.334,3.94,5.34,1.893,4.8,1.1l-2,2.047L.666-.019-.017.673l2.137,3.17L.925,5.063l1.215.1.517-.529Z"
          transform="translate(132.919 51.151)"
        />
        <path
          className={classes.c}
          d="M2.657,4.632l.4.6,1.215.1L3.334,3.94,5.34,1.893,4.8,1.1l-2,2.047L.666-.019-.017.673l2.137,3.17L.925,5.063l1.215.1.517-.529Z"
          transform="translate(132.919 51.151)"
        />
        <path
          className={classes.a}
          d="M.666-.018l-.683.7h.005l.464.692,1.215.1-1-1.488Z"
          transform="translate(124.864 59.374)"
        />
        <path
          className={classes.c}
          d="M.666-.018l-.683.7h.005l.464.692,1.215.1-1-1.488Z"
          transform="translate(124.864 59.374)"
        />
        <path
          className={classes.a}
          d="M1.545,4.946l2.208.175-.438-.646L5.32,2.427,7.332,5.409l1.5.122,1.85-1.884,1.449,2.147,1.215.1L11.355,2.949,13.36.9,13.2.669,12.928.644,12.345.6,10.823,2.16,9.625.381,8.41.283l1.73,2.568L8.135,4.9,6,1.736V1.729L7.492.21,6.277.113,5.467.94,4.831,0,3.616-.1l1.172,1.74L2.784,3.685.647.515l-.678.692v.007L2.106,4.376l-.561.569Z"
          transform="translate(127.566 56.101)"
        />
        <path
          className={classes.c}
          d="M1.545,4.946l2.208.175-.438-.646L5.32,2.427,7.332,5.409l1.5.122,1.85-1.884,1.449,2.147,1.215.1L11.355,2.949,13.36.9,13.2.669,12.928.644,12.345.6,10.823,2.16,9.625.381,8.41.283l1.73,2.568L8.135,4.9,6,1.736V1.729L7.492.21,6.277.113,5.467.94,4.831,0,3.616-.1l1.172,1.74L2.784,3.685.647.515l-.678.692v.007L2.106,4.376l-.561.569Z"
          transform="translate(127.566 56.101)"
        />
        <path
          className={classes.a}
          d="M-.047,1.84,1.8-.044,3.251,2.1,1.8-.051-.047,1.841Z"
          transform="translate(136.44 59.793)"
        />
        <path
          className={classes.c}
          d="M-.047,1.84,1.8-.044,3.251,2.1,1.8-.051-.047,1.841Z"
          transform="translate(136.44 59.793)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055,3.966,2.927,1.954-.055Z"
          transform="translate(130.932 58.583)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055,3.966,2.927,1.954-.055Z"
          transform="translate(130.932 58.583)"
        />
        <path
          className={classes.a}
          d="M1.45-.041-.038,1.477Z"
          transform="translate(133.608 56.353)"
        />
        <path
          className={classes.c}
          d="M1.45-.041-.038,1.477Z"
          transform="translate(133.608 56.353)"
        />
        <path
          className={classes.a}
          d="M1.73,2.568,0,0,1.73,2.568Z"
          transform="translate(135.976 56.385)"
        />
        <path
          className={classes.c}
          d="M1.73,2.568,0,0,1.73,2.568Z"
          transform="translate(135.976 56.385)"
        />
        <path
          className={classes.a}
          d="M.275.025,0,0Z"
          transform="translate(140.495 56.745)"
        />
        <path
          className={classes.c}
          d="M.275.025,0,0Z"
          transform="translate(140.495 56.745)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1,0,0Z"
          transform="translate(131.182 55.999)"
        />
        <path
          className={classes.c}
          d="M1.215.1,0,0Z"
          transform="translate(131.182 55.999)"
        />
        <path
          className={classes.a}
          d="M1.214.1.108.01ZM0,0,.108,0Z"
          transform="translate(135.976 56.385)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(135.976 56.385)"
        />
        <path
          className={classes.a}
          d="M1.168,1.9,2.3.739,1.765-.05-.046,1.8Z"
          transform="translate(141.298 60.223)"
        />
        <path
          className={classes.c}
          d="M1.168,1.9,2.3.739,1.765-.05-.046,1.8Z"
          transform="translate(141.298 60.223)"
        />
        <path
          className={classes.a}
          d="M37.057,14.575,9.8,42.407a.938.938,0,0,1-.747.307.911.911,0,0,1-.678-.418L-.512,29.107a1.373,1.373,0,0,1-.208-.894,1.409,1.409,0,0,1,.383-.844L26.917-.471a.887.887,0,0,1,1.425.111l8.89,13.189a1.428,1.428,0,0,1,.084,1.363,1.307,1.307,0,0,1-.259.382"
          transform="translate(93.471 29.005)"
        />
        <path
          className={classes.c}
          d="M37.057,14.575,9.8,42.407a.938.938,0,0,1-.747.307.911.911,0,0,1-.678-.418L-.512,29.107a1.373,1.373,0,0,1-.208-.894,1.409,1.409,0,0,1,.383-.844L26.917-.471a.887.887,0,0,1,1.425.111l8.89,13.189a1.428,1.428,0,0,1,.084,1.363,1.307,1.307,0,0,1-.259.382h0Z"
          transform="translate(93.471 29.005)"
        />
        <path
          className={classes.a}
          d="M-.017.68l1.4,2.083,1.215.1L.661-.019l-.678.7Z"
          transform="translate(113.149 35.27)"
        />
        <path
          className={classes.c}
          d="M-.017.68l1.4,2.083,1.215.1L.661-.019l-.678.7Z"
          transform="translate(113.149 35.27)"
        />
        <path
          className={classes.a}
          d="M.291.434.664.054,0,0Z"
          transform="translate(125.664 47.816)"
        />
        <path
          className={classes.c}
          d="M.291.434.664.054,0,0Z"
          transform="translate(125.664 47.816)"
        />
        <path
          className={classes.a}
          d="M-.008.317.564.366.314-.009Z"
          transform="translate(128.346 38.816)"
        />
        <path
          className={classes.c}
          d="M.314-.009-.008.317.564.366.314-.009Z"
          transform="translate(128.346 38.816)"
        />
        <path
          className={classes.a}
          d="M1.51,8.4,2.582,7.3l.844,1.248,1.215.1L3.265,6.612l2-2.047L7.4,7.727,6.363,8.792l1.215.1.361-.366.281.414,1.21.1-.813-1.21,2.005-2.047-.532-.789L8.085,7.036,5.949,3.866,7.954,1.819l-.537-.789L5.412,3.077,3.275-.092,2.6.606,4.734,3.769,2.729,5.816.592,2.653l-.678.691,2.137,3.17L.3,8.3Z"
          transform="translate(115.901 29.86)"
        />
        <path
          className={classes.c}
          d="M1.51,8.4,2.582,7.3l.844,1.248,1.215.1L3.265,6.612l2-2.047L7.4,7.727,6.363,8.792l1.215.1.361-.366.281.414,1.21.1-.813-1.21,2.005-2.047-.532-.789L8.085,7.036,5.949,3.866,7.954,1.819l-.537-.789L5.412,3.077,3.275-.092,2.6.606,4.734,3.769,2.729,5.816.592,2.653l-.678.691,2.137,3.17L.3,8.3Z"
          transform="translate(115.901 29.86)"
        />
        <path
          className={classes.a}
          d="M7.972,7.026,5.841,3.856,7.846,1.822l2.131,3.17Zm7.363-.867.678-.678-2.137-3.17L15.1,1.064l-1.215-.1-.555.557L12.906.875l-1.215-.1L12.656,2.2l-2,2.034L8.514,1.072,9.025.549,6.9.379l.4.6L5.294,3.008,3.32.078,1.743-.049l-1.79,1.9.52.8L2.478.609,4.615,3.772,2.61,5.806l.521.8,2-2.034L7.272,7.731,5.267,9.765l.521.8,2-2.034L9.924,11.69l.683-.679L8.47,7.842l2-2.033,2.137,3.17.678-.678L11.152,5.137l2.022-2.081Z"
          transform="translate(94.517 58.503)"
        />
        <path
          className={classes.c}
          d="M7.972,7.026,5.841,3.856,7.846,1.822l2.131,3.17Zm7.363-.867.678-.678-2.137-3.17L15.1,1.064l-1.215-.1-.555.557L12.906.875l-1.215-.1L12.656,2.2l-2,2.034L8.514,1.072,9.025.549,6.9.379l.4.6L5.294,3.008,3.32.078,1.743-.049l-1.79,1.9.52.8L2.478.609,4.615,3.772,2.61,5.806l.521.8,2-2.034L7.272,7.731,5.267,9.765l.521.8,2-2.034L9.924,11.69l.683-.679L8.47,7.842l2-2.033,2.137,3.17.678-.678L11.152,5.137l2.022-2.081Z"
          transform="translate(94.517 58.503)"
        />
        <path
          className={classes.a}
          d="M0,0,1.522,2.258,2.2,1.566,1.209.1Z"
          transform="translate(111.012 59.699)"
        />
        <path
          className={classes.c}
          d="M0,0,1.522,2.258,2.2,1.566,1.209.1Z"
          transform="translate(111.012 59.699)"
        />
        <path
          className={classes.a}
          d="M.66-.019-.017.673.671,1.695l1.215.1Z"
          transform="translate(97.039 51.722)"
        />
        <path
          className={classes.c}
          d="M.66-.019-.017.673.671,1.695l1.215.1Z"
          transform="translate(97.039 51.722)"
        />
        <path
          className={classes.a}
          d="M13.468,8.282,11.337,5.119l2-2.035,2.137,3.163ZM8.09,7.069,5.953,3.9,7.958,1.865l2.136,3.17Zm15.4-2.359.677-.678L22.3,1.263l-1.825-.146L18.807,2.812,17.5.871,16.29.773,18.124,3.5l-2,2.034-2.137-3.17L15.615.706,14.4.609l-.95.969-.74-1.1L11.5.381,12.773,2.27l-2,2.033L8.631,1.141,9.543.206,8.333.108,8.1.345,7.917.08,6.7-.018l.719,1.061-2,2.034L3.28-.092,2.6.586l2.131,3.17L2.729,5.79.6,2.62-.085,3.3l2.137,3.17-.839.854,1.215.1.161-.163.134.26,1.215.1-.651-.977,2-2.034,2.137,3.17L7.3,7.9l1.436.118-.1-.149,2-2.034,1.673,2.484L14.6,8.5l1.416-1.444L17.125,8.7l1.215.1L16.693,6.358,18.7,4.324l2.136,3.17.678-.679L19.375,3.653,21.38,1.619,23.486,4.71Z"
          transform="translate(99.759 46.28)"
        />
        <path
          className={classes.c}
          d="M13.468,8.282,11.337,5.119l2-2.035,2.137,3.163ZM8.09,7.069,5.953,3.9,7.958,1.865l2.136,3.17Zm15.4-2.359.677-.678L22.3,1.263l-1.825-.146L18.807,2.812,17.5.871,16.29.773,18.124,3.5l-2,2.034-2.137-3.17L15.615.706,14.4.609l-.95.969-.74-1.1L11.5.381,12.773,2.27l-2,2.033L8.631,1.141,9.543.206,8.333.108,8.1.345,7.917.08,6.7-.018l.719,1.061-2,2.034L3.28-.092,2.6.586l2.131,3.17L2.729,5.79.6,2.62-.085,3.3l2.137,3.17-.839.854,1.215.1.161-.163.134.26,1.215.1-.651-.977,2-2.034,2.137,3.17L7.3,7.9l1.436.118-.1-.149,2-2.034,1.673,2.484L14.6,8.5l1.416-1.444L17.125,8.7l1.215.1L16.693,6.358,18.7,4.324l2.136,3.17.678-.679L19.375,3.653,21.38,1.619,23.486,4.71Z"
          transform="translate(99.759 46.28)"
        />
        <path
          className={classes.a}
          d="M18.706,2.537,16.7,4.585l1.141,1.7-1.209-.1-.615-.905-.778.793-1.215-.1,1.461-1.491L13.349,1.325l-2.006,2.04L13.053,5.9l-1.215-.1-1.172-1.74L9.171,5.588,7.045,5.417,5.31,2.843,3.3,4.89l.161.239L1.889,5l.205-.21L-.042,1.63l.678-.7L2.772,4.1,4.778,2.054,3.329-.094,4.544,0l.911,1.359L6.622.169l1.215.1L5.993,2.151l2.131,3.17,2.005-2.054L8.123.291l1.215.1,1.475,2.186L12.69.658l1.436.117L16.163,3.8l2.006-2.047-.464-.685,2.267.179-.589.6,2.137,3.163-.683.7Z"
          transform="translate(94.38 53.51)"
        />
        <path
          className={classes.c}
          d="M18.706,2.537,16.7,4.585l1.141,1.7-1.209-.1-.615-.905-.778.793-1.215-.1,1.461-1.491L13.349,1.325l-2.006,2.04L13.053,5.9l-1.215-.1-1.172-1.74L9.171,5.588,7.045,5.417,5.31,2.843,3.3,4.89l.161.239L1.889,5l.205-.21L-.042,1.63l.678-.7L2.772,4.1,4.778,2.054,3.329-.094,4.544,0l.911,1.359L6.622.169l1.215.1L5.993,2.151l2.131,3.17,2.005-2.054L8.123.291l1.215.1,1.475,2.186L12.69.658l1.436.117L16.163,3.8l2.006-2.047-.464-.685,2.267.179-.589.6,2.137,3.163-.683.7Z"
          transform="translate(94.38 53.51)"
        />
        <path
          className={classes.a}
          d="M1.7.829l-.683.691L0,0,1.215.1,1.7.829Z"
          transform="translate(116.879 54.952)"
        />
        <path
          className={classes.c}
          d="M1.7.829l-.683.691L0,0,1.215.1,1.7.829Z"
          transform="translate(116.879 54.952)"
        />
        <path
          className={classes.a}
          d="M0,0V.007L2.131,3.17,2.009,3.3l.128-.129Z"
          transform="translate(105.061 50.869)"
        />
        <path
          className={classes.c}
          d="M0,0V.007L2.131,3.17,2.009,3.3l.128-.129Z"
          transform="translate(105.061 50.869)"
        />
        <path
          className={classes.a}
          d="M-.048,1.867,1.83-.052Z"
          transform="translate(105.24 54.22)"
        />
        <path
          className={classes.c}
          d="M-.048,1.867,1.83-.052Z"
          transform="translate(105.24 54.22)"
        />
        <path
          className={classes.a}
          d="M1.938.552-.067,2.6,1.938.552l-.4-.6Z"
          transform="translate(99.888 58.971)"
        />
        <path
          className={classes.c}
          d="M1.938.552-.067,2.6Zm0,0-.4-.6Z"
          transform="translate(99.888 58.971)"
        />
        <path
          className={classes.a}
          d="M1.735,2.575,0,0V.006L1.735,2.574Z"
          transform="translate(99.69 56.354)"
        />
        <path
          className={classes.c}
          d="M1.735,2.575,0,0V.006L1.735,2.574Z"
          transform="translate(99.69 56.354)"
        />
        <path
          className={classes.a}
          d="M.222-.006-.006.231.227-.006Z"
          transform="translate(107.897 46.413)"
        />
        <path
          className={classes.c}
          d="M-.006.231.227-.006H.222Z"
          transform="translate(107.897 46.413)"
        />
        <path
          className={classes.a}
          d="M-.024.943.92-.026Z"
          transform="translate(113.272 46.92)"
        />
        <path
          className={classes.c}
          d="M-.024.943.92-.026Z"
          transform="translate(113.272 46.92)"
        />
        <path
          className={classes.a}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(118.641 47.428)"
        />
        <path
          className={classes.c}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(118.641 47.428)"
        />
        <path
          className={classes.a}
          d="M1.9,1.87-.1,3.918,1.9,1.872.628-.018,1.9,1.872Z"
          transform="translate(110.66 46.684)"
        />
        <path
          className={classes.c}
          d="M1.9,1.87-.1,3.918,1.9,1.872Zm0,0L.628-.018,1.9,1.871Z"
          transform="translate(110.66 46.684)"
        />
        <path
          className={classes.a}
          d="M1.877,2.728l-2,2.047L1.883,2.728.043,0Z"
          transform="translate(116.038 47.047)"
        />
        <path
          className={classes.c}
          d="M1.877,2.728l-2,2.047L1.883,2.728.043,0Z"
          transform="translate(116.038 47.047)"
        />
        <path
          className={classes.a}
          d="M.131,5.217,2.137,3.17,0,0,2.137,3.17Z"
          transform="translate(107.745 48.131)"
        />
        <path
          className={classes.c}
          d="M.131,5.217,2.137,3.17,0,0,2.137,3.17Z"
          transform="translate(107.745 48.131)"
        />
        <path
          className={classes.a}
          d="M0,0,.126.188Z"
          transform="translate(102.378 53.615)"
        />
        <path
          className={classes.c}
          d="M0,0,.126.188Z"
          transform="translate(102.378 53.615)"
        />
        <path
          className={classes.a}
          d="M2.006,2.976,0,5.03H.006l2-2.054L0,0Z"
          transform="translate(102.503 53.802)"
        />
        <path
          className={classes.c}
          d="M2.006,2.976,0,5.03H.006Zm0,0L0,0Z"
          transform="translate(102.503 53.802)"
        />
        <path
          className={classes.a}
          d="M0,0l2.131,3.17L.126,5.217H.131l2-2.047L0,0Z"
          transform="translate(102.363 60.311)"
        />
        <path
          className={classes.c}
          d="M0,0l2.131,3.17L.126,5.217H.131l2-2.047L0,0Z"
          transform="translate(102.363 60.311)"
        />
        <path
          className={classes.a}
          d="M0,0,1.673,2.478Z"
          transform="translate(110.413 52.089)"
        />
        <path
          className={classes.c}
          d="M0,0,1.673,2.478Z"
          transform="translate(110.413 52.089)"
        />
        <path
          className={classes.a}
          d="M1.936.651-.069,2.7,1.936.651,1.473-.042Z"
          transform="translate(110.613 54.608)"
        />
        <path
          className={classes.c}
          d="M1.936.651-.069,2.7Zm0,0L1.473-.042Z"
          transform="translate(110.613 54.608)"
        />
        <path
          className={classes.a}
          d="M.132,5.21l2-2.047L0,0,2.132,3.163Z"
          transform="translate(113.101 49.351)"
        />
        <path
          className={classes.c}
          d="M.132,5.21l2-2.047L0,0,2.132,3.163Z"
          transform="translate(113.101 49.351)"
        />
        <path
          className={classes.a}
          d="M1.911,1.4l-2,2.047V3.45L1.917,1.4.953-.027Z"
          transform="translate(105.266 59.34)"
        />
        <path
          className={classes.c}
          d="M1.911,1.4l-2,2.047V3.45L1.917,1.4.953-.027Z"
          transform="translate(105.266 59.34)"
        />
        <path
          className={classes.a}
          d="M1.172,1.74,0,0Z"
          transform="translate(105.046 57.573)"
        />
        <path
          className={classes.c}
          d="M1.172,1.74,0,0Z"
          transform="translate(105.046 57.573)"
        />
        <path
          className={classes.a}
          d="M.531-.015-.014.541Z"
          transform="translate(107.874 59.504)"
        />
        <path
          className={classes.c}
          d="M-.014.541.531-.015Z"
          transform="translate(107.874 59.504)"
        />
        <path
          className={classes.a}
          d="M.676,4.654,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(107.729 54.835)"
        />
        <path
          className={classes.c}
          d="M.676,4.654,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(107.729 54.835)"
        />
        <path
          className={classes.a}
          d="M.66-.019l-.678.7L1.4,2.788l1.215.1L.66-.019Z"
          transform="translate(105.094 43.493)"
        />
        <path
          className={classes.c}
          d="M.66-.019l-.678.7L1.4,2.788l1.215.1L.66-.019Z"
          transform="translate(105.094 43.493)"
        />
        <path
          className={classes.a}
          d="M13.383,8.224l-2.137-3.17L13.257,3.02l2.131,3.17ZM8,7,5.868,3.842l2-2.034L10.01,4.97Zm12.757.385.677-.678L19.3,3.542l2-2.034-.276-.414-.577-.05L18.787,2.765,17.468.826l-1.215-.1,1.855,2.75-2,2.034L13.967,2.348,15.633.666l-1.215-.1-.966.984L12.7.426l-1.215-.1,1.292,1.94-2,2.034L8.632,1.133,9.565.184,8.35.087,8.1.344,7.9.054l-1.215-.1.73,1.08L5.412,3.07,3.281-.093,2.6.585l2.137,3.17L2.729,5.788.593,2.619-.085,3.3,2.052,6.46.226,8.272l1.209.1,1.11-1.112L3.4,8.552l1.215.1L3.228,6.579,5.233,4.545l2.131,3.17L6.294,8.76l1.215.1.378-.387.3.44,1.215.1L8.564,7.78l2.005-2.034,2.136,3.163-.344.352,1.824.146-.266-.4,2.006-2.034L17.77,9.709l.659.054.311-.319L16.6,6.281l2-2.034Z"
          transform="translate(107.899 38.115)"
        />
        <path
          className={classes.c}
          d="M13.383,8.224l-2.137-3.17L13.257,3.02l2.131,3.17ZM8,7,5.868,3.842l2-2.034L10.01,4.97Zm12.757.385.677-.678L19.3,3.542l2-2.034-.276-.414-.577-.05L18.787,2.765,17.468.826l-1.215-.1,1.855,2.75-2,2.034L13.967,2.348,15.633.666l-1.215-.1-.966.984L12.7.426l-1.215-.1,1.292,1.94-2,2.034L8.632,1.133,9.565.184,8.35.087,8.1.344,7.9.054l-1.215-.1.73,1.08L5.412,3.07,3.281-.093,2.6.585l2.137,3.17L2.729,5.788.593,2.619-.085,3.3,2.052,6.46.226,8.272l1.209.1,1.11-1.112L3.4,8.552l1.215.1L3.228,6.579,5.233,4.545l2.131,3.17L6.294,8.76l1.215.1.378-.387.3.44,1.215.1L8.564,7.78l2.005-2.034,2.136,3.163-.344.352,1.824.146-.266-.4,2.006-2.034L17.77,9.709l.659.054.311-.319L16.6,6.281l2-2.034Z"
          transform="translate(107.899 38.115)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055Z"
          transform="translate(118.665 40.388)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055Z"
          transform="translate(118.665 40.388)"
        />
        <path
          className={classes.a}
          d="M.244-.007l-.25.257Z"
          transform="translate(115.953 38.17)"
        />
        <path
          className={classes.c}
          d="M.244-.007l-.25.257Z"
          transform="translate(115.953 38.17)"
        />
        <path
          className={classes.a}
          d="M.86,1.275,0,0Z"
          transform="translate(110.428 45.392)"
        />
        <path
          className={classes.c}
          d="M.86,1.275,0,0Z"
          transform="translate(110.428 45.392)"
        />
        <path
          className={classes.a}
          d="M.755,1.125,0,0,.755,1.125,1.722.143l-.967.983Z"
          transform="translate(120.542 38.509)"
        />
        <path
          className={classes.c}
          d="M.755,1.125,0,0,.755,1.125Zm0,0L1.722.143l-.967.983Z"
          transform="translate(120.542 38.509)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.163l2-2.047-2,2.047L0,0Z"
          transform="translate(121.834 40.43)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.163l2-2.047-2,2.047L0,0Z"
          transform="translate(121.834 40.43)"
        />
        <path
          className={classes.a}
          d="M1.319,1.96,3,.245H3L1.319,1.96,0,0Z"
          transform="translate(125.336 38.895)"
        />
        <path
          className={classes.c}
          d="M1.319,1.96,3,.245H3Zm0,0L0,0Z"
          transform="translate(125.336 38.895)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055Z"
          transform="translate(121.333 44.347)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055Z"
          transform="translate(121.333 44.347)"
        />
        <path
          className={classes.a}
          d="M.131,5.209,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(115.8 39.909)"
        />
        <path
          className={classes.c}
          d="M.131,5.209,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(115.8 39.909)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.163l-.345.352.345-.352Z"
          transform="translate(118.468 43.868)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.163l-.345.352.345-.352Z"
          transform="translate(118.468 43.868)"
        />
        <path
          className={classes.a}
          d="M0,0,2.131,3.17,1.076,4.248,2.131,3.17Z"
          transform="translate(113.117 42.647)"
        />
        <path
          className={classes.c}
          d="M0,0,2.131,3.17,1.076,4.248,2.131,3.17Z"
          transform="translate(113.117 42.647)"
        />
        <path
          className={classes.a}
          d="M0,0,.3.44Z"
          transform="translate(115.785 46.605)"
        />
        <path
          className={classes.c}
          d="M.3.439,0,0,.3.44Z"
          transform="translate(115.785 46.605)"
        />
        <path
          className={classes.a}
          d="M0,0,.578.049.006,0Z"
          transform="translate(128.332 39.133)"
        />
        <path
          className={classes.c}
          d="M.578.049.006,0H0Z"
          transform="translate(128.332 39.133)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(119.327 38.412)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(119.327 38.412)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(122.264 38.652)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1V.091Z"
          transform="translate(122.264 38.652)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(116.196 38.164)"
        />
        <path
          className={classes.c}
          d="M1.215.1,0,0Z"
          transform="translate(116.196 38.164)"
        />
        <path
          className={classes.a}
          d="M0,0,1.209.1Z"
          transform="translate(124.121 38.797)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1H1.209Z"
          transform="translate(124.121 38.797)"
        />
        <path
          className={classes.a}
          d="M.652-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(115.828 32.536)"
        />
        <path
          className={classes.c}
          d="M.652-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(115.828 32.536)"
        />
        <path
          className={classes.a}
          d="M.652-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(97.04 51.718)"
        />
        <path
          className={classes.c}
          d="M.652-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(97.04 51.718)"
        />
        <path
          className={classes.a}
          d="M62.31-1.757-1.632,63.549.2,66.262,64.14.957Z"
          transform="translate(14.018 103.728)"
        />
        <path
          className={classes.c}
          d="M62.31-1.757-1.632,63.549.2,66.262,64.14.957Z"
          transform="translate(14.018 103.728)"
        />
        <path
          className={classes.a}
          d="M37.056,14.594,9.791,42.427a.893.893,0,0,1-1.125.182.99.99,0,0,1-.3-.293L-.513,29.107A1.436,1.436,0,0,1-.6,27.748a1.312,1.312,0,0,1,.259-.38L26.916-.471a.879.879,0,0,1,1.121-.177.991.991,0,0,1,.3.295L37.23,12.882a1.448,1.448,0,0,1-.175,1.745"
          transform="translate(42.922 116.685)"
        />
        <path
          className={classes.c}
          d="M37.056,14.594,9.791,42.427a.893.893,0,0,1-1.125.182.99.99,0,0,1-.3-.293L-.513,29.107A1.436,1.436,0,0,1-.6,27.748a1.312,1.312,0,0,1,.259-.38L26.916-.471a.879.879,0,0,1,1.121-.177.991.991,0,0,1,.3.295L37.23,12.882a1.453,1.453,0,0,1-.174,1.712Z"
          transform="translate(42.922 116.685)"
        />
        <path
          className={classes.a}
          d="M0,0,1.006,1.488,1.684.8,1.215.1Z"
          transform="translate(52.924 156.391)"
        />
        <path
          className={classes.c}
          d="M0,0,1.006,1.488,1.684.8,1.215.1Z"
          transform="translate(52.924 156.391)"
        />
        <path
          className={classes.a}
          d="M.449.659,1.009.084,0,0Z"
          transform="translate(49.345 156.103)"
        />
        <path
          className={classes.c}
          d="M.449.659,1.009.084,0,0Z"
          transform="translate(49.345 156.103)"
        />
        <path
          className={classes.a}
          d="M26.573,6.5l-2.137-3.17L26.441,1.3l2.136,3.17ZM23.89,9.213,21.753,6.05l2.005-2.034,2.136,3.163Zm-2.683,2.746L19.07,8.789l2-2.034,2.131,3.17Zm-2.688,2.712-2.131-3.163,2-2.034,2.137,3.17Zm-2.683,2.745L13.7,14.247,15.7,12.213l2.137,3.17Zm-2.682,2.712-2.137-3.17,2.005-2.034,2.137,3.17ZM21.223,5.2,19.087,2.039,21.092,0l2.137,3.163ZM18.536,7.914,16.4,4.744l2-2.034,2.137,3.17Zm-2.682,2.712L13.716,7.463l2-2.034L17.858,8.6ZM13.17,13.371,11.033,10.2l2-2.034,2.137,3.17Zm-2.682,2.712-2.136-3.17,2-2.034,2.137,3.17ZM7.8,18.8,5.668,15.633,7.673,13.6,9.8,16.762ZM33.934,5.476l.683-.679-2.136-3.17L33.353.74l-1.215-.1-.189.2L31.8.613l-1.215-.1.683,1.015L29.261,3.564,27.13.4l.156-.162L25.79.116,25.915.3,23.91,2.338l-1.7-2.516L20-.36,18.527,1.323,17.39-.358l-1.209-.1,1.667,2.477-2,2.034L13.707.886l-.678.678,2.137,3.17L13.161,6.767,11.025,3.6l-.678.678,2.137,3.169L10.478,9.479,8.342,6.31l-.683.678L9.8,10.158l-2,2.034L5.654,9.022,4.976,9.7l2.137,3.163-2,2.034L2.971,11.734l-.677.678,2.137,3.17-2,2.034L.289,14.446l-.678.679,2.137,3.163L.637,19.413l1.215.1.417-.427.328.485,1.215.1-.865-1.294,2-2.034,2.137,3.169-.383.387,1.895.157-.3-.446,2.005-2.034,1.87,2.775,1.809.147,1.667-1.708,1.313,1.94,1.215.1-1.845-2.736,2-2.034,2.137,3.163.678-.678L19.02,15.375l2.005-2.034L23.156,16.5l.683-.678L21.7,12.663l2.005-2.034,2.137,3.163.677-.678L24.385,9.951l2-2.034,2.137,3.163L29.2,10.4l-2.131-3.17,2-2.034,2.137,3.17.678-.679L29.8,4.551l2.005-2.034,2.125,2.959Z"
          transform="translate(44.16 127.537)"
        />
        <path
          className={classes.c}
          d="M26.573,6.5l-2.137-3.17L26.441,1.3l2.136,3.17ZM23.89,9.213,21.753,6.05l2.005-2.034,2.136,3.163Zm-2.683,2.746L19.07,8.789l2-2.034,2.131,3.17Zm-2.688,2.712-2.131-3.163,2-2.034,2.137,3.17Zm-2.683,2.745L13.7,14.247,15.7,12.213l2.137,3.17Zm-2.682,2.712-2.137-3.17,2.005-2.034,2.137,3.17ZM21.223,5.2,19.087,2.039,21.092,0l2.137,3.163ZM18.536,7.914,16.4,4.744l2-2.034,2.137,3.17Zm-2.682,2.712L13.716,7.463l2-2.034L17.858,8.6ZM13.17,13.371,11.033,10.2l2-2.034,2.137,3.17Zm-2.682,2.712-2.136-3.17,2-2.034,2.137,3.17ZM7.8,18.8,5.668,15.633,7.673,13.6,9.8,16.762ZM33.934,5.476l.683-.679-2.136-3.17L33.353.74l-1.215-.1-.189.2L31.8.613l-1.215-.1.683,1.015L29.261,3.564,27.13.4l.156-.162L25.79.116,25.915.3,23.91,2.338l-1.7-2.516L20-.36,18.527,1.323,17.39-.358l-1.209-.1,1.667,2.477-2,2.034L13.707.886l-.678.678,2.137,3.17L13.161,6.767,11.025,3.6l-.678.678,2.137,3.169L10.478,9.479,8.342,6.31l-.683.678L9.8,10.158l-2,2.034L5.654,9.022,4.976,9.7l2.137,3.163-2,2.034L2.971,11.734l-.677.678,2.137,3.17-2,2.034L.289,14.446l-.678.679,2.137,3.163L.637,19.413l1.215.1.417-.427.328.485,1.215.1-.865-1.294,2-2.034,2.137,3.169-.383.387,1.895.157-.3-.446,2.005-2.034,1.87,2.775,1.809.147,1.667-1.708,1.313,1.94,1.215.1-1.845-2.736,2-2.034,2.137,3.163.678-.678L19.02,15.375l2.005-2.034L23.156,16.5l.683-.678L21.7,12.663l2.005-2.034,2.137,3.163.677-.678L24.385,9.951l2-2.034,2.137,3.163L29.2,10.4l-2.131-3.17,2-2.034,2.137,3.17.678-.679L29.8,4.551l2.005-2.034,2.125,2.959Z"
          transform="translate(44.16 127.537)"
        />
        <path
          className={classes.a}
          d="M5.2,5.951,3.072,2.781l2-2.034,2.137,3.17Zm7.362-.834.678-.679-2.137-3.17L11.439.93,9.63.79l.266.381-2,2.034L6.052.482,4.157.331,2.535,1.985,1.263.1.049,0,1.884,2.682-.121,4.717l.521.8,2-2.034L4.541,6.641,2.536,8.675l.089.136,1.009.084L5.073,7.424,6.2,9.1l1.215.1L5.756,6.726l2-2.034,2.131,3.17.683-.679L8.439,4.02l2-2.034Z"
          transform="translate(46.737 147.278)"
        />
        <path
          className={classes.c}
          d="M5.2,5.951,3.072,2.781l2-2.034,2.137,3.17Zm7.362-.834.678-.679-2.137-3.17L11.439.93,9.63.79l.266.381-2,2.034L6.052.482,4.157.331,2.535,1.985,1.263.1.049,0,1.884,2.682-.121,4.717l.521.8,2-2.034L4.541,6.641,2.536,8.675l.089.136,1.009.084L5.073,7.424,6.2,9.1l1.215.1L5.756,6.726l2-2.034,2.131,3.17.683-.679L8.439,4.02l2-2.034Z"
          transform="translate(46.737 147.278)"
        />
        <path
          className={classes.a}
          d="M2.1.073.508,1.693-.023.9.882-.025Z"
          transform="translate(43.943 147.152)"
        />
        <path
          className={classes.c}
          d="M2.1.073.508,1.693-.023.9.882-.025Z"
          transform="translate(43.943 147.152)"
        />
        <path
          className={classes.a}
          d="M.823,1.223,1.5.525,1.215.1,0,0Z"
          transform="translate(61.162 148.433)"
        />
        <path
          className={classes.c}
          d="M.823,1.223,1.5.525,1.215.1,0,0Z"
          transform="translate(61.162 148.433)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(52.558 138.624)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(52.558 138.624)"
        />
        <path
          className={classes.a}
          d="M2.08,5.155-.051,1.992l2-2.047-2,2.047Z"
          transform="translate(49.874 141.369)"
        />
        <path
          className={classes.c}
          d="M2.08,5.155-.051,1.992l2-2.047-2,2.047Z"
          transform="translate(49.874 141.369)"
        />
        <path
          className={classes.a}
          d="M2.086,5.161-.051,2,1.954-.055-.051,1.992Z"
          transform="translate(57.925 133.141)"
        />
        <path
          className={classes.c}
          d="M2.086,5.161-.051,2,1.954-.055-.051,1.992Z"
          transform="translate(57.925 133.141)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(55.241 135.886)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(55.241 135.886)"
        />
        <path
          className={classes.a}
          d="M.683,1.016,0,0Z"
          transform="translate(74.739 128.23)"
        />
        <path
          className={classes.c}
          d="M.683,1.016,0,0Z"
          transform="translate(74.739 128.23)"
        />
        <path
          className={classes.a}
          d="M.157,0,0,.158H0Z"
          transform="translate(71.283 127.972)"
        />
        <path
          className={classes.c}
          d="M.157,0,0,.158Z"
          transform="translate(71.283 127.972)"
        />
        <path
          className={classes.a}
          d="M0,0,.125.188Z"
          transform="translate(69.946 127.845)"
        />
        <path
          className={classes.c}
          d="M.125.188,0,0Z"
          transform="translate(69.946 127.845)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.046,1.991l2-2.047L4.091,3.114,1.954-.055-.051,1.992Z"
          transform="translate(60.608 130.402)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.046,1.991l2-2.047L4.091,3.114,1.954-.055-.051,1.992Z"
          transform="translate(60.608 130.402)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055-.051,1.992l.865,1.28Z"
          transform="translate(47.186 144.108)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Zm0,0,.865,1.28Z"
          transform="translate(47.186 144.108)"
        />
        <path
          className={classes.a}
          d="M0,0,1.272,1.889Z"
          transform="translate(48 147.381)"
        />
        <path
          className={classes.c}
          d="M0,0,1.272,1.889Z"
          transform="translate(48 147.381)"
        />
        <path
          className={classes.a}
          d="M2.08,5.161-.051,2l2-2.054-2,2.047Z"
          transform="translate(60.598 137.099)"
        />
        <path
          className={classes.c}
          d="M2.08,5.161-.051,2l2-2.054-2,2.047Z"
          transform="translate(60.598 137.099)"
        />
        <path
          className={classes.a}
          d="M1.954-.048,4.09,3.115,1.954-.055-.051,2Z"
          transform="translate(65.964 131.615)"
        />
        <path
          className={classes.c}
          d="M1.954-.048,4.09,3.115,1.954-.055-.051,2Z"
          transform="translate(65.964 131.615)"
        />
        <path
          className={classes.a}
          d="M2.08,5.161-.051,1.992,1.954-.055,4.085,3.114,1.954-.055-.051,1.992Z"
          transform="translate(63.281 134.361)"
        />
        <path
          className={classes.c}
          d="M2.08,5.161-.051,1.992,1.954-.055,4.085,3.114,1.954-.055-.051,1.992Z"
          transform="translate(63.281 134.361)"
        />
        <path
          className={classes.a}
          d="M1.955-.055l2.136,3.17L1.955-.055-.051,1.992Z"
          transform="translate(68.647 128.876)"
        />
        <path
          className={classes.c}
          d="M1.955-.055l2.136,3.17Zm0,0L-.051,1.992Z"
          transform="translate(68.647 128.876)"
        />
        <path
          className={classes.a}
          d="M1.954-.048,4.086,3.115,1.955-.055-.051,2Z"
          transform="translate(63.296 127.656)"
        />
        <path
          className={classes.c}
          d="M1.954-.048,4.086,3.115,1.955-.055-.051,2Z"
          transform="translate(63.296 127.656)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(55.226 142.583)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(55.226 142.583)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(57.909 139.844)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(57.909 139.844)"
        />
        <path
          className={classes.a}
          d="M1.954-.055-.051,1.992,2.085,5.161-.046,1.992Z"
          transform="translate(49.854 148.067)"
        />
        <path
          className={classes.c}
          d="M1.954-.055-.051,1.992,2.085,5.161-.046,1.992Z"
          transform="translate(49.854 148.067)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055-.051,1.992l.3.447Z"
          transform="translate(52.542 145.328)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055Zm0,0,.3.447Z"
          transform="translate(52.542 145.328)"
        />
        <path
          className={classes.a}
          d="M0,0,1.84,2.716.006,0Z"
          transform="translate(52.788 147.767)"
        />
        <path
          className={classes.c}
          d="M0,0,1.84,2.716.006,0Z"
          transform="translate(52.788 147.767)"
        />
        <path
          className={classes.a}
          d="M-.012.475.571.518l.27.026L.466-.013Z"
          transform="translate(74.971 122.562)"
        />
        <path
          className={classes.c}
          d="M.841.543.466-.013-.012.475.571.518Z"
          transform="translate(74.971 122.562)"
        />
        <path
          className={classes.a}
          d="M2.161,2.206.661-.019l-.678.7.964,1.43Z"
          transform="translate(65.283 120.225)"
        />
        <path
          className={classes.c}
          d="M2.161,2.206.661-.019l-.678.7.964,1.43Z"
          transform="translate(65.283 120.225)"
        />
        <path
          className={classes.a}
          d="M2.651,4.631l.407.6,1.209.1L3.329,3.94,5.334,1.893,4.8,1.1,2.8,3.15.661-.019-.017.673l2.137,3.17L.925,5.062l1.215.1Z"
          transform="translate(67.966 117.486)"
        />
        <path
          className={classes.c}
          d="M2.651,4.631l.407.6,1.209.1L3.329,3.94,5.334,1.893,4.8,1.1,2.8,3.15.661-.019-.017.673l2.137,3.17L.925,5.062l1.215.1Z"
          transform="translate(67.966 117.486)"
        />
        <path
          className={classes.a}
          d="M.666-.012.661-.019l-.678.7.464.692,1.215.1Z"
          transform="translate(59.911 125.709)"
        />
        <path
          className={classes.c}
          d="M.666-.012.661-.019l-.678.7.464.692,1.215.1Z"
          transform="translate(59.911 125.709)"
        />
        <path
          className={classes.a}
          d="M1.55,4.946l2.208.182L3.32,4.474,5.325,2.427,7.337,5.409l1.5.123,1.845-1.885,1.454,2.147,1.215.1L11.36,2.949,13.365.9,13.209.669H13.2l-.27-.026L12.35.6,10.823,2.16,9.625.381,8.415.283l1.73,2.568L8.14,4.9,6,1.736,7.492.21,6.283.113,5.472.939,4.836,0,3.622-.1l1.172,1.74L2.788,3.685.652.515l-.683.691.005.007L2.105,4.377Z"
          transform="translate(62.608 122.436)"
        />
        <path
          className={classes.c}
          d="M1.55,4.946l2.208.182L3.32,4.474,5.325,2.427,7.337,5.409l1.5.123,1.845-1.885,1.454,2.147,1.215.1L11.36,2.949,13.365.9,13.209.669H13.2l-.27-.026L12.35.6,10.823,2.16,9.625.381,8.415.283l1.73,2.568L8.14,4.9,6,1.736,7.492.21,6.283.113,5.472.939,4.836,0,3.622-.1l1.172,1.74L2.788,3.685.652.515l-.683.691.005.007L2.105,4.377Z"
          transform="translate(62.608 122.436)"
        />
        <path
          className={classes.a}
          d="M-.047,1.841,1.8-.044,3.251,2.1,1.8-.051Z"
          transform="translate(71.488 126.127)"
        />
        <path
          className={classes.c}
          d="M-.047,1.841,1.8-.044,3.251,2.1,1.8-.051Z"
          transform="translate(71.488 126.127)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.955-.055,3.966,2.927,1.955-.055Z"
          transform="translate(65.979 124.918)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055,3.966,2.927,1.955-.055Z"
          transform="translate(65.979 124.918)"
        />
        <path
          className={classes.a}
          d="M1.451-.041-.038,1.484,1.456-.041Z"
          transform="translate(68.65 122.687)"
        />
        <path
          className={classes.c}
          d="M1.451-.041-.038,1.484,1.456-.041Z"
          transform="translate(68.65 122.687)"
        />
        <path
          className={classes.a}
          d="M1.73,2.568,0,0Z"
          transform="translate(71.024 122.719)"
        />
        <path
          className={classes.c}
          d="M1.73,2.568,0,0Z"
          transform="translate(71.024 122.719)"
        />
        <path
          className={classes.a}
          d="M.27.026,0,0Z"
          transform="translate(75.542 123.08)"
        />
        <path
          className={classes.c}
          d="M.27.026,0,0Z"
          transform="translate(75.542 123.08)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(66.23 122.334)"
        />
        <path
          className={classes.c}
          d="M1.215.1,0,0Z"
          transform="translate(66.23 122.334)"
        />
        <path
          className={classes.a}
          d="M.005,0,1.215.1,0,0Z"
          transform="translate(71.018 122.719)"
        />
        <path
          className={classes.c}
          d="M0,0,1.209.1Z"
          transform="translate(71.024 122.719)"
        />
        <path
          className={classes.a}
          d="M1.168,1.9,2.3.739,1.77-.05-.046,1.8Z"
          transform="translate(76.34 126.557)"
        />
        <path
          className={classes.c}
          d="M1.168,1.9,2.3.739,1.77-.05-.046,1.8Z"
          transform="translate(76.34 126.557)"
        />
        <path
          className={classes.a}
          d="M37.061,14.573,9.806,42.406a.95.95,0,0,1-.747.307.9.9,0,0,1-.678-.418L-.509,29.1A1.422,1.422,0,0,1-.6,27.748a1.321,1.321,0,0,1,.258-.381L26.931-.473A.891.891,0,0,1,28.056-.65a.988.988,0,0,1,.3.3l8.89,13.183a1.353,1.353,0,0,1,.212.9,1.419,1.419,0,0,1-.381.849"
          transform="translate(28.515 95.341)"
        />
        <path
          className={classes.c}
          d="M37.061,14.573,9.806,42.406a.95.95,0,0,1-.747.307.9.9,0,0,1-.678-.418L-.509,29.1A1.422,1.422,0,0,1-.6,27.748a1.321,1.321,0,0,1,.258-.381L26.931-.473A.891.891,0,0,1,28.056-.65a.988.988,0,0,1,.3.3l8.89,13.183a1.449,1.449,0,0,1,.077,1.365,1.317,1.317,0,0,1-.262.38Z"
          transform="translate(28.515 95.341)"
        />
        <path
          className={classes.a}
          d="M-.017.679,1.39,2.762l1.215.1L.666-.019Z"
          transform="translate(48.191 101.605)"
        />
        <path
          className={classes.c}
          d="M-.017.679,1.39,2.762l1.215.1L.666-.019Z"
          transform="translate(48.191 101.605)"
        />
        <path
          className={classes.a}
          d="M.291.433.664.053,0,0Z"
          transform="translate(60.712 114.151)"
        />
        <path
          className={classes.c}
          d="M.291.433.664.053,0,0Z"
          transform="translate(60.712 114.151)"
        />
        <path
          className={classes.a}
          d="M-.008.323.57.366.314-.009-.008.323Z"
          transform="translate(63.387 105.151)"
        />
        <path
          className={classes.c}
          d="M.314-.009-.008.323.57.366Z"
          transform="translate(63.387 105.151)"
        />
        <path
          className={classes.a}
          d="M1.5,8.4H1.51L2.582,7.3l.839,1.255h.005V8.552l1.209.1L3.26,6.612,5.265,4.565,7.4,7.727,6.363,8.792l1.215.1h0l.356-.366.282.414,1.215.1-.813-1.21,2.005-2.047-.537-.789L8.079,7.036,5.943,3.866,7.949,1.82l-.532-.789L5.412,3.077,3.275-.092,2.6.606,4.734,3.769,2.729,5.816.592,2.653l-.678.691,2.131,3.17L.295,8.3,1.5,8.4Z"
          transform="translate(50.948 96.195)"
        />
        <path
          className={classes.c}
          d="M1.5,8.4H1.51L2.582,7.3l.839,1.255h.005V8.552l1.209.1L3.26,6.612,5.265,4.565,7.4,7.727,6.363,8.792l1.215.1h0l.356-.366.282.414,1.215.1-.813-1.21,2.005-2.047-.537-.789L8.079,7.036,5.943,3.866,7.949,1.82l-.532-.789L5.412,3.077,3.275-.092,2.6.606,4.734,3.769,2.729,5.816.592,2.653l-.678.691,2.131,3.17L.295,8.3,1.5,8.4Z"
          transform="translate(50.948 96.195)"
        />
        <path
          className={classes.a}
          d="M7.978,6.973,5.842,3.8l2-2.033L9.983,4.939Zm7.356-.827.683-.679L13.881,2.3l1.227-1.234-1.215-.1-.556.557L12.906.875,11.7.777l.958,1.43-2,2.034L8.52,1.072,9.025.549,6.9.379l.4.6L5.3,3.008,3.335.077,1.754-.049l-1.8,1.837.52.8,2-2.034L4.611,3.713l-2,2.034.52.8,2-2.034L7.273,7.672l-2,2.034.52.8L7.794,8.468,9.93,11.631l.678-.678L8.471,7.783l2.006-2.034,2.136,3.169L13.3,8.24,11.159,5.07l2-2.034,2.171,3.109Z"
          transform="translate(29.558 124.89)"
        />
        <path
          className={classes.c}
          d="M7.978,6.973,5.842,3.8l2-2.033L9.983,4.939Zm7.356-.827.683-.679L13.881,2.3l1.227-1.234-1.215-.1-.556.557L12.906.875,11.7.777l.958,1.43-2,2.034L8.52,1.072,9.025.549,6.9.379l.4.6L5.3,3.008,3.335.077,1.754-.049l-1.8,1.837.52.8,2-2.034L4.611,3.713l-2,2.034.52.8,2-2.034L7.273,7.672l-2,2.034.52.8L7.794,8.468,9.93,11.631l.678-.678L8.471,7.783l2.006-2.034,2.136,3.169L13.3,8.24,11.159,5.07l2-2.034,2.171,3.109Z"
          transform="translate(29.558 124.89)"
        />
        <path
          className={classes.a}
          d="M0,0,1.527,2.257,2.2,1.566,1.215.1Z"
          transform="translate(46.055 126.033)"
        />
        <path
          className={classes.c}
          d="M0,0,1.527,2.257,2.2,1.566,1.215.1Z"
          transform="translate(46.055 126.033)"
        />
        <path
          className={classes.a}
          d="M.66-.019-.017.673.67,1.7l1.209.1Z"
          transform="translate(32.087 118.056)"
        />
        <path
          className={classes.c}
          d="M.66-.019-.017.673.67,1.7l1.209.1Z"
          transform="translate(32.087 118.056)"
        />
        <path
          className={classes.a}
          d="M13.43,8.3,11.293,5.132,13.3,3.1l2.136,3.163ZM8.051,7.083,5.915,3.913l2-2.034,2.131,3.17Zm15.4-2.359.678-.678-1.86-2.782-1.825-.146L18.775,2.812,17.472.871l-1.215-.1L18.1,3.5l-2,2.034-2.137-3.17L15.583.707,14.368.61l-.945.97-.74-1.1-1.215-.1L12.746,2.27l-2,2.034L8.6,1.141,9.516.206,8.3.109,8.073.346,7.89.081l-1.215-.1L7.39,1.044l-2,2.034L3.253-.092,2.571.586,4.707,3.75,2.7,5.784.59,2.7l-.677.678,2.136,3.17L1.206,7.4l1.215.1.161-.163.125.188,1.215.1-.656-.977,2-2.034L7.4,7.773,7.278,7.9l1.436.117-.1-.149,2.006-2.034,1.673,2.484,2.267.18,1.411-1.444L17.082,8.7l1.215.1L16.655,6.359l2-2.034,2.131,3.169.683-.678L19.337,3.653l2-2.033,2.106,3.1Z"
          transform="translate(34.856 112.6)"
        />
        <path
          className={classes.c}
          d="M13.43,8.3,11.293,5.132,13.3,3.1l2.136,3.163ZM8.051,7.083,5.915,3.913l2-2.034,2.131,3.17Zm15.4-2.359.678-.678-1.86-2.782-1.825-.146L18.775,2.812,17.472.871l-1.215-.1L18.1,3.5l-2,2.034-2.137-3.17L15.583.707,14.368.61l-.945.97-.74-1.1-1.215-.1L12.746,2.27l-2,2.034L8.6,1.141,9.516.206,8.3.109,8.073.346,7.89.081l-1.215-.1L7.39,1.044l-2,2.034L3.253-.092,2.571.586,4.707,3.75,2.7,5.784.59,2.7l-.677.678,2.136,3.17L1.206,7.4l1.215.1.161-.163.125.188,1.215.1-.656-.977,2-2.034L7.4,7.773,7.278,7.9l1.436.117-.1-.149,2.006-2.034,1.673,2.484,2.267.18,1.411-1.444L17.082,8.7l1.215.1L16.655,6.359l2-2.034,2.131,3.169.683-.678L19.337,3.653l2-2.033,2.106,3.1Z"
          transform="translate(34.856 112.6)"
        />
        <path
          className={classes.a}
          d="M18.706,2.537,16.7,4.584l1.147,1.7-1.215-.1-.61-.905-.778.793-1.215-.1,1.455-1.492L13.355,1.324,11.349,3.371l1.7,2.529-1.209-.1-1.178-1.74L9.172,5.588,7.05,5.417,5.315,2.842,3.309,4.9l.162.232L1.89,5l.205-.21L-.042,1.629.642.93,2.778,4.1l2-2.047L3.329-.094,4.544,0l.917,1.359L6.623.169l1.215.1L5.993,2.15,8.129,5.32l2.005-2.047L8.123.291l1.215.1,1.474,2.186L12.7.657l1.436.117L16.169,3.8l2-2.047-.458-.685,2.267.179-.594.6L21.52,5.009l-.678.7Z"
          transform="translate(29.422 119.845)"
        />
        <path
          className={classes.c}
          d="M18.706,2.537,16.7,4.584l1.147,1.7-1.215-.1-.61-.905-.778.793-1.215-.1,1.455-1.492L13.355,1.324,11.349,3.371l1.7,2.529-1.209-.1-1.178-1.74L9.172,5.588,7.05,5.417,5.315,2.842,3.309,4.9l.162.232L1.89,5l.205-.21L-.042,1.629.642.93,2.778,4.1l2-2.047L3.329-.094,4.544,0l.917,1.359L6.623.169l1.215.1L5.993,2.15,8.129,5.32l2.005-2.047L8.123.291l1.215.1,1.474,2.186L12.7.657l1.436.117L16.169,3.8l2-2.047-.458-.685,2.267.179-.594.6L21.52,5.009l-.678.7Z"
          transform="translate(29.422 119.845)"
        />
        <path
          className={classes.a}
          d="M1.7.828l-.677.691L0,0,1.215.1,1.7.828Z"
          transform="translate(51.921 121.287)"
        />
        <path
          className={classes.c}
          d="M1.7.828l-.677.691L0,0,1.215.1,1.7.828Z"
          transform="translate(51.921 121.287)"
        />
        <path
          className={classes.a}
          d="M0,0V.006L2.136,3.169,2.014,3.3l.122-.129L0,0Z"
          transform="translate(40.104 117.204)"
        />
        <path
          className={classes.c}
          d="M0,0V.006L2.136,3.169,2.014,3.3l.122-.129L0,0Z"
          transform="translate(40.104 117.204)"
        />
        <path
          className={classes.a}
          d="M-.048,1.867,1.835-.052Z"
          transform="translate(40.283 120.554)"
        />
        <path
          className={classes.c}
          d="M-.048,1.867,1.835-.052Z"
          transform="translate(40.283 120.554)"
        />
        <path
          className={classes.a}
          d="M1.938.552-.067,2.6,1.938.552l-.4-.6Z"
          transform="translate(34.935 125.306)"
        />
        <path
          className={classes.c}
          d="M1.938.552-.067,2.6Zm0,0-.4-.6Z"
          transform="translate(34.935 125.306)"
        />
        <path
          className={classes.a}
          d="M1.735,2.575,0,0V.006Z"
          transform="translate(34.737 122.688)"
        />
        <path
          className={classes.c}
          d="M1.735,2.575,0,0V.006Z"
          transform="translate(34.737 122.688)"
        />
        <path
          className={classes.a}
          d="M.222-.006-.006.224Z"
          transform="translate(42.944 112.754)"
        />
        <path
          className={classes.c}
          d="M-.006.231.222-.006Z"
          transform="translate(42.945 112.747)"
        />
        <path
          className={classes.a}
          d="M-.024.944.92-.026Z"
          transform="translate(48.314 113.255)"
        />
        <path
          className={classes.c}
          d="M-.024.944.92-.026Z"
          transform="translate(48.314 113.255)"
        />
        <path
          className={classes.a}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(53.689 113.763)"
        />
        <path
          className={classes.c}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(53.689 113.763)"
        />
        <path
          className={classes.a}
          d="M1.9,1.871-.1,3.918,1.9,1.871.627-.018Z"
          transform="translate(45.707 113.019)"
        />
        <path
          className={classes.c}
          d="M1.9,1.871-.1,3.918Zm0,0L.627-.018Z"
          transform="translate(45.707 113.019)"
        />
        <path
          className={classes.a}
          d="M1.883,2.722-.123,4.769,1.883,2.722.049,0H.043Z"
          transform="translate(51.08 113.388)"
        />
        <path
          className={classes.c}
          d="M1.883,2.722-.123,4.769Zm0,0L.049,0H.043Z"
          transform="translate(51.08 113.388)"
        />
        <path
          className={classes.a}
          d="M.131,5.216,2.137,3.169,0,0,2.131,3.169Z"
          transform="translate(42.792 114.466)"
        />
        <path
          className={classes.c}
          d="M.131,5.216,2.137,3.169,0,0,2.131,3.169Z"
          transform="translate(42.792 114.466)"
        />
        <path
          className={classes.a}
          d="M.126.188,0,0Z"
          transform="translate(37.421 119.949)"
        />
        <path
          className={classes.c}
          d="M0,0,.126.188Z"
          transform="translate(37.421 119.949)"
        />
        <path
          className={classes.a}
          d="M2.011,2.982.006,5.029,2.011,2.982,0,0Z"
          transform="translate(37.546 120.137)"
        />
        <path
          className={classes.c}
          d="M2.011,2.982.006,5.029Zm0,0L0,0Z"
          transform="translate(37.546 120.137)"
        />
        <path
          className={classes.a}
          d="M0,.006,2.136,3.17.131,5.216,2.136,3.17,0,0Z"
          transform="translate(37.405 126.647)"
        />
        <path
          className={classes.c}
          d="M0,.006,2.136,3.17.131,5.216,2.136,3.17,0,0Z"
          transform="translate(37.405 126.647)"
        />
        <path
          className={classes.a}
          d="M0,0,1.673,2.477Z"
          transform="translate(45.46 118.424)"
        />
        <path
          className={classes.c}
          d="M0,0,1.673,2.477Z"
          transform="translate(45.46 118.424)"
        />
        <path
          className={classes.a}
          d="M1.936.651-.069,2.7,1.936.651,1.473-.042Z"
          transform="translate(45.66 120.943)"
        />
        <path
          className={classes.c}
          d="M1.936.651-.069,2.7Zm0,0L1.473-.042Z"
          transform="translate(45.66 120.943)"
        />
        <path
          className={classes.a}
          d="M.131,5.21,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(48.143 115.686)"
        />
        <path
          className={classes.c}
          d="M.131,5.21,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(48.143 115.686)"
        />
        <path
          className={classes.a}
          d="M1.917,1.4-.089,3.45,1.917,1.4.958-.027Z"
          transform="translate(40.308 125.675)"
        />
        <path
          className={classes.c}
          d="M1.917,1.4-.089,3.45Zm0,0L.958-.027Z"
          transform="translate(40.308 125.675)"
        />
        <path
          className={classes.a}
          d="M1.178,1.74,0,0,1.178,1.74Z"
          transform="translate(40.088 123.908)"
        />
        <path
          className={classes.c}
          d="M1.178,1.74,0,0,1.178,1.74Z"
          transform="translate(40.088 123.908)"
        />
        <path
          className={classes.a}
          d="M.531-.015-.014.541Z"
          transform="translate(42.922 125.839)"
        />
        <path
          className={classes.c}
          d="M-.014.541.531-.015Z"
          transform="translate(42.922 125.839)"
        />
        <path
          className={classes.a}
          d="M.676,4.655,2.131,3.163,0,0,2.131,3.163Z"
          transform="translate(42.777 121.169)"
        />
        <path
          className={classes.c}
          d="M.676,4.655,2.131,3.163,0,0,2.131,3.163Z"
          transform="translate(42.777 121.169)"
        />
        <path
          className={classes.a}
          d="M.666-.019-.017.673,1.405,2.782l1.215.1Z"
          transform="translate(40.136 109.834)"
        />
        <path
          className={classes.c}
          d="M.666-.019-.017.673,1.405,2.782l1.215.1Z"
          transform="translate(40.136 109.834)"
        />
        <path
          className={classes.a}
          d="M13.426,8.223,11.29,5.054,13.3,3.02l2.136,3.17ZM8.048,7,5.912,3.841l2-2.034L10.053,4.97Zm12.714.387.683-.679-2.137-3.17,2.005-2.034-.281-.414-.578-.043L18.787,2.767,17.468.826l-1.215-.1,1.855,2.749-2,2.034L13.967,2.348,15.633.667l-1.215-.1-.967.983L12.69.427l-1.209-.1L12.773,2.27,10.763,4.3,8.632,1.135,9.56.185,8.35.087,8.095.345,7.9.054l-1.215-.1.73,1.08-2,2.034L3.275-.092,2.6.586l2.137,3.17L2.729,5.79.593,2.62-.085,3.3,2.046,6.461.274,8.271l1.215.1L2.6,7.257l.859,1.294,1.215.1-1.4-2.07,2-2.034,2.137,3.17L6.358,8.792l1.215.1L7.95,8.5l.3.44,1.215.1L8.633,7.812l2-2.034L12.77,8.94l-.339.352,1.825.147-.271-.4,2-2.034,1.845,2.736.664.053.306-.318L16.672,6.313l2-2.034,2.09,3.112Z"
          transform="translate(42.903 104.45)"
        />
        <path
          className={classes.c}
          d="M13.426,8.223,11.29,5.054,13.3,3.02l2.136,3.17ZM8.048,7,5.912,3.841l2-2.034L10.053,4.97Zm12.714.387.683-.679-2.137-3.17,2.005-2.034-.281-.414-.578-.043L18.787,2.767,17.468.826l-1.215-.1,1.855,2.749-2,2.034L13.967,2.348,15.633.667l-1.215-.1-.967.983L12.69.427l-1.209-.1L12.773,2.27,10.763,4.3,8.632,1.135,9.56.185,8.35.087,8.095.345,7.9.054l-1.215-.1.73,1.08-2,2.034L3.275-.092,2.6.586l2.137,3.17L2.729,5.79.593,2.62-.085,3.3,2.046,6.461.274,8.271l1.215.1L2.6,7.257l.859,1.294,1.215.1-1.4-2.07,2-2.034,2.137,3.17L6.358,8.792l1.215.1L7.95,8.5l.3.44,1.215.1L8.633,7.812l2-2.034L12.77,8.94l-.339.352,1.825.147-.271-.4,2-2.034,1.845,2.736.664.053.306-.318L16.672,6.313l2-2.034,2.09,3.112Z"
          transform="translate(42.903 104.45)"
        />
        <path
          className={classes.a}
          d="M-.169,6.59l.005.006ZM2.369,1.923,4.374-.123Z"
          transform="translate(51.293 106.791)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.955-.055Z"
          transform="translate(53.713 106.722)"
        />
        <path
          className={classes.a}
          d="M.244-.007l-.25.257L.249-.007Z"
          transform="translate(50.995 104.505)"
        />
        <path
          className={classes.c}
          d="M.249-.007H.244l-.25.257L.249-.007Z"
          transform="translate(50.995 104.505)"
        />
        <path
          className={classes.a}
          d="M.86,1.268,0,0Z"
          transform="translate(45.475 111.727)"
        />
        <path
          className={classes.c}
          d="M.86,1.268,0,0Z"
          transform="translate(45.475 111.727)"
        />
        <path
          className={classes.a}
          d="M.76,1.125.005,0H0L.76,1.125,1.727.142Z"
          transform="translate(55.584 104.844)"
        />
        <path
          className={classes.c}
          d="M.76,1.125.005,0H0Zm0,0L1.727.142Z"
          transform="translate(55.584 104.844)"
        />
        <path
          className={classes.a}
          d="M.006,0H0L2.137,3.163,4.143,1.115,2.137,3.163Z"
          transform="translate(56.876 106.765)"
        />
        <path
          className={classes.c}
          d="M.006,0H0L2.137,3.163,4.143,1.115,2.137,3.163Z"
          transform="translate(56.876 106.765)"
        />
        <path
          className={classes.a}
          d="M1.318,1.96,3,.245,1.318,1.96,0,0Z"
          transform="translate(60.378 105.229)"
        />
        <path
          className={classes.c}
          d="M1.318,1.96,3,.245Zm0,0L0,0Z"
          transform="translate(60.378 105.229)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(56.381 110.681)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(56.381 110.681)"
        />
        <path
          className={classes.a}
          d="M.132,5.21,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(50.842 106.243)"
        />
        <path
          className={classes.c}
          d="M.132,5.21,2.137,3.163,0,0,2.137,3.163Z"
          transform="translate(50.842 106.243)"
        />
        <path
          className={classes.a}
          d="M0,0,2.131,3.164l-.339.352.339-.352Z"
          transform="translate(53.516 110.201)"
        />
        <path
          className={classes.c}
          d="M0,0,2.131,3.164l-.339.352.339-.352Z"
          transform="translate(53.516 110.201)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.17,1.076,4.248h.006L2.137,3.17,0,0Z"
          transform="translate(48.158 108.981)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.17,1.076,4.248h.006L2.137,3.17,0,0Z"
          transform="translate(48.158 108.981)"
        />
        <path
          className={classes.a}
          d="M0,0,.3.44.005,0Z"
          transform="translate(50.827 112.94)"
        />
        <path
          className={classes.c}
          d="M.3.44,0,0Z"
          transform="translate(50.827 112.94)"
        />
        <path
          className={classes.a}
          d="M0,0,.578.043Z"
          transform="translate(63.379 105.475)"
        />
        <path
          className={classes.c}
          d="M.578.05,0,0V.007Z"
          transform="translate(63.379 105.468)"
        />
        <path
          className={classes.a}
          d="M0,.006,1.209.1,0,0Z"
          transform="translate(54.375 104.746)"
        />
        <path
          className={classes.c}
          d="M0,0V.006L1.209.1Z"
          transform="translate(54.375 104.746)"
        />
        <path
          className={classes.a}
          d="M0,0,1.209.1h.005Z"
          transform="translate(57.311 104.986)"
        />
        <path
          className={classes.c}
          d="M0,0,1.209.1h.005Z"
          transform="translate(57.311 104.986)"
        />
        <path
          className={classes.a}
          d="M0,0,1.209.1Z"
          transform="translate(51.243 104.498)"
        />
        <path
          className={classes.c}
          d="M1.209.1,0,0Z"
          transform="translate(51.243 104.498)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(59.163 105.132)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(59.163 105.132)"
        />
        <path
          className={classes.a}
          d="M.651-.018-.017.665l24.547,36.4.669-.682L.651-.018Z"
          transform="translate(50.877 98.868)"
        />
        <path
          className={classes.c}
          d="M.651-.018-.017.665l24.547,36.4.669-.682L.651-.018Z"
          transform="translate(50.877 98.868)"
        />
        <path
          className={classes.a}
          d="M.651-.018-.017.665l24.547,36.4.669-.683Z"
          transform="translate(29.405 120.804)"
        />
        <path
          className={classes.c}
          d="M.651-.018-.017.665l24.547,36.4.669-.683Z"
          transform="translate(29.405 120.804)"
        />
        <path
          className={classes.a}
          d="M37.057,14.577,9.8,42.409a.883.883,0,0,1-1.125.178,1,1,0,0,1-.3-.3L-.512,29.109a1.362,1.362,0,0,1-.209-.9,1.4,1.4,0,0,1,.383-.848L26.928-.469a.935.935,0,0,1,.745-.306.9.9,0,0,1,.675.418l8.9,13.182a1.451,1.451,0,0,1-.175,1.745"
          transform="translate(11.455 148.84)"
        />
        <path
          className={classes.c}
          d="M37.057,14.577,9.8,42.409a.883.883,0,0,1-1.125.178,1,1,0,0,1-.3-.3L-.512,29.109a1.362,1.362,0,0,1-.209-.9,1.4,1.4,0,0,1,.383-.848L26.928-.469a.935.935,0,0,1,.745-.306.9.9,0,0,1,.675.418l8.9,13.182a1.443,1.443,0,0,1,.077,1.37,1.321,1.321,0,0,1-.263.382Z"
          transform="translate(11.455 148.84)"
        />
        <path
          className={classes.a}
          d="M0,0,1.005,1.494,1.684.8,1.215.1Z"
          transform="translate(21.459 188.521)"
        />
        <path
          className={classes.c}
          d="M0,0,1.005,1.494,1.684.8,1.215.1Z"
          transform="translate(21.459 188.521)"
        />
        <path
          className={classes.a}
          d="M.448.653,1.009.077,0,0Z"
          transform="translate(17.88 188.24)"
        />
        <path
          className={classes.c}
          d="M.448.653,1.009.077,0,0Z"
          transform="translate(17.88 188.24)"
        />
        <path
          className={classes.a}
          d="M26.557,6.489,24.42,3.326l2-2.034,2.137,3.163ZM23.874,9.2,21.738,6.032l2-2.034,2.137,3.169Zm-2.683,2.713L19.053,8.823l2-2.034L23.19,9.952Zm-2.679,2.8-2.142-3.182,2-2.034,2.136,3.17ZM15.83,17.43l-2.137-3.17,2-2.034,2.137,3.17Zm-2.695,2.765L11,17.026l2-2.034,2.137,3.169Zm8.07-14.926L19.069,2.1l2-2.034,2.131,3.17ZM18.517,7.981,16.386,4.818l2-2.034,2.137,3.163Zm-2.683,2.746L13.7,7.557,15.7,5.523l2.137,3.17Zm-2.683,2.712-2.137-3.17L13.02,8.235l2.137,3.17Zm-2.683,2.745L8.33,13.08l2.006-2.034,2.131,3.17ZM7.781,18.9l-2.133-3.1,2-2.034,2.137,3.17ZM33.892,5.662l.683-.678L32.438,1.821,33.31.926,32.1.829l-.189.2L31.75.806,30.54.708l.683,1.015L29.217,3.757,27.087.587l.155-.162-1.5-.116.125.181-2,2.034L22.168.008,19.976-.161l-1.45,1.477L17.4-.359l-1.215-.1,1.673,2.477-2,2.034L13.712.886l-.678.679,2.137,3.17L13.166,6.768,11.03,3.6l-.683.678,2.136,3.17-2,2.034L8.347,6.31l-.683.679L9.8,10.151l-2,2.034L5.659,9.016l-.678.678,2.137,3.17-2,2.034-2.137-3.17-.678.679,2.137,3.163-2,2.034L.29,14.625l-.683.679,2.137,3.17L.633,19.592l1.215.1.417-.427.328.485,1.215.1-.865-1.294,2-2.034L7.084,19.68l-.383.393,1.895.151-.3-.446,2-2.034,1.871,2.782,1.809.147,1.667-1.709,1.313,1.94,1.215.1-1.845-2.743,2-2.034,2.137,3.17.677-.678-2.114-3.209,2-2.034,2.137,3.143.683-.679-2.137-3.163,2.006-2.034L25.857,13.9l.683-.678L24.4,10.063l2-2.034,2.137,3.163.677-.678L27.088,7.278l2-2.034L31.23,8.407l.678-.679L29.776,4.565l2.005-2.033,2.11,3.13Z"
          transform="translate(12.711 159.68)"
        />
        <path
          className={classes.c}
          d="M26.557,6.489,24.42,3.326l2-2.034,2.137,3.163ZM23.874,9.2,21.738,6.032l2-2.034,2.137,3.169Zm-2.683,2.713L19.053,8.823l2-2.034L23.19,9.952Zm-2.679,2.8-2.142-3.182,2-2.034,2.136,3.17ZM15.83,17.43l-2.137-3.17,2-2.034,2.137,3.17Zm-2.695,2.765L11,17.026l2-2.034,2.137,3.169Zm8.07-14.926L19.069,2.1l2-2.034,2.131,3.17ZM18.517,7.981,16.386,4.818l2-2.034,2.137,3.163Zm-2.683,2.746L13.7,7.557,15.7,5.523l2.137,3.17Zm-2.683,2.712-2.137-3.17L13.02,8.235l2.137,3.17Zm-2.683,2.745L8.33,13.08l2.006-2.034,2.131,3.17ZM7.781,18.9l-2.133-3.1,2-2.034,2.137,3.17ZM33.892,5.662l.683-.678L32.438,1.821,33.31.926,32.1.829l-.189.2L31.75.806,30.54.708l.683,1.015L29.217,3.757,27.087.587l.155-.162-1.5-.116.125.181-2,2.034L22.168.008,19.976-.161l-1.45,1.477L17.4-.359l-1.215-.1,1.673,2.477-2,2.034L13.712.886l-.678.679,2.137,3.17L13.166,6.768,11.03,3.6l-.683.678,2.136,3.17-2,2.034L8.347,6.31l-.683.679L9.8,10.151l-2,2.034L5.659,9.016l-.678.678,2.137,3.17-2,2.034-2.137-3.17-.678.679,2.137,3.163-2,2.034L.29,14.625l-.683.679,2.137,3.17L.633,19.592l1.215.1.417-.427.328.485,1.215.1-.865-1.294,2-2.034L7.084,19.68l-.383.393,1.895.151-.3-.446,2-2.034,1.871,2.782,1.809.147,1.667-1.709,1.313,1.94,1.215.1-1.845-2.743,2-2.034,2.137,3.17.677-.678-2.114-3.209,2-2.034,2.137,3.143.683-.679-2.137-3.163,2.006-2.034L25.857,13.9l.683-.678L24.4,10.063l2-2.034,2.137,3.163.677-.678L27.088,7.278l2-2.034L31.23,8.407l.678-.679L29.776,4.565l2.005-2.033,2.11,3.13Z"
          transform="translate(12.711 159.68)"
        />
        <path
          className={classes.a}
          d="M5.23,5.923,3.1,2.76,5.1.726,7.236,3.889ZM12.587,5.1l.683-.679L11.133,1.254l.333-.339L9.658.769l.261.388-2,2.034L6.1.461,4.206.309,2.562,1.984,1.291.095.076,0,1.885,2.662-.121,4.7.4,5.485l2-2.033L4.542,6.621,2.536,8.655l.089.129,1.009.084L5.074,7.4,6.2,9.073l1.215.1L5.757,6.705l2-2.033L9.893,7.835l.683-.679L8.439,3.987l2-2.034L12.587,5.1Z"
          transform="translate(15.244 179.436)"
        />
        <path
          className={classes.c}
          d="M5.23,5.923,3.1,2.76,5.1.726,7.236,3.889ZM12.587,5.1l.683-.679L11.133,1.254l.333-.339L9.658.769l.261.388-2,2.034L6.1.461,4.206.309,2.562,1.984,1.291.095.076,0,1.885,2.662-.121,4.7.4,5.485l2-2.033L4.542,6.621,2.536,8.655l.089.129,1.009.084L5.074,7.4,6.2,9.073l1.215.1L5.757,6.705l2-2.033L9.893,7.835l.683-.679L8.439,3.987l2-2.034L12.587,5.1Z"
          transform="translate(15.244 179.436)"
        />
        <path
          className={classes.a}
          d="M2.1.073.508,1.693-.023.9.883-.025Z"
          transform="translate(12.478 179.282)"
        />
        <path
          className={classes.c}
          d="M2.1.073.508,1.693-.023.9.883-.025Z"
          transform="translate(12.478 179.282)"
        />
        <path
          className={classes.a}
          d="M.824,1.216,1.5.524,1.215.1,0,0Z"
          transform="translate(29.696 180.571)"
        />
        <path
          className={classes.c}
          d="M.824,1.216,1.5.524,1.215.1,0,0Z"
          transform="translate(29.696 180.571)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(21.093 170.761)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(21.093 170.761)"
        />
        <path
          className={classes.a}
          d="M2.08,5.162-.051,1.992l2-2.047-2,2.047Z"
          transform="translate(18.409 173.499)"
        />
        <path
          className={classes.c}
          d="M2.08,5.162-.051,1.992l2-2.047-2,2.047Z"
          transform="translate(18.409 173.499)"
        />
        <path
          className={classes.a}
          d="M2.086,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(26.459 165.277)"
        />
        <path
          className={classes.c}
          d="M2.086,5.161-.051,1.992,1.955-.055-.051,1.992Z"
          transform="translate(26.459 165.277)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.049V-.055L-.051,1.992Z"
          transform="translate(23.776 168.016)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.049V-.055L-.051,1.992Z"
          transform="translate(23.776 168.016)"
        />
        <path
          className={classes.a}
          d="M.683,1.015,0,0Z"
          transform="translate(43.274 160.367)"
        />
        <path
          className={classes.c}
          d="M.683,1.015,0,0Z"
          transform="translate(43.274 160.367)"
        />
        <path
          className={classes.a}
          d="M.157,0,0,.158H0Z"
          transform="translate(39.819 160.102)"
        />
        <path
          className={classes.c}
          d="M.157,0,0,.158H0Z"
          transform="translate(39.819 160.102)"
        />
        <path
          className={classes.a}
          d="M.126.182,0,0Z"
          transform="translate(38.496 159.981)"
        />
        <path
          className={classes.c}
          d="M.126.182,0,0Z"
          transform="translate(38.48 159.981)"
        />
        <path
          className={classes.a}
          d="M2.085,5.155-.046,1.992l2-2.047L4.091,3.108,1.954-.055l-2,2.04Z"
          transform="translate(29.143 162.539)"
        />
        <path
          className={classes.c}
          d="M2.085,5.155-.046,1.992l2-2.047L4.091,3.108,1.954-.055l-2,2.04Z"
          transform="translate(29.143 162.539)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055-.051,1.992.814,3.273Z"
          transform="translate(15.721 176.245)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Zm0,0L.814,3.273Z"
          transform="translate(15.721 176.245)"
        />
        <path
          className={classes.a}
          d="M0,0,1.271,1.889V1.882Z"
          transform="translate(16.535 179.518)"
        />
        <path
          className={classes.c}
          d="M0,0,1.271,1.889V1.882Z"
          transform="translate(16.535 179.518)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.045,1.992l2-2.047L-.051,1.992Z"
          transform="translate(29.127 169.236)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.045,1.992l2-2.047L-.051,1.992Z"
          transform="translate(29.127 169.236)"
        />
        <path
          className={classes.a}
          d="M1.954-.055,4.091,3.114,1.954-.055-.051,1.992Z"
          transform="translate(34.499 163.752)"
        />
        <path
          className={classes.c}
          d="M1.954-.055,4.091,3.114Zm0,0L-.051,1.992Z"
          transform="translate(34.499 163.752)"
        />
        <path
          className={classes.a}
          d="M2.08,5.155-.051,1.992,1.954-.055,4.085,3.108,1.954-.055-.051,1.992Z"
          transform="translate(31.816 166.497)"
        />
        <path
          className={classes.c}
          d="M2.08,5.155-.051,1.992,1.954-.055,4.085,3.108,1.954-.055-.051,1.992Z"
          transform="translate(31.816 166.497)"
        />
        <path
          className={classes.a}
          d="M1.954-.055,4.091,3.108,1.954-.055-.051,1.992Z"
          transform="translate(37.182 161.014)"
        />
        <path
          className={classes.c}
          d="M1.954-.055,4.091,3.108Zm0,0L-.051,1.992Z"
          transform="translate(37.182 161.014)"
        />
        <path
          className={classes.a}
          d="M1.954-.055,4.085,3.114,1.954-.055-.051,1.992Z"
          transform="translate(31.831 159.794)"
        />
        <path
          className={classes.c}
          d="M1.954-.055,4.085,3.114Zm0,0L-.051,1.992Z"
          transform="translate(31.831 159.794)"
        />
        <path
          className={classes.a}
          d="M2.086,5.155-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(23.761 174.72)"
        />
        <path
          className={classes.c}
          d="M2.086,5.155-.051,1.992,1.954-.055-.051,1.992Z"
          transform="translate(23.761 174.72)"
        />
        <path
          className={classes.a}
          d="M2.085,5.161-.051,1.992,1.954-.049V-.055L-.051,1.992Z"
          transform="translate(26.444 171.975)"
        />
        <path
          className={classes.c}
          d="M2.085,5.161-.051,1.992,1.954-.049V-.055L-.051,1.992Z"
          transform="translate(26.444 171.975)"
        />
        <path
          className={classes.a}
          d="M1.955-.055-.051,1.992,2.086,5.155-.045,1.991Z"
          transform="translate(18.389 180.204)"
        />
        <path
          className={classes.c}
          d="M1.955-.055-.051,1.992,2.086,5.155-.045,1.991Z"
          transform="translate(18.389 180.204)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055H1.949l-2,2.047.3.447Z"
          transform="translate(21.078 177.458)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055H1.949Zm0,0,.3.447Z"
          transform="translate(21.078 177.458)"
        />
        <path
          className={classes.a}
          d="M0,0,1.834,2.723.005,0Z"
          transform="translate(21.323 179.897)"
        />
        <path
          className={classes.c}
          d="M0,0,1.834,2.723.005,0Z"
          transform="translate(21.323 179.897)"
        />
        <path
          className={classes.a}
          d="M-.012.475.571.524l.27.019L.465-.013Z"
          transform="translate(43.506 154.692)"
        />
        <path
          className={classes.c}
          d="M.841.543.465-.013-.012.475.571.524Z"
          transform="translate(43.506 154.692)"
        />
        <path
          className={classes.a}
          d="M2.161,2.206.66-.019-.017.672.946,2.109l1.209.1Z"
          transform="translate(33.818 152.362)"
        />
        <path
          className={classes.c}
          d="M2.161,2.206.66-.019-.017.672.946,2.109l1.209.1Z"
          transform="translate(33.818 152.362)"
        />
        <path
          className={classes.a}
          d="M2.651,4.631l.4.6,1.215.091v.007L3.328,3.94,5.334,1.893,4.8,1.1,2.8,3.145.661-.019-.017.673l2.137,3.17L.925,5.063l1.215.091Z"
          transform="translate(36.501 149.623)"
        />
        <path
          className={classes.c}
          d="M2.651,4.631l.4.6,1.215.091v.007L3.328,3.94,5.334,1.893,4.8,1.1,2.8,3.145.661-.019-.017.673l2.137,3.17L.925,5.063l1.215.091Z"
          transform="translate(36.501 149.623)"
        />
        <path
          className={classes.a}
          d="M.666-.019H.661L-.017.673V.68l.469.686,1.209.1Z"
          transform="translate(28.446 157.845)"
        />
        <path
          className={classes.c}
          d="M.666-.019H.661L-.017.673V.68l.469.686,1.209.1Z"
          transform="translate(28.446 157.845)"
        />
        <path
          className={classes.a}
          d="M1.55,4.946l2.208.175L3.32,4.474V4.468l2.006-2.04L7.337,5.409l1.5.117L10.677,3.64l1.454,2.154,1.215.1L11.36,2.949,13.366.9,13.21.663H13.2l-.27-.019L12.351.6,10.823,2.154,9.625.375,8.416.284l1.73,2.568L8.14,4.9,6,1.729,7.5.211,6.283.113,5.472.94,4.837,0,3.622-.1,4.789,1.632l-2,2.047L.652.515V.509l-.683.7h.006L2.106,4.376Z"
          transform="translate(31.143 154.572)"
        />
        <path
          className={classes.c}
          d="M1.55,4.946l2.208.175L3.32,4.474V4.468l2.006-2.04L7.337,5.409l1.5.117L10.677,3.64l1.454,2.154,1.215.1L11.36,2.949,13.366.9,13.21.663H13.2l-.27-.019L12.351.6,10.823,2.154,9.625.375,8.416.284l1.73,2.568L8.14,4.9,6,1.729,7.5.211,6.283.113,5.472.94,4.837,0,3.622-.1,4.789,1.632l-2,2.047L.652.515V.509l-.683.7h.006L2.106,4.376Z"
          transform="translate(31.143 154.572)"
        />
        <path
          className={classes.a}
          d="M0,.006H0v0ZM.423,5.248,2.267,3.364,3.72,5.518,2.267,3.364Z"
          transform="translate(39.553 154.849)"
        />
        <path
          className={classes.c}
          d="M-.047,1.834,1.8-.051,3.251,2.1,1.8-.051Z"
          transform="translate(40.023 158.264)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055,3.966,2.926,1.954-.055Z"
          transform="translate(34.514 157.055)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055,3.966,2.926,1.954-.055Z"
          transform="translate(34.514 157.055)"
        />
        <path
          className={classes.a}
          d="M1.451-.041-.038,1.478,1.456-.041Z"
          transform="translate(37.184 154.824)"
        />
        <path
          className={classes.c}
          d="M1.451-.041-.038,1.478,1.456-.041Z"
          transform="translate(37.184 154.824)"
        />
        <path
          className={classes.a}
          d="M1.735,2.568.006,0H0Z"
          transform="translate(39.553 154.856)"
        />
        <path
          className={classes.c}
          d="M1.735,2.568.006,0H0Z"
          transform="translate(39.553 154.856)"
        />
        <path
          className={classes.a}
          d="M.27.019,0,0Z"
          transform="translate(44.077 155.216)"
        />
        <path
          className={classes.c}
          d="M.27.019,0,0Z"
          transform="translate(44.077 155.216)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1H1.209Z"
          transform="translate(34.764 154.47)"
        />
        <path
          className={classes.c}
          d="M1.215.1H1.209L0,0Z"
          transform="translate(34.764 154.47)"
        />
        <path
          className={classes.a}
          d="M1.112.088,0,0Z"
          transform="translate(39.656 154.866)"
        />
        <path
          className={classes.c}
          d="M0,0H.006L1.215.091Z"
          transform="translate(39.553 154.856)"
        />
        <path
          className={classes.a}
          d="M1.168,1.9,2.3.746,1.77-.05-.046,1.8Z"
          transform="translate(44.875 158.687)"
        />
        <path
          className={classes.c}
          d="M1.168,1.9,2.3.746,1.77-.05-.046,1.8Z"
          transform="translate(44.875 158.687)"
        />
        <path
          className={classes.a}
          d="M37.081,14.593,9.828,42.386a.885.885,0,0,1-1.125.178,1.013,1.013,0,0,1-.3-.3L-.509,29.106A1.434,1.434,0,0,1-.6,27.744a1.317,1.317,0,0,1,.258-.383L26.915-.472A.888.888,0,0,1,28.34-.36l8.9,13.189a1.359,1.359,0,0,1,.227.9,1.418,1.418,0,0,1-.381.863"
          transform="translate(-2.972 127.478)"
        />
        <path
          className={classes.c}
          d="M37.081,14.593,9.828,42.386a.885.885,0,0,1-1.125.178,1.013,1.013,0,0,1-.3-.3L-.509,29.106A1.434,1.434,0,0,1-.6,27.744a1.317,1.317,0,0,1,.258-.383L26.915-.472A.888.888,0,0,1,28.34-.36l8.9,13.189a1.359,1.359,0,0,1,.227.9,1.418,1.418,0,0,1-.381.863Z"
          transform="translate(-2.972 127.478)"
        />
        <path
          className={classes.a}
          d="M-.017.673,1.39,2.756l1.215.1L.666-.019Z"
          transform="translate(16.726 133.742)"
        />
        <path
          className={classes.c}
          d="M-.017.673,1.39,2.756l1.215.1L.666-.019Z"
          transform="translate(16.726 133.742)"
        />
        <path
          className={classes.a}
          d="M.292.427.664.048,0,0Z"
          transform="translate(29.246 146.288)"
        />
        <path
          className={classes.c}
          d="M.292.433.664.054,0,0Z"
          transform="translate(29.246 146.282)"
        />
        <path
          className={classes.a}
          d="M-.008.323.57.366.314-.009Z"
          transform="translate(31.923 137.282)"
        />
        <path
          className={classes.c}
          d="M.314-.009-.008.323.569.373Z"
          transform="translate(31.923 137.282)"
        />
        <path
          className={classes.a}
          d="M1.51,8.4h.005L2.587,7.3l.839,1.249h.006l1.209.1L3.266,6.6,5.271,4.557l2.136,3.17L6.369,8.785l1.215.1h0l.355-.366.281.421h0l1.215.1-.813-1.21,2.006-2.047-.537-.8L8.086,7.029,5.949,3.866,7.954,1.819l-.531-.8L5.417,3.07,3.281-.093,2.6.6,4.74,3.768,2.734,5.816.6,2.646l-.683.7L2.051,6.507.3,8.3l1.209.1V8.4Z"
          transform="translate(19.478 128.332)"
        />
        <path
          className={classes.c}
          d="M1.51,8.4h.005L2.587,7.3l.839,1.249h.006l1.209.1L3.266,6.6,5.271,4.557l2.136,3.17L6.369,8.785l1.215.1h0l.355-.366.281.421h0l1.215.1-.813-1.21,2.006-2.047-.537-.8L8.086,7.029,5.949,3.866,7.954,1.819l-.531-.8L5.417,3.07,3.281-.093,2.6.6,4.74,3.768,2.734,5.816.6,2.646l-.683.7L2.051,6.507.3,8.3l1.209.1V8.4Z"
          transform="translate(19.478 128.332)"
        />
        <path
          className={classes.a}
          d="M7.973,6.986,5.836,3.816l2-2.034,2.137,3.17Zm7.357-.834.683-.679L13.876,2.3,15.1,1.05,13.889.96l-.555.556L12.9.868l-1.209-.1.959,1.423L10.645,4.228,8.514,1.065,9.02.543,6.915.378l.4.6L5.31,3.008,3.336.077,1.754-.049l-1.8,1.837.52.789L2.48.543l2.131,3.17-2,2.034.521.789,2-2.034,2.137,3.17L5.268,9.706l.521.789,2-2.034,2.137,3.17.678-.678L8.472,7.782l2-2.034,2.137,3.17L13.3,8.24,11.159,5.077l2.005-2.034,2.165,3.11Z"
          transform="translate(-1.907 157.014)"
        />
        <path
          className={classes.c}
          d="M7.973,6.986,5.836,3.816l2-2.034,2.137,3.17Zm7.357-.834.683-.679L13.876,2.3,15.1,1.05,13.889.96l-.555.556L12.9.868l-1.209-.1.959,1.423L10.645,4.228,8.514,1.065,9.02.543,6.915.378l.4.6L5.31,3.008,3.336.077,1.754-.049l-1.8,1.837.52.789L2.48.543l2.131,3.17-2,2.034.521.789,2-2.034,2.137,3.17L5.268,9.706l.521.789,2-2.034,2.137,3.17.678-.678L8.472,7.782l2-2.034,2.137,3.17L13.3,8.24,11.159,5.077l2.005-2.034,2.165,3.11Z"
          transform="translate(-1.907 157.014)"
        />
        <path
          className={classes.a}
          d="M0,0,1.527,2.264l.678-.7L1.215.1Z"
          transform="translate(14.589 158.164)"
        />
        <path
          className={classes.c}
          d="M0,0,1.527,2.264l.678-.7L1.215.1Z"
          transform="translate(14.589 158.164)"
        />
        <path
          className={classes.a}
          d="M.661-.019l-.678.7L.67,1.7l1.215.1Z"
          transform="translate(0.621 150.187)"
        />
        <path
          className={classes.c}
          d="M.661-.019l-.678.7L.67,1.7l1.215.1Z"
          transform="translate(0.621 150.187)"
        />
        <path
          className={classes.a}
          d="M13.436,8.282,11.3,5.112,13.3,3.078l2.136,3.17ZM8.057,7.063,5.921,3.9l2-2.034L10.09,5.015Zm15.4-2.352.678-.678L22.266,1.271l-1.8-.174L18.8,2.8,17.5.858,16.285.76l1.84,2.723L16.12,5.517l-2.138-3.11L15.61.714,14.4.616l-.945.963L12.711.465,11.5.368l1.277,1.9L10.768,4.3,8.632,1.128,9.543.2,8.328.1,8.1.339,7.918.067,6.7-.024l.713,1.06-2,2.034L3.281-.093,2.6.586,4.734,3.755l-2,2.034L.593,2.619-.085,3.3l2.137,3.17-.846.914,1.215.1.161-.163.125.188,1.215.091-.663-.93,2-2.034L7.4,7.8l-.122.122,1.436.117-.1-.142,2-2.1,1.673,2.478,2.267.18,1.438-1.431L17.1,8.673l1.215.1L16.672,6.332,18.677,4.3l2.131,3.164.683-.679L19.324,3.574l2-2.034,2.126,3.17Z"
          transform="translate(3.374 144.75)"
        />
        <path
          className={classes.c}
          d="M13.436,8.282,11.3,5.112,13.3,3.078l2.136,3.17ZM8.057,7.063,5.921,3.9l2-2.034L10.09,5.015Zm15.4-2.352.678-.678L22.266,1.271l-1.8-.174L18.8,2.8,17.5.858,16.285.76l1.84,2.723L16.12,5.517l-2.138-3.11L15.61.714,14.4.616l-.945.963L12.711.465,11.5.368l1.277,1.9L10.768,4.3,8.632,1.128,9.543.2,8.328.1,8.1.339,7.918.067,6.7-.024l.713,1.06-2,2.034L3.281-.093,2.6.586,4.734,3.755l-2,2.034L.593,2.619-.085,3.3l2.137,3.17-.846.914,1.215.1.161-.163.125.188,1.215.091-.663-.93,2-2.034L7.4,7.8l-.122.122,1.436.117-.1-.142,2-2.1,1.673,2.478,2.267.18,1.438-1.431L17.1,8.673l1.215.1L16.672,6.332,18.677,4.3l2.131,3.164.683-.679L19.324,3.574l2-2.034,2.126,3.17Z"
          transform="translate(3.374 144.75)"
        />
        <path
          className={classes.a}
          d="M18.706,2.537,16.7,4.585l1.146,1.694-1.215-.1-.61-.906-.778.793L14.03,5.978l1.456-1.491-2.137-3.17-2,2.047,1.7,2.535-1.209-.1-1.178-1.74L9.171,5.588,7.05,5.417,5.315,2.842,3.31,4.89l.161.239L1.889,5l.206-.21L-.042,1.622.641.931,2.778,4.094l2-2.04L3.334-.094,4.544,0l.917,1.351L6.622.169l1.215.1L5.993,2.145,8.129,5.314l2.006-2.047L8.123.291,9.338.383l1.475,2.193L12.7.657l1.436.111,2.038,3.021,2.005-2.047-.463-.685,2.267.18-.595.6,2.137,3.17-.678.691L18.706,2.537Z"
          transform="translate(-2.043 151.982)"
        />
        <path
          className={classes.c}
          d="M18.706,2.537,16.7,4.585l1.146,1.694-1.215-.1-.61-.906-.778.793L14.03,5.978l1.456-1.491-2.137-3.17-2,2.047,1.7,2.535-1.209-.1-1.178-1.74L9.171,5.588,7.05,5.417,5.315,2.842,3.31,4.89l.161.239L1.889,5l.206-.21L-.042,1.622.641.931,2.778,4.094l2-2.04L3.334-.094,4.544,0l.917,1.351L6.622.169l1.215.1L5.993,2.145,8.129,5.314l2.006-2.047L8.123.291,9.338.383l1.475,2.193L12.7.657l1.436.111,2.038,3.021,2.005-2.047-.463-.685,2.267.18-.595.6,2.137,3.17-.678.691L18.706,2.537Z"
          transform="translate(-2.043 151.982)"
        />
        <path
          className={classes.a}
          d="M1.71.822l-.683.7L0,0,1.215.1Z"
          transform="translate(20.456 153.424)"
        />
        <path
          className={classes.c}
          d="M1.71.822l-.683.7L0,0,1.215.1Z"
          transform="translate(20.456 153.424)"
        />
        <path
          className={classes.a}
          d="M.006,0H0L2.137,3.17,2.009,3.3h.006l.122-.128Z"
          transform="translate(8.638 149.341)"
        />
        <path
          className={classes.c}
          d="M.006,0H0L2.137,3.17,2.009,3.3h.006l.122-.128Z"
          transform="translate(8.638 149.341)"
        />
        <path
          className={classes.a}
          d="M-.048,1.867,1.835-.052H1.829Z"
          transform="translate(8.818 152.691)"
        />
        <path
          className={classes.c}
          d="M-.048,1.867,1.835-.052H1.829Z"
          transform="translate(8.818 152.691)"
        />
        <path
          className={classes.a}
          d="M1.939.552-.067,2.6,1.939.552l-.4-.6Z"
          transform="translate(3.469 157.442)"
        />
        <path
          className={classes.c}
          d="M1.939.552-.067,2.6Zm0,0-.4-.6Z"
          transform="translate(3.469 157.442)"
        />
        <path
          className={classes.a}
          d="M1.735,2.575,0,0Z"
          transform="translate(3.272 154.824)"
        />
        <path
          className={classes.c}
          d="M1.735,2.575,0,0Z"
          transform="translate(3.272 154.824)"
        />
        <path
          className={classes.a}
          d="M.222-.006-.006.231Z"
          transform="translate(11.479 144.884)"
        />
        <path
          className={classes.c}
          d="M-.006.231.222-.006Z"
          transform="translate(11.479 144.884)"
        />
        <path
          className={classes.a}
          d="M-.024.937l.95-.963H.92Z"
          transform="translate(16.849 145.392)"
        />
        <path
          className={classes.c}
          d="M-.024.937l.95-.963H.92Z"
          transform="translate(16.849 145.392)"
        />
        <path
          className={classes.a}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(22.223 145.899)"
        />
        <path
          className={classes.c}
          d="M-.042,1.649,1.619-.046Z"
          transform="translate(22.223 145.899)"
        />
        <path
          className={classes.a}
          d="M1.9,1.877-.1,3.925,1.9,1.877.628-.018Z"
          transform="translate(14.242 145.149)"
        />
        <path
          className={classes.c}
          d="M1.9,1.877-.1,3.925Zm0,0L.628-.018Z"
          transform="translate(14.242 145.149)"
        />
        <path
          className={classes.a}
          d="M1.883,2.722-.123,4.769,1.883,2.722.049,0Z"
          transform="translate(19.615 145.518)"
        />
        <path
          className={classes.c}
          d="M1.883,2.722-.123,4.769Zm0,0L.049,0Z"
          transform="translate(19.615 145.518)"
        />
        <path
          className={classes.a}
          d="M.131,5.21,2.136,3.163,0,0,2.131,3.163Z"
          transform="translate(11.327 146.602)"
        />
        <path
          className={classes.c}
          d="M.131,5.21,2.136,3.163,0,0,2.131,3.163Z"
          transform="translate(11.327 146.602)"
        />
        <path
          className={classes.a}
          d="M0,0,.126.189H.131L0,0Z"
          transform="translate(5.955 152.086)"
        />
        <path
          className={classes.c}
          d="M0,0,.126.189H.131L0,0Z"
          transform="translate(5.955 152.086)"
        />
        <path
          className={classes.a}
          d="M2.012,2.982.006,5.029,2.012,2.982.005.006,0,0Z"
          transform="translate(6.08 152.267)"
        />
        <path
          className={classes.c}
          d="M2.012,2.982.006,5.029Zm0,0L.005.006,0,0Z"
          transform="translate(6.08 152.267)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.17.131,5.217,2.137,3.17Z"
          transform="translate(5.94 158.783)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.17.131,5.217,2.137,3.17Z"
          transform="translate(5.94 158.783)"
        />
        <path
          className={classes.a}
          d="M0,0,1.673,2.478Z"
          transform="translate(13.995 150.561)"
        />
        <path
          className={classes.c}
          d="M0,0,1.673,2.478Z"
          transform="translate(13.995 150.561)"
        />
        <path
          className={classes.a}
          d="M1.936.644-.069,2.691,1.936.644,1.473-.042Z"
          transform="translate(14.195 153.08)"
        />
        <path
          className={classes.c}
          d="M1.936.644-.069,2.691Zm0,0L1.473-.042Z"
          transform="translate(14.195 153.08)"
        />
        <path
          className={classes.a}
          d="M.131,5.216,2.136,3.169,0,0,2.136,3.169Z"
          transform="translate(16.678 147.816)"
        />
        <path
          className={classes.c}
          d="M.131,5.216,2.136,3.169,0,0,2.136,3.169Z"
          transform="translate(16.678 147.816)"
        />
        <path
          className={classes.a}
          d="M1.917,1.4-.089,3.45,1.917,1.4.958-.021V-.027Z"
          transform="translate(8.843 157.805)"
        />
        <path
          className={classes.c}
          d="M1.917,1.4-.089,3.45Zm0,0L.958-.021V-.027Z"
          transform="translate(8.843 157.805)"
        />
        <path
          className={classes.a}
          d="M1.178,1.74,0,0Z"
          transform="translate(8.623 156.045)"
        />
        <path
          className={classes.c}
          d="M1.178,1.74,0,0Z"
          transform="translate(8.623 156.045)"
        />
        <path
          className={classes.a}
          d="M.53-.015-.014.541Z"
          transform="translate(11.457 157.975)"
        />
        <path
          className={classes.c}
          d="M-.014.541.53-.015Z"
          transform="translate(11.457 157.975)"
        />
        <path
          className={classes.a}
          d="M.676,4.661,2.131,3.17,0,0,2.131,3.17Z"
          transform="translate(11.312 153.299)"
        />
        <path
          className={classes.c}
          d="M.676,4.661,2.131,3.17,0,0,2.131,3.17Z"
          transform="translate(11.312 153.299)"
        />
        <path
          className={classes.a}
          d="M15.171,3.883l-.005.007h.005ZM.666-.019l-.683.7,1.423,2.1,1.215.1L.666-.019Z"
          transform="translate(8.671 141.964)"
        />
        <path
          className={classes.c}
          d="M.666-.019l-.683.7,1.423,2.1,1.215.1Z"
          transform="translate(8.671 141.964)"
        />
        <path
          className={classes.a}
          d="M8.34.041,8.335.048H8.34Zm5.086,8.175L11.29,5.053l2-2.034,2.137,3.163ZM8.048,7,5.911,3.834,7.917,1.8l2.136,3.17Zm12.714.386.683-.679L19.307,3.549l2-2.034L21.031,1.1l-.577-.05L18.787,2.766,17.468.826l-1.215-.1,1.855,2.749L16.1,5.511,13.967,2.342,15.633.66l-1.215-.1-.967.989L12.691.426l-1.209-.1L12.773,2.27,10.762,4.3,8.631,1.141,9.559.185,8.35.088,8.094.345,7.9.054l-1.215-.1.73,1.087L5.411,3.077,3.275-.092,2.6.585,4.734,3.749,2.729,5.783.592,2.619-.085,3.3l2.131,3.17L.274,8.277l1.215.1L2.6,7.256l.86,1.294,1.215.1-1.4-2.07L5.282,4.544,7.418,7.707,6.358,8.791l1.215.1L7.95,8.5l.3.44,1.215.1L8.633,7.8l2.005-2.034,2.131,3.17-.339.345,1.825.153-.271-.4L15.989,7,17.8,9.695l.664.054.305-.312-2.131-3.17,2-2.034Z"
          transform="translate(11.438 136.587)"
        />
        <path
          className={classes.c}
          d="M13.427,8.217,11.29,5.053l2-2.034,2.137,3.163ZM8.048,7,5.911,3.834,7.917,1.8l2.136,3.17Zm12.714.386.683-.679L19.307,3.549l2-2.034L21.031,1.1l-.577-.05L18.787,2.766,17.468.826l-1.215-.1,1.855,2.749L16.1,5.511,13.967,2.342,15.633.66l-1.215-.1-.967.989L12.691.426l-1.209-.1L12.773,2.27,10.762,4.3,8.631,1.141,9.559.185,8.35.088,8.094.345,7.9.054l-1.215-.1.73,1.087L5.411,3.077,3.275-.092,2.6.585,4.734,3.749,2.729,5.783.592,2.619-.085,3.3l2.131,3.17L.274,8.277l1.215.1L2.6,7.256l.86,1.294,1.215.1-1.4-2.07L5.282,4.544,7.418,7.707,6.358,8.791l1.215.1L7.95,8.5l.3.44,1.215.1L8.633,7.8l2.005-2.034,2.131,3.17-.339.345,1.825.153-.271-.4L15.989,7,17.8,9.695l.664.054.305-.312-2.131-3.17,2-2.034Z"
          transform="translate(11.438 136.587)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(22.248 138.853)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(22.248 138.853)"
        />
        <path
          className={classes.a}
          d="M.249-.007-.006.244Z"
          transform="translate(19.53 136.642)"
        />
        <path
          className={classes.c}
          d="M.249-.007H.244l-.25.251Z"
          transform="translate(19.53 136.642)"
        />
        <path
          className={classes.a}
          d="M.86,1.274,0,0Z"
          transform="translate(14.01 143.857)"
        />
        <path
          className={classes.c}
          d="M.86,1.274,0,0Z"
          transform="translate(14.01 143.857)"
        />
        <path
          className={classes.a}
          d="M.761,1.126.006,0H0L.761,1.126l.967-.99Z"
          transform="translate(24.119 136.98)"
        />
        <path
          className={classes.c}
          d="M.761,1.126.006,0H0Zm0,0,.967-.99Z"
          transform="translate(24.119 136.98)"
        />
        <path
          className={classes.a}
          d="M.005,0H0L2.137,3.17,4.142,1.122,2.137,3.17Z"
          transform="translate(25.411 138.895)"
        />
        <path
          className={classes.c}
          d="M.005,0H0L2.137,3.17,4.142,1.122,2.137,3.17Z"
          transform="translate(25.411 138.895)"
        />
        <path
          className={classes.a}
          d="M1.319,1.953,3,.238,1.319,1.953,0,0Z"
          transform="translate(28.912 137.366)"
        />
        <path
          className={classes.c}
          d="M1.319,1.953,3,.238Zm0,0L0,0Z"
          transform="translate(28.912 137.366)"
        />
        <path
          className={classes.a}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(24.916 142.811)"
        />
        <path
          className={classes.c}
          d="M-.051,1.992,1.954-.055Z"
          transform="translate(24.916 142.811)"
        />
        <path
          className={classes.a}
          d="M.131,5.217,2.137,3.17,0,0,2.137,3.17Z"
          transform="translate(19.377 138.373)"
        />
        <path
          className={classes.c}
          d="M.131,5.217,2.137,3.17,0,0,2.137,3.17Z"
          transform="translate(19.377 138.373)"
        />
        <path
          className={classes.a}
          d="M0,0,2.131,3.17l-.339.346.345-.346Z"
          transform="translate(22.05 142.332)"
        />
        <path
          className={classes.c}
          d="M0,0,2.131,3.17l-.339.346.345-.346Z"
          transform="translate(22.05 142.332)"
        />
        <path
          className={classes.a}
          d="M0,0,2.137,3.163,1.076,4.248h.005L2.137,3.163Z"
          transform="translate(16.693 141.119)"
        />
        <path
          className={classes.c}
          d="M0,0,2.137,3.163,1.076,4.248h.005L2.137,3.163Z"
          transform="translate(16.693 141.119)"
        />
        <path
          className={classes.a}
          d="M0,0,.3.439H.3L.005,0Z"
          transform="translate(19.362 145.077)"
        />
        <path
          className={classes.c}
          d="M.3.439,0,0,.3.439Z"
          transform="translate(19.362 145.077)"
        />
        <path
          className={classes.a}
          d="M0,0,.578.049V.043Z"
          transform="translate(31.914 137.605)"
        />
        <path
          className={classes.c}
          d="M.578.049V.043L0,0Z"
          transform="translate(31.914 137.605)"
        />
        <path
          className={classes.a}
          d="M0,0,1.209.1Z"
          transform="translate(22.91 136.883)"
        />
        <path
          className={classes.c}
          d="M0,0,1.209.1Z"
          transform="translate(22.91 136.883)"
        />
        <path
          className={classes.a}
          d="M0,0,1.209.1h.005Z"
          transform="translate(25.846 137.117)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(25.846 137.117)"
        />
        <path
          className={classes.a}
          d="M0,.007,1.209.1V.1L0,0Z"
          transform="translate(19.779 136.628)"
        />
        <path
          className={classes.c}
          d="M1.209.1V.1L0,0V.007Z"
          transform="translate(19.779 136.628)"
        />
        <path
          className={classes.a}
          d="M0,0,1.215.1Z"
          transform="translate(27.698 137.269)"
        />
        <path
          className={classes.c}
          d="M0,0,1.215.1Z"
          transform="translate(27.698 137.269)"
        />
        <path
          className={classes.a}
          d="M.651-.018-.017.665l24.55,36.408.669-.683Z"
          transform="translate(19.416 131.004)"
        />
        <path
          className={classes.c}
          d="M.651-.018-.017.665l24.55,36.408.669-.683Z"
          transform="translate(19.416 131.004)"
        />
        <path
          className={classes.a}
          d="M.651-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(0.628 150.187)"
        />
        <path
          className={classes.c}
          d="M.651-.018-.017.664l24.55,36.408.669-.683Z"
          transform="translate(0.628 150.187)"
        />
        <path
          className={classes.a}
          d="M1.845,17.06l-2.272-.424L16.311-.46l-.073,2.824A3.814,3.814,0,0,1,15.2,4.917L4.093,16.278a2.476,2.476,0,0,1-2.227.781"
          transform="translate(116.68 138.958)"
        />
        <path
          className={classes.c}
          d="M1.845,17.06l-2.272-.424L16.311-.46l-.073,2.824A3.814,3.814,0,0,1,15.2,4.917L4.093,16.278a2.475,2.475,0,0,1-2.249.782Z"
          transform="translate(116.68 138.958)"
        />
        <path
          className={classes.a}
          d="M17.5,30.461,16.59,29.1l3.445-3.525a2.458,2.458,0,0,0,.293-2.968L6.68,2.363a1.512,1.512,0,0,0-2.429-.2L.8,5.693-.111,4.335,3.333.817A2.993,2.993,0,0,1,5.7-.145,2.873,2.873,0,0,1,7.841,1.177L21.494,21.423a4.574,4.574,0,0,1-.542,5.519Z"
          transform="translate(107.585 96.054)"
        />
        <path
          className={classes.c}
          d="M17.5,30.461,16.59,29.1l3.445-3.525a2.458,2.458,0,0,0,.293-2.968L6.68,2.363a1.512,1.512,0,0,0-2.429-.2L.8,5.693-.111,4.335,3.333.817A2.993,2.993,0,0,1,5.7-.145,2.873,2.873,0,0,1,7.841,1.177L21.494,21.423a4.574,4.574,0,0,1-.542,5.519Z"
          transform="translate(107.585 96.054)"
        />
        <path
          className={classes.a}
          d="M14.14,29.15.5,8.917A4.637,4.637,0,0,1,1.045,3.4L4.495-.127l.917,1.358L1.963,4.756A2.458,2.458,0,0,0,1.67,7.724L15.323,27.97a1.511,1.511,0,0,0,2.43.2L21.2,24.647l.917,1.358L18.648,29.5a2.994,2.994,0,0,1-2.366.962,2.873,2.873,0,0,1-2.142-1.323"
          transform="translate(86.04 117.834)"
        />
        <path
          className={classes.c}
          d="M14.14,29.15.5,8.917A4.637,4.637,0,0,1,1.045,3.4L4.495-.127l.917,1.358L1.963,4.756A2.458,2.458,0,0,0,1.67,7.724L15.323,27.97a1.513,1.513,0,0,0,2.43.195L21.2,24.647l.917,1.358L18.648,29.5a2.8,2.8,0,0,1-4.508-.347Z"
          transform="translate(86.04 117.834)"
        />
        <path
          className={classes.a}
          d="M20.823-.587-.546,21.236l28.982,42.98L49.8,42.392Z"
          transform="translate(64.979 63.295)"
        />
        <path
          className={classes.c}
          d="M20.823-.587-.546,21.236l28.982,42.98L49.8,42.392Z"
          transform="translate(64.979 63.295)"
        />
        <path
          className={classes.a}
          d="M9.782,19.327l-9.9-14.71L4.515-.127,17.021,18.431l-1.328,1.356a3.91,3.91,0,0,1-3.1,1.25,3.762,3.762,0,0,1-2.8-1.743"
          transform="translate(81.309 62.823)"
        />
        <path
          className={classes.c}
          d="M9.782,19.327l-9.9-14.71L4.515-.127,17.021,18.431l-1.328,1.356a3.919,3.919,0,0,1-3.1,1.275,3.755,3.755,0,0,1-2.81-1.734Z"
          transform="translate(81.309 62.823)"
        />
        <path
          className={classes.a}
          d="M8.232-.232-.216,8.4l28.982,42.98,8.448-8.628Z"
          transform="translate(64.648 76.138)"
        />
        <path
          className={classes.c}
          d="M8.232-.232-.216,8.4l28.982,42.98,8.448-8.628Z"
          transform="translate(64.648 76.138)"
        />
        <path
          className={classes.a}
          d="M20.218,38.036-.2,7.724,7.579-.214,28.016,30.1a2.116,2.116,0,0,1-.249,2.552L22.28,38.2a1.3,1.3,0,0,1-2.083-.166"
          transform="translate(70.673 70.664)"
        />
        <path
          className={classes.c}
          d="M20.218,38.036-.2,7.724,7.579-.214,28.016,30.1a2.116,2.116,0,0,1-.249,2.552L22.28,38.2a1.292,1.292,0,0,1-2.062-.166Z"
          transform="translate(70.673 70.664)"
        />
        <path
          className={classes.a}
          d="M20.24,38.035-.2,7.724,7.579-.214,28.017,30.1a1.994,1.994,0,0,1,.306,1.307,2.056,2.056,0,0,1-.556,1.238L22.329,38.2a1.384,1.384,0,0,1-1.094.446,1.328,1.328,0,0,1-.99-.611"
          transform="translate(71.429 69.871)"
        />
        <path
          className={classes.c}
          d="M20.24,38.035-.2,7.724,7.579-.214,28.017,30.1a1.994,1.994,0,0,1,.306,1.307,2.056,2.056,0,0,1-.556,1.238L22.329,38.2a1.3,1.3,0,0,1-1.649.267,1.463,1.463,0,0,1-.44-.432Z"
          transform="translate(71.429 69.871)"
        />
        <path
          className={classes.a}
          d="M7.576-.214-.2,7.716l.3.451L7.88.238Z"
          transform="translate(86.074 91.583)"
        />
        <path
          className={classes.c}
          d="M7.576-.214-.2,7.716l.3.451L7.88.238Z"
          transform="translate(86.074 91.583)"
        />
        <path
          className={classes.a}
          d="M7.576-.214-.2,7.716l.3.451L7.88.238Z"
          transform="translate(81.492 84.796)"
        />
        <path
          className={classes.c}
          d="M7.576-.214-.2,7.716l.3.451L7.88.238Z"
          transform="translate(81.492 84.796)"
        />
        <path
          className={classes.a}
          d="M20.334,34.367-.1,4.057,3.974-.112,25.339,31.583l-2.922,2.97A1.379,1.379,0,0,1,21.323,35a1.32,1.32,0,0,1-.99-.611"
          transform="translate(71.335 73.539)"
        />
        <path
          className={classes.c}
          d="M20.334,34.367-.1,4.057,3.974-.112,25.339,31.583l-2.922,2.97a1.377,1.377,0,0,1-1.1.438A1.33,1.33,0,0,1,20.334,34.367Z"
          transform="translate(71.335 73.539)"
        />
        <path
          className={classes.a}
          d="M3.979-.112-.1,4.052.2,4.5,4.283.34Z"
          transform="translate(85.979 95.243)"
        />
        <path
          className={classes.c}
          d="M3.979-.112-.1,4.052.2,4.5,4.283.34Z"
          transform="translate(85.979 95.243)"
        />
        <path
          className={classes.a}
          d="M3.979-.112-.1,4.052.2,4.5,4.284.339Z"
          transform="translate(81.402 88.463)"
        />
        <path
          className={classes.c}
          d="M3.979-.112-.1,4.052.2,4.5,4.284.339Z"
          transform="translate(81.402 88.463)"
        />
        <path
          className={classes.a}
          d="M-.214,21.877l11.9,17.646a1.3,1.3,0,0,0,2.078.166L32.8,20.24a2.128,2.128,0,0,0,.511-1.935,1.834,1.834,0,0,0-.262-.609L21.157.05a1.327,1.327,0,0,0-.99-.612,1.383,1.383,0,0,0-1.094.446L.035,19.326a1.908,1.908,0,0,0-.377.56,2.092,2.092,0,0,0,.127,1.991"
          transform="translate(101.956 117.997)"
        />
        <path
          className={classes.c}
          d="M-.214,21.877l11.9,17.646a1.3,1.3,0,0,0,2.078.166L32.8,20.24a2.128,2.128,0,0,0,.511-1.935,1.834,1.834,0,0,0-.262-.609L21.157.05a1.327,1.327,0,0,0-.99-.612,1.383,1.383,0,0,0-1.094.446L.035,19.326a1.908,1.908,0,0,0-.377.56,2.092,2.092,0,0,0,.127,1.991h0Z"
          transform="translate(101.956 117.997)"
        />
        <path
          className={classes.a}
          d="M.095,9.792l11.9,17.646a1.3,1.3,0,0,0,2.078.166l7.284-7.456L7.628-.215.345,7.241a2.113,2.113,0,0,0-.25,2.551"
          transform="translate(101.647 130.082)"
        />
        <path
          className={classes.c}
          d="M.095,9.792l11.9,17.646a1.3,1.3,0,0,0,2.078.166l7.284-7.456L7.628-.215.345,7.241a2.113,2.113,0,0,0-.25,2.551Z"
          transform="translate(101.647 130.082)"
        />
        <path
          className={classes.a}
          d="M20.823-.587-.546,21.236.98,23.5,22.348,1.675Z"
          transform="translate(61.927 58.777)"
        />
        <path
          className={classes.c}
          d="M20.823-.587-.546,21.236.98,23.5,22.348,1.675Z"
          transform="translate(61.927 58.777)"
        />
        <path
          className={classes.a}
          d="M-.471,18.337l-.092,3.6L20.808.1l-2.9-.544a3.157,3.157,0,0,0-2.844,1L.857,15.063a4.86,4.86,0,0,0-1.322,3.26"
          transform="translate(61.952 58.07)"
        />
        <path
          className={classes.c}
          d="M-.471,18.337l-.092,3.6L20.808.1l-2.9-.544a3.157,3.157,0,0,0-2.844,1L.857,15.063A4.865,4.865,0,0,0-.471,18.337Z"
          transform="translate(61.952 58.07)"
        />
        <path
          className={classes.a}
          d="M8.805-.248-.231,8.98l1.525,2.262L10.33,2.013Z"
          transform="translate(61.615 71.03)"
        />
        <path
          className={classes.c}
          d="M8.805-.248-.231,8.98l1.525,2.262L10.33,2.013Z"
          transform="translate(61.615 71.03)"
        />
        <path
          className={classes.a}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(65.158 62.776)"
        />
        <path
          className={classes.c}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(65.158 62.776)"
        />
        <path
          className={classes.a}
          d="M17.316-.488-.454,17.668l.639.947L17.955.459l-.639-.947Z"
          transform="translate(65.158 62.762)"
        />
        <path
          className={classes.c}
          d="M17.316-.488-.454,17.668l.639.947L17.955.459l-.639-.947Z"
          transform="translate(65.158 62.762)"
        />
        <path
          className={classes.a}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(101.555 116.747)"
        />
        <path
          className={classes.c}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(101.555 116.747)"
        />
        <path
          className={classes.a}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(95.662 108.019)"
        />
        <path
          className={classes.c}
          d="M17.319-.488-.454,17.663l1.525,2.262L18.844,1.773Z"
          transform="translate(95.662 108.019)"
        />
        <path
          className={classes.a}
          d="M17.319-.488-.454,17.663l.609.9L17.928.415Z"
          transform="translate(95.662 108.019)"
        />
        <path
          className={classes.c}
          d="M17.319-.488-.454,17.663l.609.9L17.928.415Z"
          transform="translate(95.662 108.019)"
        />
        <path
          className={classes.a}
          d="M6.48-.183-.17,6.609,1.355,8.871l6.65-6.792Z"
          transform="translate(95.383 119.072)"
        />
        <path
          className={classes.c}
          d="M6.48-.183-.17,6.609,1.355,8.871l6.65-6.792Z"
          transform="translate(95.383 119.072)"
        />
        <path
          className={classes.a}
          d="M6.48-.183-.17,6.609,1.356,8.871l6.65-6.792Z"
          transform="translate(101.271 127.804)"
        />
        <path
          className={classes.c}
          d="M6.48-.183-.17,6.609,1.356,8.871l6.65-6.792Z"
          transform="translate(101.271 127.804)"
        />
        <path
          className={classes.a}
          d="M11.8-.333-.309,12.036l4.365,6.473L16.167,6.14Z"
          transform="translate(99.877 113.01)"
        />
        <path
          className={classes.c}
          d="M11.8-.333-.309,12.036l4.365,6.473L16.167,6.14Z"
          transform="translate(99.877 113.01)"
        />
        <path
          className={classes.a}
          d="M6.09-.172-.16,6.211l4.365,6.473L10.455,6.3Z"
          transform="translate(99.723 118.838)"
        />
        <path
          className={classes.c}
          d="M6.09-.172-.16,6.211l4.365,6.473L10.455,6.3Z"
          transform="translate(99.723 118.838)"
        />
        <path
          className={classes.a}
          d="M11.8-.333-.309,12.036.821,13.713,12.932,1.343Z"
          transform="translate(99.871 113.013)"
        />
        <path
          className={classes.c}
          d="M11.8-.333-.309,12.036.821,13.713,12.932,1.343Z"
          transform="translate(99.871 113.013)"
        />
        <path
          className={classes.a}
          d="M12.591-.355-.33,12.841l.3.451L12.895.1Z"
          transform="translate(111.747 120.201)"
        />
        <path
          className={classes.c}
          d="M12.591-.355-.33,12.841l.3.451L12.895.1Z"
          transform="translate(111.747 120.201)"
        />
        <path
          className={classes.a}
          d="M.378-.011l-.388.4L8.837,13.5l.387-.4Z"
          transform="translate(120.371 124.28)"
        />
        <path
          className={classes.c}
          d="M.378-.011l-.388.4L8.837,13.5l.387-.4Z"
          transform="translate(120.371 124.28)"
        />
        <path
          className={classes.a}
          d="M.378-.011l-.387.4L8.837,13.5l.388-.4Z"
          transform="translate(107.16 137.765)"
        />
        <path
          className={classes.c}
          d="M.378-.011l-.387.4L8.837,13.5l.388-.4Z"
          transform="translate(107.16 137.765)"
        />
        <path
          className={classes.a}
          d="M12.591-.355-.33,12.841l.3.452L12.9.1Z"
          transform="translate(99.749 102.417)"
        />
        <path
          className={classes.c}
          d="M12.591-.355-.33,12.841l.3.452L12.9.1Z"
          transform="translate(99.749 102.417)"
        />
        <path
          className={classes.a}
          d="M8.232-.232-.216,8.4l.3.452L8.536.22Z"
          transform="translate(91.187 115.491)"
        />
        <path
          className={classes.c}
          d="M8.232-.232-.216,8.4l.3.452L8.536.22Z"
          transform="translate(91.187 115.491)"
        />
        <path
          className={classes.a}
          d="M8.226-.232-.216,8.4l.3.451L8.53.219Z"
          transform="translate(103.179 133.265)"
        />
        <path
          className={classes.c}
          d="M8.226-.232-.216,8.4l.3.451L8.53.219Z"
          transform="translate(103.179 133.265)"
        />
        <path
          className={classes.a}
          d="M8.232-.232-.216,8.4l.3.451L8.536.219Z"
          transform="translate(112.332 146.847)"
        />
        <path
          className={classes.c}
          d="M8.232-.232-.216,8.4l.3.451L8.536.219Z"
          transform="translate(112.332 146.847)"
        />
        <path
          className={classes.a}
          d="M12.591-.355-.33,12.841l.3.452L12.895.1Z"
          transform="translate(120.893 133.776)"
        />
        <path
          className={classes.c}
          d="M12.591-.355-.33,12.841l.3.452L12.895.1Z"
          transform="translate(120.893 133.776)"
        />
        <path
          className={classes.a}
          d="M14.41,14.589,4.51-.127-.119,4.617,12.388,23.175l1.328-1.355a5.978,5.978,0,0,0,.726-7.218"
          transform="translate(64.571 79.932)"
        />
        <path
          className={classes.c}
          d="M14.41,14.589,4.51-.127-.119,4.617,12.388,23.175l1.328-1.355A5.87,5.87,0,0,0,15.289,18.3a5.672,5.672,0,0,0-.879-3.709Z"
          transform="translate(64.571 79.932)"
        />
        <path
          className={classes.a}
          d="M2.807,3.731a1.739,1.739,0,0,1-.959.516A1.6,1.6,0,0,1,.135,3.186,2.569,2.569,0,0,1,.093,1.348,2.12,2.12,0,0,1,1.237.064a1.519,1.519,0,0,1,1.87.619,2.506,2.506,0,0,1,.151,2.379,2.3,2.3,0,0,1-.451.669"
          transform="translate(104.251 99.793)"
        />
        <path
          className={classes.c}
          d="M2.807,3.731a1.739,1.739,0,0,1-.959.516A1.6,1.6,0,0,1,.135,3.186,2.569,2.569,0,0,1,.093,1.348,2.12,2.12,0,0,1,1.237.064a1.519,1.519,0,0,1,1.87.619,2.506,2.506,0,0,1,.151,2.379,2.3,2.3,0,0,1-.451.669h0Z"
          transform="translate(104.251 99.793)"
        />
        <path
          className={classes.a}
          d="M3.805,5.051a2.192,2.192,0,0,1-2,.688A2.308,2.308,0,0,1,.183,4.318a3.477,3.477,0,0,1-.057-2.49A2.872,2.872,0,0,1,1.675.087,2.058,2.058,0,0,1,4.209.926a3.416,3.416,0,0,1-.4,4.124"
          transform="translate(118.661 132.268)"
        />
        <path
          className={classes.c}
          d="M3.805,5.051a2.192,2.192,0,0,1-2,.688A2.308,2.308,0,0,1,.183,4.318a3.477,3.477,0,0,1-.057-2.49A2.872,2.872,0,0,1,1.675.087,2.058,2.058,0,0,1,4.209.926a3.416,3.416,0,0,1-.4,4.124h0Z"
          transform="translate(118.661 132.268)"
        />
        <path
          className={classes.a}
          d="M1.18,1.559a.651.651,0,0,1-.824.135A.907.907,0,0,1-.018.823,1.015,1.015,0,0,1,.321.147.652.652,0,0,1,.952.01a.8.8,0,0,1,.5.666,1.065,1.065,0,0,1-.27.882"
          transform="translate(120.347 133.387)"
        />
        <path
          className={classes.c}
          d="M1.18,1.559a.651.651,0,0,1-.824.135A.907.907,0,0,1-.018.823,1.015,1.015,0,0,1,.321.147.652.652,0,0,1,.952.01a.8.8,0,0,1,.5.666,1.065,1.065,0,0,1-.27.882Z"
          transform="translate(120.347 133.387)"
        />
        <path
          className={classes.a}
          d="M.092,3.014a1.065,1.065,0,0,1,.13-1.276L1.805.118a.644.644,0,0,1,1,.128,1.058,1.058,0,0,1-.088,1.231L1.133,3.1A.65.65,0,0,1,.092,3.014"
          transform="translate(101.027 103.861)"
        />
        <path
          className={classes.c}
          d="M.092,3.014a1.065,1.065,0,0,1,.13-1.276L1.805.118a.644.644,0,0,1,1,.128,1.058,1.058,0,0,1-.088,1.231L1.133,3.1A.65.65,0,0,1,.092,3.014Z"
          transform="translate(101.027 103.861)"
        />
        <path
          className={classes.a}
          d="M16.841,26.26.134,1.479A1.06,1.06,0,0,1,.259.2.691.691,0,0,1,.806-.019.663.663,0,0,1,1.3.286L18.013,25.073a1.05,1.05,0,0,1,.1,1.028.865.865,0,0,1-.466.474.643.643,0,0,1-.8-.314"
          transform="translate(98.732 108.821)"
        />
        <path
          className={classes.c}
          d="M16.841,26.26.134,1.479A1.06,1.06,0,0,1,.259.2.691.691,0,0,1,.806-.019.663.663,0,0,1,1.3.286L18.013,25.073a1.05,1.05,0,0,1,.1,1.028.865.865,0,0,1-.466.474.643.643,0,0,1-.8-.314Z"
          transform="translate(98.732 108.821)"
        />
        <path
          className={classes.a}
          d="M5.223,6.933a3.008,3.008,0,0,1-2.747.944A3.168,3.168,0,0,1,.252,5.925,4.77,4.77,0,0,1,.174,2.507,3.938,3.938,0,0,1,2.3.119,2.823,2.823,0,0,1,5.78,1.275a4.691,4.691,0,0,1-.556,5.658"
          transform="translate(67.139 78.62)"
        />
        <path
          className={classes.c}
          d="M5.223,6.933a3.008,3.008,0,0,1-2.747.944A3.168,3.168,0,0,1,.252,5.925,4.77,4.77,0,0,1,.174,2.507,3.938,3.938,0,0,1,2.3.119,2.823,2.823,0,0,1,5.78,1.275a4.691,4.691,0,0,1-.556,5.658Z"
          transform="translate(67.139 78.62)"
        />
        <path
          className={classes.a}
          d="M4.38,5.816A2.415,2.415,0,0,1,1.319,6.3,2.876,2.876,0,0,1,.21,4.968,4.026,4.026,0,0,1,.578,1.237,2.788,2.788,0,0,1,2.736-.077,2.433,2.433,0,0,1,4.848,1.07a3.71,3.71,0,0,1,.573,2.438A3.841,3.841,0,0,1,4.38,5.816"
          transform="translate(67.867 77.064)"
        />
        <path
          className={classes.c}
          d="M4.38,5.816A2.415,2.415,0,0,1,1.319,6.3,2.876,2.876,0,0,1,.21,4.968,4.026,4.026,0,0,1,.578,1.237,2.788,2.788,0,0,1,2.736-.077,2.433,2.433,0,0,1,4.848,1.07a3.71,3.71,0,0,1,.573,2.438A3.841,3.841,0,0,1,4.38,5.816Z"
          transform="translate(67.867 77.064)"
        />
        <path
          className={classes.a}
          d="M1.761,2.335a1.013,1.013,0,0,1-.926.322A1.065,1.065,0,0,1,.085,2,1.615,1.615,0,0,1,.231.5,1.051,1.051,0,0,1,1.417.012a1.034,1.034,0,0,1,.529.412,1.536,1.536,0,0,1,.105,1.494A1.4,1.4,0,0,1,1.761,2.335Z"
          transform="translate(69.47 79.031)"
        />
        <path
          className={classes.c}
          d="M1.761,2.335a1.013,1.013,0,0,1-.926.322A1.065,1.065,0,0,1,.085,2,1.615,1.615,0,0,1,.231.5,1.051,1.051,0,0,1,1.417.012a1.034,1.034,0,0,1,.529.412,1.536,1.536,0,0,1,.105,1.494A1.4,1.4,0,0,1,1.761,2.335Z"
          transform="translate(69.47 79.031)"
        />
      </g>
    </svg>
  );
}
export default Satellite;
