import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import en_UK from 'i18n/en-UK.json';
import { loadState, saveState } from '../statePersist/localStore';
import { LANG_KEY } from '../../constants/constants';

const DEFAULT_LANGUAGE = 'en-UK';

export const LanguageContext = React.createContext<
  [string, Dispatch<SetStateAction<string>>]
>(['', () => {}]);
LanguageContext.displayName = 'LanguageContext';

function ConfiguredIntlProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [language, setLanguage] = useState<string>(
    loadState(LANG_KEY) || DEFAULT_LANGUAGE,
  );
  const [messages, setMessages] = useState(en_UK);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const nextMessages = await import(
          /* webpackChunkName: "translation" */ `i18n/${language}.json`
        );
        setMessages(nextMessages.default);
      } catch (error) {
        console.error('Dynamic lang messages load failure: ', error);
      }
      return null;
    }

    saveState(LANG_KEY, language);
    if (language !== DEFAULT_LANGUAGE) {
      fetchMessages();
    } else {
      setMessages(en_UK);
    }
  }, [language]);

  return (
    <IntlProvider locale={language} messages={{ ...en_UK, ...messages }}>
      <LanguageContext.Provider value={[language, setLanguage]}>
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
}

export default ConfiguredIntlProvider;
