import React from 'react';
import Typography, { Variant, Theme } from './Typography';
import { ContentTag } from './Element';

type Props = {
  children: React.ReactNode;
  variant?: Variant;
  theme?: Theme;
  className?: string;
  truncate?: boolean;
};

const Span = (props: Props) => <Typography {...props} type={ContentTag.SPAN} />;

export default Span;
