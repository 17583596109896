import { Redirect } from 'react-router-dom';
import React from 'react';
import { clearState } from '../../services/statePersist/localStore';
import { TOKEN_KEY, USER_KEY } from '../../constants/constants';

const clearAuthData = () => {
  clearState(TOKEN_KEY);
  clearState(USER_KEY);
};

function Logout(): React.ReactElement {
  clearAuthData();
  return (
    <Redirect to={{ pathname: '/login', search: window.location.search }} />
  );
}

export default Logout;
