import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SentryRoute } from 'services/sentry';
import Logout from './pages/Logout';
import Login from './pages/Login';
import ForgottenPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';

function AppRoutes(): React.ReactElement {
  return (
    <Switch>
      <SentryRoute path="/login">
        <Login />
      </SentryRoute>
      <SentryRoute path="/forgotten-password">
        <ForgottenPassword />
      </SentryRoute>
      <SentryRoute path="/set-password/:email/:token">
        <ResetPassword />
      </SentryRoute>
      <SentryRoute exact path="/logout">
        <Logout />
      </SentryRoute>
      <Route exact path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

export default AppRoutes;
