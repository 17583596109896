import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Label, Span, MediaQueries } from './index';
import { OpenEye, ClosedEye } from '../../components/icons';

export enum InputType {
  EMAIL = 'email',
  TEXT = 'text',
  PASSWORD = 'password',
}

type Props = {
  className?: string;
  type: InputType;
  name: string;
  defaultValue: string;
  label: string;
  onChange: (event: ChangeEvent) => void;
};

const INPUT_BACKGROUND_COLOR = '#fafafa';
const INPUT_BACKGROUND_COLOR_ACTIVE = '#f5fbfa';
const AUTOFILL_OVERRIDES =
  '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active';

const useStyles = createUseStyles({
  textInputRoot: {
    position: 'relative',
    cursor: 'text',
  },
  label: {
    position: 'absolute',
    top: 24,
    left: 20,
    color: '#6c6c6c',
    transition: 'all 0.2s linear',
  },
  labelActive: {
    fontSize: 12,
    top: 10,
  },
  input: {
    backgroundColor: INPUT_BACKGROUND_COLOR,
    padding: [30, 20, 12, 20],
    border: [2, 'solid', INPUT_BACKGROUND_COLOR],
    borderRadius: 5,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 14,
    color: '#05051b',
    transition: 'all 0.2s linear',
    [AUTOFILL_OVERRIDES]: {
      fontSize: 14,
    },
  },
  inputInFocus: {
    backgroundColor: INPUT_BACKGROUND_COLOR_ACTIVE,
    borderColor: '#1fd0bd',
  },
  inputInError: {
    borderColor: '#cc0027',
    backgroundColor: '#cc002750',
  },
  errorMessage: {
    display: 'block',
    marginTop: 4,
  },
  passwordRevealButton: {
    position: 'absolute',
    height: 'auto',
    width: 32,
    right: 24,
    bottom: 8,
    cursor: 'pointer',
  },
  [MediaQueries.DESKTOP]: {
    label: {
      top: 30,
    },
    labelActive: {
      fontSize: 14,
      top: 12,
    },
    input: {
      fontSize: 16,
      padding: [38, 20, 14, 20],
      [AUTOFILL_OVERRIDES]: {
        fontSize: 16,
      },
    },
  },
});

const isValidEmail = (value: string): boolean =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value,
  );

const TextInput = ({
  className = '',
  type,
  name,
  label,
  defaultValue,
  onChange,
}: Props) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);
  const [isFocus, setIsFocus] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputType, setInputType] = useState(type);

  const onInputChange = (event: any) => {
    setValue(event.target.value);
    if (type === InputType.EMAIL) {
      if (isValidEmail(event.target.value)) {
        setIsError(false);
        setErrorMessage('');
      } else {
        setIsError(true);
        setErrorMessage('Emails must be in the correct format');
      }
    }
    onChange(event);
  };

  const toggleInputType = () =>
    setInputType(
      inputType === InputType.PASSWORD ? InputType.TEXT : InputType.PASSWORD,
    );

  return (
    <div className={classNames(classes.textInputRoot, className)}>
      <Label>
        <span
          className={classNames(classes.label, {
            [classes.labelActive]: value || isFocus,
          })}
        >
          {label}
        </span>
        <input
          className={classNames(
            classes.input,
            { [classes.inputInFocus]: isFocus && !isError },
            { [classes.inputInError]: isError },
          )}
          type={inputType}
          name={name}
          defaultValue={defaultValue}
          onChange={onInputChange}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </Label>
      {type === InputType.PASSWORD && (
        <button
          className={classes.passwordRevealButton}
          onClick={toggleInputType}
          type="button"
        >
          {inputType === InputType.PASSWORD ? <ClosedEye /> : <OpenEye />}
        </button>
      )}
      {isError && (
        <Span className={classes.errorMessage} theme="error">
          {errorMessage}
        </Span>
      )}
    </div>
  );
};

export default TextInput;
