/**
 * Functions for common action methods on the Intercom object.
 * See documentation: https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 * Does not implement all documented action methods
 * Actions are methods not requiring a callback. See events for those
 */

export type IntercomActions = 'shutdown' | 'hide' | 'show' | 'update';

export type IntercomAvatar = {
  type: 'avatar';
  /** An avatar image URL. Note: needs to be https  */
  image_url: 'string';
};

export type IntercomCompany = {
  id?: string;
  name?: string;
  created_at?: string;
  plan?: string;
  monthly_spend?: string;
  user_count?: number;
  size?: number;
  website?: string;
  industry?: string;
};

export interface IntercomDataOptions {
  email?: string;
  user_id?: string;
  created_at?: string;
  name?: string;
  phone?: string;
  last_request_at?: string;
  unsubscribed_from_emails?: boolean;
  language_override?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  avatar?: IntercomAvatar;
  user_hash?: string;
  company?: IntercomCompany;
  companies?: IntercomCompany[];
}

export function intercomAction(
  action: IntercomActions,
  /** Use with `update` */
  dataOptions?: IntercomDataOptions,
): void {
  if (window.Intercom) {
    window.Intercom(action, dataOptions);
  }
}
