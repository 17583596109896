import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import MediaQueries from './MediaQueries';

export type Variant = 'normal' | 'small';
type Target = '_blank' | '_self';

type Props = {
  to?: string;
  href?: string;
  children: ReactNode;
  className?: string;
  variant?: Variant;
  target?: Target;
  rel?: string;
  noOpener?: string;
};

export const useStyles = createUseStyles({
  squareLinkRoot: {
    color: '#24b9a9',
    backgroundColor: '#fff',
    lineHeight: '35px',
    display: 'inline-block',
    padding: [0, 5],
    margin: [0, 5],
    transition: 'background-color 0.2s linear',
    borderRadius: 5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5fbfa',
    },
  },
  // variants
  normal: {
    lineHeight: '35px',
  },
  small: {
    lineHeight: '32px',
  },
  [MediaQueries.DESKTOP]: {
    squareLinkRoot: {
      padding: [0, 10],
      margin: [0, 10],
    },
  },
});

const SquareLink = ({
  to,
  href = '',
  children,
  className = '',
  variant = 'normal',
  target = '_self',
  rel = '',
  noOpener = 'false',
}: Props) => {
  const classes = useStyles();

  const combinedClassnames = classNames(
    classes.squareLinkRoot,
    classes[variant],
    className,
  );

  return to ? (
    <Link to={to} className={combinedClassnames}>
      {children}
    </Link>
  ) : (
    <a
      target={target}
      rel={rel}
      no-opener={noOpener}
      href={href}
      className={combinedClassnames}
    >
      {children}
    </a>
  );
};

export default SquareLink;
