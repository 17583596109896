import React from 'react';
import { createUseStyles } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import {
  ConfigProvider,
  AppEnvironment,
} from '@hummingbirdtechgroup/wings-config';
import { UnitConversionProvider } from '@hummingbirdtechgroup/wings-unit-conversion';
import { ConfiguredIntlProvider } from 'services/i18n';
import { SentryScope } from 'services/sentry';
import { useIntercomMessenger } from 'services/intercomMessenger';
import AppRoutes from './AppRoutes';
import EmailProvider from './services/email/EmailProvider';
import { fontStyles, resetStyles } from './styles';
import GoogleAnalytics from './libraries/analytics/GoogleAnalytics';

const useStyles = createUseStyles(() => ({
  '@global': {
    ...fontStyles,
    ...resetStyles,
  },
}));

function App() {
  useIntercomMessenger();
  useStyles();

  const nodeEnv = process.env.NODE_ENV;

  const appEnv: AppEnvironment =
    nodeEnv === 'production' ? 'production' : 'staging';

  return (
    <ConfigProvider env={appEnv}>
      <>
        <AppRoutes />
        {nodeEnv === 'production' && (
          <>
            <SentryScope />
            <GoogleAnalytics />
          </>
        )}
      </>
    </ConfigProvider>
  );
}

function AppWithProviders(): React.ReactElement {
  return (
    <BrowserRouter>
      <ConfiguredIntlProvider>
        <UnitConversionProvider>
          <EmailProvider>
            <App />
          </EmailProvider>
        </UnitConversionProvider>
      </ConfiguredIntlProvider>
    </BrowserRouter>
  );
}

export default AppWithProviders;
