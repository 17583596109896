import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { MediaQueries } from '../../libraries/ui';
import getRedirectSearchParam from '../../util/getRedirectSearchParam';
import isLegacyUrl from '../../util/isLegacyUrl';
import isFreshUrl from '../../util/isFreshUrl';
import { HummingbirdLogo } from '../icons';
import LanguageSwitcher from '../LanguageSwitcher';
import MeasurementSystemSwitcher from '../MeasurementSystemSwitcher';
import IconList from './IconList';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const HEADER_HEIGHT_DESKTOP = 75;

const useStyles = createUseStyles({
  accountLayoutRoot: {
    padding: [0, 16],
  },
  header: {},
  headerContent: {},
  logo: {
    width: 'auto',
    height: 45,
    marginTop: 16,
  },
  switcherContainer: {
    marginTop: 8,
  },
  switcher: {
    marginTop: 8,
  },
  pageContent: {},
  background: {},
  icon: {},
  satellite: {},
  [MediaQueries.TABLET]: {
    accountLayoutRoot: {
      position: 'relative',
      padding: 0,
      background:
        'linear-gradient(to right, #fff 0%,#fff 50%,#fff 50%,#fafafa 50%,#fafafa 100%)',
    },
    header: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 100,
    },
    headerContent: {
      maxWidth: 1366,
      margin: [0, 'auto'],
      padding: [0, 50],
      height: HEADER_HEIGHT_DESKTOP,
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
    },
    switcherContainer: {
      width: 158,
      marginTop: 18,
    },
    switcher: {
      marginTop: 10,
    },
    pageContent: {
      padding: [HEADER_HEIGHT_DESKTOP, 110, 0, 140],
      boxSizing: 'border-box',
      backgroundColor: 'white',
      maxWidth: 784,
      minHeight: '100vh',
    },
    background: {
      maxWidth: 1366,
      margin: [0, 'auto'],
    },
  },
});

function AccountLayout({
  children,
  className = '',
}: Props): React.ReactElement {
  const classes = useStyles();
  const redirectUrl = getRedirectSearchParam();

  return (
    <div className={classNames(classes.accountLayoutRoot, className)}>
      <header className={classes.header}>
        <div className={classes.headerContent}>
          <a href="https://hummingbirdtech.com/">
            <HummingbirdLogo className={classes.logo} />
          </a>
          <div className={classes.switcherContainer}>
            {isLegacyUrl(redirectUrl) && (
              <LanguageSwitcher className={classes.switcher} />
            )}
            {isFreshUrl(redirectUrl) && (
              <MeasurementSystemSwitcher className={classes.switcher} />
            )}
          </div>
        </div>
      </header>
      <div className={classes.background}>
        <div className={classes.pageContent}>{children}</div>
        <IconList />
      </div>
    </div>
  );
}

export default AccountLayout;
