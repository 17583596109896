import React, { FormEvent, useContext, useState } from 'react';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import resetPassword from 'services/api/resetPassword';
import { EmailContext } from 'services/email/EmailProvider';
import { AccountLayout } from 'components';
import { Button, H1, MediaQueries, P } from '../../libraries/ui';
import TextInput, { InputType } from '../../libraries/ui/TextInput';

const useStyles = createUseStyles({
  pageTitle: {
    marginTop: 32,
  },
  form: {
    marginTop: 20,
    overflow: 'hidden',
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  [MediaQueries.DESKTOP]: {
    pageTitle: {
      marginTop: 136,
    },
  },
});

function ForgottenPassword() {
  const intl = useIntl();
  const [email, setEmail] = useContext(EmailContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const reset = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetPassword(email)
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  return (
    <AccountLayout>
      <H1 className={classes.pageTitle} variant="jumbo" theme="bold">
        <FormattedMessage
          id="forgottenPassword.resetPassword"
          defaultMessage="Reset Password"
        />
      </H1>
      <form className={classes.form} onSubmit={reset}>
        {error && (
          <P theme="error">
            <FormattedMessage
              id="forgottenPassword.errorMessage"
              defaultMessage="There was a problem resetting the password, please try again."
            />
          </P>
        )}
        {success && (
          <P theme="success">
            <FormattedMessage
              id="forgottenPassword.successHeading"
              defaultMessage="We've successfully processed your request."
            />
            <FormattedMessage
              id="forgottenPassword.successDescription"
              defaultMessage="You will shortly receive a link to reset your password to your email."
            />
          </P>
        )}
        <TextInput
          label={intl.formatMessage({
            id: 'forgottenPassword.emailPlaceholder',
            defaultMessage: 'Email address',
          })}
          className={classes.input}
          name="email"
          type={InputType.EMAIL}
          defaultValue={email}
          onChange={(event: any) => setEmail(event.target.value)}
        />

        <Button
          className={classes.button}
          disabled={!email.length}
          type="submit"
        >
          <FormattedMessage
            id="forgottenPassword.submitButtonLabel"
            defaultMessage="Reset password"
          />
        </Button>
      </form>
    </AccountLayout>
  );
}

export default ForgottenPassword;
