import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  a: {
    fill: '#6c6c6c',
    transition: 'fill 0.2s linear',
    '&:hover': {
      fill: '#24b9a9',
    },
  },
});

type Props = {
  className?: string;
};

function OpenEye({ className = '' }: Props) {
  const classes = useStyles();

  return (
    <svg className={className} viewBox="0 0 32 24">
      <path
        className={classes.a}
        d="M18,7.383A16.936,16.936,0,0,0,2.25,18a16.991,16.991,0,0,0,31.5,0A16.936,16.936,0,0,0,18,7.383Zm0,17.7A7.081,7.081,0,1,1,25.158,18,7.139,7.139,0,0,1,18,25.08Zm0-11.327A4.247,4.247,0,1,0,22.3,18,4.285,4.285,0,0,0,18,13.753Z"
        transform="translate(-2.25 -7.383)"
      />
    </svg>
  );
}

export default OpenEye;
