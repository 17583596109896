import getUnixTime from 'date-fns/getUnixTime';

const getPayload = (/** A JWT token */ token: string) => {
  const payload = token.split('.')[1];

  return JSON.parse(atob(payload));
};

/**
 * Checks if a users JWT token has expired.
 */
export default (/** A JWT token */ token: string): boolean => {
  if (!token) return true;

  let exp;
  try {
    exp = getPayload(token).exp;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('The JWT token is not in the expected format', error);
    return true;
  }

  const now = getUnixTime(new Date());

  const hasExpired = exp < now;
  return hasExpired;
};
