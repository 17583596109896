/* eslint-disable  react/destructuring-assignment */

const EMAIL_PATTERN = /@[a-z0-9-.\][:]+\./g;

export default (email: string): string | null => {
  if (email && email.toLowerCase().match(EMAIL_PATTERN)) {
    const domains = email.split('@');
    const company = domains[1].split('.')[0];
    return company;
  }
  return null;
};
