import React from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  className?: string;
};

const useStyles = createUseStyles({
  a: {
    fill: '#fff',
  },
  b: {
    fill: 'none',
    stroke: '#05051b',
    strokeMiterlimit: 10,
    strokeWidth: '1.07px',
  },
});

function Plane({ className = '' }: Props) {
  const classes = useStyles();

  return (
    <svg
      className={className}
      width="195.02"
      height="157.171"
      viewBox="0 0 195.02 157.171"
    >
      <g transform="matrix(-0.966, -0.259, 0.259, -0.966, 164.317, 156.496)">
        <path
          className={classes.a}
          d="M14.309,0,13.021,9.792,11.232,23.077,0,28.341,1.263,5.672"
          transform="translate(32.321 105.911)"
        />
        <path
          className={classes.b}
          d="M14.309,0,13.021,9.792,11.232,23.077,0,28.341,1.263,5.672"
          transform="translate(32.321 105.911)"
        />
        <path
          className={classes.a}
          d="M14.309,0,13.021,9.792,11.232,23.077,0,28.341,1.263,5.672"
          transform="translate(32.321 105.911)"
        />
        <path
          className={classes.b}
          d="M14.309,0,13.021,9.792,11.232,23.077,0,28.341,1.263,5.672"
          transform="translate(32.321 105.911)"
        />
        <path
          className={classes.a}
          d="M20.717,31.961s8.367-4.426,16.955-10.08S61.882,5.917,64.035,4.027Q65.921,2.365,67.926.848L87.3,0,80.031,10.462,41.147,29.1l-11.02,5.256-.924,1.7-8.477-4.069L15.419,40.76l-1.5.628c-2.8,1.153-8.7,3.7-10.529,4.714C.958,47.475,0,47.263,0,46.576s1.568-2.619,4.238-4.239S11.4,37.861,11.4,37.861"
          transform="translate(30.152 69.151)"
        />
        <path
          className={classes.b}
          d="M20.717,31.961s8.367-4.426,16.955-10.08S61.882,5.917,64.035,4.027Q65.921,2.365,67.926.848L87.3,0,80.031,10.462,41.147,29.1l-11.02,5.256-.924,1.7-8.477-4.069L15.419,40.76l-1.5.628c-2.8,1.153-8.7,3.7-10.529,4.714C.958,47.475,0,47.263,0,46.576s1.568-2.619,4.238-4.239S11.4,37.861,11.4,37.861"
          transform="translate(30.152 69.151)"
        />
        <path
          className={classes.a}
          d="M20.717,31.961s8.367-4.426,16.955-10.08S61.882,5.917,64.035,4.027Q65.921,2.365,67.926.848L87.3,0,80.031,10.462,41.147,29.1l-11.02,5.256-.924,1.7-8.477-4.069L15.419,40.76l-1.5.628c-2.8,1.153-8.7,3.7-10.529,4.714C.958,47.475,0,47.263,0,46.576s1.568-2.619,4.238-4.239S11.4,37.861,11.4,37.861"
          transform="translate(30.152 69.151)"
        />
        <path
          className={classes.b}
          d="M20.717,31.961s8.367-4.426,16.955-10.08S61.882,5.917,64.035,4.027Q65.921,2.365,67.926.848L87.3,0,80.031,10.462,41.147,29.1l-11.02,5.256-.924,1.7-8.477-4.069L15.419,40.76l-1.5.628c-2.8,1.153-8.7,3.7-10.529,4.714C.958,47.475,0,47.263,0,46.576s1.568-2.619,4.238-4.239S11.4,37.861,11.4,37.861"
          transform="translate(30.152 69.151)"
        />
        <path
          className={classes.a}
          d="M99.018,76.215s1.193-.551-.26-1.727-12.118-8.3-12.118-8.3A31.9,31.9,0,0,1,81.661,67.7c-1.161.065-2.085-.414-1.461-1.176s2.6-1.8,2.662-2.1,0-.494-1.058-1.328S48.268,39.974,43.5,36.593,15.531,16.467,13.849,15.219,2,6.4.8,5.235c0,0-1.352-2.488-.538-3.482S5.68-.721,10.689.4,38.394,11.025,50.014,17.512,77.04,33.1,83.518,37.587l19.449,13.466s10.7-5.8,14.22-8.053a23.56,23.56,0,0,1,9.535-3.279c1.074.051,1.58,0,.308,2.017A27.044,27.044,0,0,1,120.861,48c-2.37,1.778-6,3.9-6,3.9s1.841.936,3.46,1.8a26.766,26.766,0,0,1,4.108,3.186,146.986,146.986,0,0,1,14.371-6.16c6.066-2.053,13.05-4.418,15.673-4.658s3.95.174,4.029,1.335-1.762,3.061-4.037,5.6-6.32,6.087-6.747,6.631a19.122,19.122,0,0,1-1.375,1.451s.679.987,2.994.1q2.7-1.093,5.262-2.438s-3.847,2.648-5.309,3.519-2.5,1.69-4.068,1.894a3.82,3.82,0,0,1-2.52-.458l-10.776,7.93s4.858,3.279,7.181,5.231,3.01,3.025,3.413,2.9,8.263-4.353,8.263-4.353,6.162-3.534,9.133-4.3,5.111-1.343,4.566,0-1.193,2.6-5.341,5.376-12.34,6.965-12.34,6.965,9.322,8.111,13.09,11.906,9.306,10.23,10.42,12.914,1.58,5.564.245,6.711-2.686,1.226-4.59.595S144.6,105.106,137.506,100.44s-13.556-8.706-13.556-8.706-3.39,1.509-4.369,1.349-1.786-.551-.861-1.451a20.047,20.047,0,0,1,2.37-1.865s-10.033-7.2-10.72-7.509-.687-.3-2.006.065-24.593,9.628-29.42,11.427-18.193,6.937-18.533,7.662-.861,1.284-.063,1.669,7.063,4.267,8.611,5.34,4.234,4.041,2.812,5.2-3.16,2.075-6.478.725-17.68-7.764-20.43-9.069-9.9-4.194-10.523-5.485-1.5-2.177,2.994-2.634,13.09,1.451,17.1,3.265,5.981,2.655,5.981,2.655"
          transform="translate(0 0)"
        />
        <path
          className={classes.b}
          d="M99.018,76.215s1.193-.551-.26-1.727-12.118-8.3-12.118-8.3A31.9,31.9,0,0,1,81.661,67.7c-1.161.065-2.085-.414-1.461-1.176s2.6-1.8,2.662-2.1,0-.494-1.058-1.328S48.268,39.974,43.5,36.593,15.531,16.467,13.849,15.219,2,6.4.8,5.235c0,0-1.352-2.488-.538-3.482S5.68-.721,10.689.4,38.394,11.025,50.014,17.512,77.04,33.1,83.518,37.587l19.449,13.466s10.7-5.8,14.22-8.053a23.56,23.56,0,0,1,9.535-3.279c1.074.051,1.58,0,.308,2.017A27.044,27.044,0,0,1,120.861,48c-2.37,1.778-6,3.9-6,3.9s1.841.936,3.46,1.8a26.766,26.766,0,0,1,4.108,3.186,146.986,146.986,0,0,1,14.371-6.16c6.066-2.053,13.05-4.418,15.673-4.658s3.95.174,4.029,1.335-1.762,3.061-4.037,5.6-6.32,6.087-6.747,6.631a19.122,19.122,0,0,1-1.375,1.451s.679.987,2.994.1q2.7-1.093,5.262-2.438s-3.847,2.648-5.309,3.519-2.5,1.69-4.068,1.894a3.82,3.82,0,0,1-2.52-.458l-10.776,7.93s4.858,3.279,7.181,5.231,3.01,3.025,3.413,2.9,8.263-4.353,8.263-4.353,6.162-3.534,9.133-4.3,5.111-1.343,4.566,0-1.193,2.6-5.341,5.376-12.34,6.965-12.34,6.965,9.322,8.111,13.09,11.906,9.306,10.23,10.42,12.914,1.58,5.564.245,6.711-2.686,1.226-4.59.595S144.6,105.106,137.506,100.44s-13.556-8.706-13.556-8.706-3.39,1.509-4.369,1.349-1.786-.551-.861-1.451a20.047,20.047,0,0,1,2.37-1.865s-10.033-7.2-10.72-7.509-.687-.3-2.006.065-24.593,9.628-29.42,11.427-18.193,6.937-18.533,7.662-.861,1.284-.063,1.669,7.063,4.267,8.611,5.34,4.234,4.041,2.812,5.2-3.16,2.075-6.478.725-17.68-7.764-20.43-9.069-9.9-4.194-10.523-5.485-1.5-2.177,2.994-2.634,13.09,1.451,17.1,3.265,5.981,2.655,5.981,2.655"
          transform="translate(0 0)"
        />
        <path
          className={classes.a}
          d="M99.018,76.215s1.193-.551-.26-1.727-12.118-8.3-12.118-8.3A31.9,31.9,0,0,1,81.661,67.7c-1.161.065-2.085-.414-1.461-1.176s2.6-1.8,2.662-2.1,0-.494-1.058-1.328S48.268,39.974,43.5,36.593,15.531,16.467,13.849,15.219,2,6.4.8,5.235c0,0-1.352-2.488-.538-3.482S5.68-.721,10.689.4,38.394,11.025,50.014,17.512,77.04,33.1,83.518,37.587l19.449,13.466s10.7-5.8,14.22-8.053a23.56,23.56,0,0,1,9.535-3.279c1.074.051,1.58,0,.308,2.017A27.044,27.044,0,0,1,120.861,48c-2.37,1.778-6,3.9-6,3.9s1.841.936,3.46,1.8a26.766,26.766,0,0,1,4.108,3.186,146.986,146.986,0,0,1,14.371-6.16c6.066-2.053,13.05-4.418,15.673-4.658s3.95.174,4.029,1.335-1.762,3.061-4.037,5.6-6.32,6.087-6.747,6.631a19.122,19.122,0,0,1-1.375,1.451s.679.987,2.994.1q2.7-1.093,5.262-2.438s-3.847,2.648-5.309,3.519-2.5,1.69-4.068,1.894a3.82,3.82,0,0,1-2.52-.458l-10.776,7.93s4.858,3.279,7.181,5.231,3.01,3.025,3.413,2.9,8.263-4.353,8.263-4.353,6.162-3.534,9.133-4.3,5.111-1.343,4.566,0-1.193,2.6-5.341,5.376-12.34,6.965-12.34,6.965,9.322,8.111,13.09,11.906,9.306,10.23,10.42,12.914,1.58,5.564.245,6.711-2.686,1.226-4.59.595S144.6,105.106,137.506,100.44s-13.556-8.706-13.556-8.706-3.39,1.509-4.369,1.349-1.786-.551-.861-1.451a20.047,20.047,0,0,1,2.37-1.865s-10.033-7.2-10.72-7.509-.687-.3-2.006.065-24.593,9.628-29.42,11.427-18.193,6.937-18.533,7.662-.861,1.284-.063,1.669,7.063,4.267,8.611,5.34,4.234,4.041,2.812,5.2-3.16,2.075-6.478.725-17.68-7.764-20.43-9.069-9.9-4.194-10.523-5.485-1.5-2.177,2.994-2.634,13.09,1.451,17.1,3.265,5.981,2.655,5.981,2.655"
          transform="translate(0 0)"
        />
        <path
          className={classes.b}
          d="M99.018,76.215s1.193-.551-.26-1.727-12.118-8.3-12.118-8.3A31.9,31.9,0,0,1,81.661,67.7c-1.161.065-2.085-.414-1.461-1.176s2.6-1.8,2.662-2.1,0-.494-1.058-1.328S48.268,39.974,43.5,36.593,15.531,16.467,13.849,15.219,2,6.4.8,5.235c0,0-1.352-2.488-.538-3.482S5.68-.721,10.689.4,38.394,11.025,50.014,17.512,77.04,33.1,83.518,37.587l19.449,13.466s10.7-5.8,14.22-8.053a23.56,23.56,0,0,1,9.535-3.279c1.074.051,1.58,0,.308,2.017A27.044,27.044,0,0,1,120.861,48c-2.37,1.778-6,3.9-6,3.9s1.841.936,3.46,1.8a26.766,26.766,0,0,1,4.108,3.186,146.986,146.986,0,0,1,14.371-6.16c6.066-2.053,13.05-4.418,15.673-4.658s3.95.174,4.029,1.335-1.762,3.061-4.037,5.6-6.32,6.087-6.747,6.631a19.122,19.122,0,0,1-1.375,1.451s.679.987,2.994.1q2.7-1.093,5.262-2.438s-3.847,2.648-5.309,3.519-2.5,1.69-4.068,1.894a3.82,3.82,0,0,1-2.52-.458l-10.776,7.93s4.858,3.279,7.181,5.231,3.01,3.025,3.413,2.9,8.263-4.353,8.263-4.353,6.162-3.534,9.133-4.3,5.111-1.343,4.566,0-1.193,2.6-5.341,5.376-12.34,6.965-12.34,6.965,9.322,8.111,13.09,11.906,9.306,10.23,10.42,12.914,1.58,5.564.245,6.711-2.686,1.226-4.59.595S144.6,105.106,137.506,100.44s-13.556-8.706-13.556-8.706-3.39,1.509-4.369,1.349-1.786-.551-.861-1.451a20.047,20.047,0,0,1,2.37-1.865s-10.033-7.2-10.72-7.509-.687-.3-2.006.065-24.593,9.628-29.42,11.427-18.193,6.937-18.533,7.662-.861,1.284-.063,1.669,7.063,4.267,8.611,5.34,4.234,4.041,2.812,5.2-3.16,2.075-6.478.725-17.68-7.764-20.43-9.069-9.9-4.194-10.523-5.485-1.5-2.177,2.994-2.634,13.09,1.451,17.1,3.265,5.981,2.655,5.981,2.655"
          transform="translate(0 0)"
        />
        <path
          className={classes.a}
          d="M13.691,15.074S5.544,8.232,3.467,6.468,0,3.688,0,3.688L6.443,0"
          transform="translate(108.221 52.275)"
        />
        <path
          className={classes.b}
          d="M13.691,15.074S5.544,8.232,3.467,6.468,0,3.688,0,3.688L6.443,0"
          transform="translate(108.221 52.275)"
        />
        <path
          className={classes.a}
          d="M13.691,15.074S5.544,8.232,3.467,6.468,0,3.688,0,3.688L6.443,0"
          transform="translate(108.221 52.275)"
        />
        <path
          className={classes.b}
          d="M13.691,15.074S5.544,8.232,3.467,6.468,0,3.688,0,3.688L6.443,0"
          transform="translate(108.221 52.275)"
        />
        <path
          className={classes.a}
          d="M0,13.013s11.97-5.8,16.3-8.232A83.014,83.014,0,0,0,23.854,0"
          transform="translate(85.683 55.582)"
        />
        <path
          className={classes.b}
          d="M0,13.013s11.97-5.8,16.3-8.232A83.014,83.014,0,0,0,23.854,0"
          transform="translate(85.683 55.582)"
        />
        <path
          className={classes.a}
          d="M0,13.013s11.97-5.8,16.3-8.232A83.014,83.014,0,0,0,23.854,0"
          transform="translate(85.683 55.582)"
        />
        <path
          className={classes.b}
          d="M0,13.013s11.97-5.8,16.3-8.232A83.014,83.014,0,0,0,23.854,0"
          transform="translate(85.683 55.582)"
        />
        <path
          className={classes.a}
          d="M0,15.633S13.368,8.486,16.378,6.308s5.145-3.959,5.391-4.679A2.547,2.547,0,0,0,21.574,0"
          transform="translate(82.036 51.388)"
        />
        <path
          className={classes.b}
          d="M0,15.633S13.368,8.486,16.378,6.308s5.145-3.959,5.391-4.679A2.547,2.547,0,0,0,21.574,0"
          transform="translate(82.036 51.388)"
        />
        <path
          className={classes.a}
          d="M0,15.633S13.368,8.486,16.378,6.308s5.145-3.959,5.391-4.679A2.547,2.547,0,0,0,21.574,0"
          transform="translate(82.036 51.388)"
        />
        <path
          className={classes.b}
          d="M0,15.633S13.368,8.486,16.378,6.308s5.145-3.959,5.391-4.679A2.547,2.547,0,0,0,21.574,0"
          transform="translate(82.036 51.388)"
        />
        <path
          className={classes.a}
          d="M0,12.174C.686,12.42,15.538,4.9,21.65,0Z"
          transform="translate(108.646 72.393)"
        />
        <path
          className={classes.b}
          d="M0,12.174C.686,12.42,15.538,4.9,21.65,0"
          transform="translate(108.646 72.393)"
        />
        <path
          className={classes.a}
          d="M3.12,0,9.512,5.689ZM13.614,4.468,5.544,7.638ZM0,33.919c.687.246,15.539-7.274,21.651-12.174Z"
          transform="translate(108.669 52.133)"
        />
        <path
          className={classes.b}
          d="M0,33.919c.687.246,15.539-7.274,21.651-12.174M3.12,0,9.512,5.689ZM13.614,4.468,5.544,7.638Z"
          transform="translate(108.669 52.133)"
        />
        <path
          className={classes.a}
          d="M0,10.292s13.257-4.714,17.4-6.9A63.06,63.06,0,0,0,23.294,0"
          transform="translate(120.632 83.97)"
        />
        <path
          className={classes.b}
          d="M0,10.292s13.257-4.714,17.4-6.9A63.06,63.06,0,0,0,23.294,0"
          transform="translate(120.632 83.97)"
        />
        <path
          className={classes.a}
          d="M0,10.292s13.257-4.714,17.4-6.9A63.06,63.06,0,0,0,23.294,0"
          transform="translate(120.632 83.97)"
        />
        <path
          className={classes.b}
          d="M0,10.292s13.257-4.714,17.4-6.9A63.06,63.06,0,0,0,23.294,0"
          transform="translate(120.632 83.97)"
        />
        <path
          className={classes.a}
          d="M0,11.716S9.63,8.071,14.233,5.443A22.143,22.143,0,0,0,20.836,0"
          transform="translate(119.944 80.631)"
        />
        <path
          className={classes.b}
          d="M0,11.716S9.63,8.071,14.233,5.443A22.143,22.143,0,0,0,20.836,0"
          transform="translate(119.944 80.631)"
        />
        <path
          className={classes.a}
          d="M0,11.716S9.63,8.071,14.233,5.443A22.143,22.143,0,0,0,20.836,0"
          transform="translate(119.944 80.631)"
        />
        <path
          className={classes.b}
          d="M0,11.716S9.63,8.071,14.233,5.443A22.143,22.143,0,0,0,20.836,0"
          transform="translate(119.944 80.631)"
        />
        <path
          className={classes.a}
          d="M0,0S1.925,4.993,4.179,4.8s10.766-3,12.343-3.112a10.348,10.348,0,0,1,4.782.7c.873.551,2.178,1.7,1.441,2.543"
          transform="translate(120.529 57.199)"
        />
        <path
          className={classes.b}
          d="M0,0S1.925,4.993,4.179,4.8s10.766-3,12.343-3.112a10.348,10.348,0,0,1,4.782.7c.873.551,2.178,1.7,1.441,2.543"
          transform="translate(120.529 57.199)"
        />
        <path
          className={classes.a}
          d="M0,0S1.925,4.993,4.179,4.8s10.766-3,12.343-3.112a10.348,10.348,0,0,1,4.782.7c.873.551,2.178,1.7,1.441,2.543"
          transform="translate(120.529 57.199)"
        />
        <path
          className={classes.b}
          d="M0,0S1.925,4.993,4.179,4.8s10.766-3,12.343-3.112a10.348,10.348,0,0,1,4.782.7c.873.551,2.178,1.7,1.441,2.543"
          transform="translate(120.529 57.199)"
        />
        <path
          className={classes.a}
          d="M19.55.767a12.251,12.251,0,0,0-1.017,1.289c-.518.755-.22,1.28-5.162,4.239S.655,14.595.214,14.188s-.237-1.7.958-2.925S13.743.963,14.871.513,17.549.276,18.261.038s1.3.7,1.3.7"
          transform="translate(106.115 41.049)"
        />
        <path
          className={classes.b}
          d="M19.55.767a12.251,12.251,0,0,0-1.017,1.289c-.518.755-.22,1.28-5.162,4.239S.655,14.595.214,14.188s-.237-1.7.958-2.925S13.743.963,14.871.513,17.549.276,18.261.038s1.3.7,1.3.7"
          transform="translate(106.115 41.049)"
        />
        <path
          className={classes.a}
          d="M19.55.767a12.251,12.251,0,0,0-1.017,1.289c-.518.755-.22,1.28-5.162,4.239S.655,14.595.214,14.188s-.237-1.7.958-2.925S13.743.963,14.871.513,17.549.276,18.261.038s1.3.7,1.3.7"
          transform="translate(106.115 41.049)"
        />
        <path
          className={classes.b}
          d="M19.55.767a12.251,12.251,0,0,0-1.017,1.289c-.518.755-.22,1.28-5.162,4.239S.655,14.594.214,14.187s-.237-1.7.958-2.925S13.743.962,14.871.512,17.549.275,18.261.038,19.55.767,19.55.767Z"
          transform="translate(106.115 41.05)"
        />
        <path
          className={classes.a}
          d="M2.277,8.06S-.58,10.73.106,10.747s3.035-.3,6.782-2.145A107.708,107.708,0,0,0,18.053,1.947c.848-.687.415-2.043.059-1.942L16.476.464Z"
          transform="translate(140.598 71.515)"
        />
        <path
          className={classes.b}
          d="M2.277,8.06S-.58,10.73.106,10.747s3.035-.3,6.782-2.145A107.708,107.708,0,0,0,18.053,1.947c.848-.687.415-2.043.059-1.942L16.476.464Z"
          transform="translate(140.598 71.515)"
        />
        <path
          className={classes.a}
          d="M2.277,8.06S-.58,10.73.106,10.747s3.035-.3,6.782-2.145A107.708,107.708,0,0,0,18.053,1.947c.848-.687.415-2.043.059-1.942L16.476.464Z"
          transform="translate(140.598 71.515)"
        />
        <path
          className={classes.b}
          d="M2.277,8.06S-.58,10.73.106,10.747s3.035-.3,6.782-2.145A107.708,107.708,0,0,0,18.053,1.947c.848-.687.415-2.043.059-1.942L16.476.464Z"
          transform="translate(140.598 71.515)"
        />
        <path
          className={classes.a}
          d="M.231,11.4s.66-1.815,5.255-4.858a57.62,57.62,0,0,1,9.68-5.01L16.319,0a5.6,5.6,0,0,0,1.229,2.221,4.663,4.663,0,0,0,2.281.848s-.484,1.441-4.29,3.569S3.621,11.945,2.485,11.987-.685,12.716.23,11.4"
          transform="translate(30.392 125.011)"
        />
        <path
          className={classes.b}
          d="M.231,11.4s.66-1.815,5.255-4.858a57.62,57.62,0,0,1,9.68-5.01L16.319,0a5.6,5.6,0,0,0,1.229,2.221,4.663,4.663,0,0,0,2.281.848s-.484,1.441-4.29,3.569S3.621,11.945,2.485,11.987-.685,12.716.23,11.4"
          transform="translate(30.392 125.011)"
        />
        <path
          className={classes.a}
          d="M.231,11.4s.66-1.815,5.255-4.858a57.62,57.62,0,0,1,9.68-5.01L16.319,0a5.6,5.6,0,0,0,1.229,2.221,4.663,4.663,0,0,0,2.281.848s-.484,1.441-4.29,3.569S3.621,11.945,2.485,11.987-.685,12.716.23,11.4"
          transform="translate(30.392 125.011)"
        />
        <path
          className={classes.b}
          d="M.231,11.4s.66-1.815,5.255-4.858a57.62,57.62,0,0,1,9.68-5.01L16.319,0a5.6,5.6,0,0,0,1.229,2.221,4.663,4.663,0,0,0,2.281.848s-.484,1.441-4.29,3.569S3.621,11.945,2.485,11.987-.685,12.716.23,11.4Z"
          transform="translate(30.392 125.011)"
        />
        <path
          className={classes.a}
          d="M0,2.769S3.06.167,4.18.014s2.543,1.009,2.314,1.84S3.51,5.812,3.51,5.812"
          transform="translate(45.75 122.243)"
        />
        <path
          className={classes.b}
          d="M0,2.769S3.06.167,4.18.014s2.543,1.009,2.314,1.84S3.51,5.812,3.51,5.812"
          transform="translate(45.75 122.243)"
        />
        <path
          className={classes.a}
          d="M0,2.769S3.06.167,4.18.014s2.543,1.009,2.314,1.84S3.51,5.812,3.51,5.812"
          transform="translate(45.75 122.243)"
        />
        <path
          className={classes.b}
          d="M0,2.769S3.06.167,4.18.014s2.543,1.009,2.314,1.84S3.51,5.812,3.51,5.812"
          transform="translate(45.75 122.243)"
        />
        <path
          className={classes.a}
          d="M7.688,11.335,0,0,9.663,10.284"
          transform="translate(40.597 111.973)"
        />
        <path
          className={classes.b}
          d="M7.688,11.335,0,0,9.663,10.284"
          transform="translate(40.597 111.973)"
        />
        <path
          className={classes.a}
          d="M7.688,11.335,0,0,9.663,10.284"
          transform="translate(40.597 111.973)"
        />
        <path
          className={classes.b}
          d="M7.688,11.335,0,0,9.663,10.284"
          transform="translate(40.597 111.973)"
        />
        <path
          className={classes.a}
          d="M0,1.289c.949.279,9.486,9.834,9.486,9.834L1.009,0"
          transform="translate(50.128 125.266)"
        />
        <path
          className={classes.b}
          d="M0,1.289c.949.279,9.486,9.834,9.486,9.834L1.009,0"
          transform="translate(50.128 125.266)"
        />
        <path
          className={classes.a}
          d="M0,9.825,1.7,0Zm8.112-.907c.95.28,9.486,9.834,9.486,9.834L9.121,7.63"
          transform="translate(42.459 117.67)"
        />
        <path
          className={classes.b}
          d="M8.112,8.918c.95.28,9.486,9.834,9.486,9.834L9.121,7.63M0,9.825,1.7,0Z"
          transform="translate(42.459 117.67)"
        />
        <path
          className={classes.a}
          d="M22.642,3.637S18.505,8.189,14.673,8.3,8.9,6.8,5.934,4.561,0,0,0,0"
          transform="translate(98.27 61.631)"
        />
        <path
          className={classes.b}
          d="M22.642,3.637S18.505,8.189,14.673,8.3,8.9,6.8,5.934,4.561,0,0,0,0"
          transform="translate(98.27 61.631)"
        />
        <path
          className={classes.a}
          d="M22.642,3.637S18.505,8.189,14.673,8.3,8.9,6.8,5.934,4.561,0,0,0,0"
          transform="translate(98.27 61.631)"
        />
        <path
          className={classes.b}
          d="M22.642,3.637S18.505,8.189,14.673,8.3,8.9,6.8,5.934,4.561,0,0,0,0"
          transform="translate(98.27 61.631)"
        />
        <path
          className={classes.a}
          d="M91.958,56.338S67.925,38.773,62.925,35.373s-20.854-14.921-23.736-16.7S18.59,4.446,13.131,2.132,6.756-.572,3.95.767,0,2.6,0,2.6"
          transform="translate(6.985 8.742)"
        />
        <path
          className={classes.b}
          d="M91.958,56.338S67.925,38.773,62.925,35.373s-20.854-14.921-23.736-16.7S18.59,4.446,13.131,2.132,6.756-.572,3.95.767,0,2.6,0,2.6"
          transform="translate(6.985 8.742)"
        />
        <path
          className={classes.a}
          d="M91.958,56.338S67.925,38.773,62.925,35.373s-20.854-14.921-23.736-16.7S18.59,4.446,13.131,2.132,6.756-.572,3.95.767,0,2.6,0,2.6"
          transform="translate(6.985 8.742)"
        />
        <path
          className={classes.b}
          d="M91.958,56.338S67.925,38.773,62.925,35.373s-20.854-14.921-23.736-16.7S18.59,4.446,13.131,2.132,6.756-.572,3.95.767,0,2.6,0,2.6"
          transform="translate(6.985 8.742)"
        />
        <path
          className={classes.a}
          d="M6.468,0S.119,4.239.025,6.07s-.059,1.7,2.544,3.747S8.5,14.523,8.5,14.523"
          transform="translate(124.788 73.143)"
        />
        <path
          className={classes.b}
          d="M6.468,0S.119,4.239.025,6.07s-.059,1.7,2.544,3.747S8.5,14.523,8.5,14.523"
          transform="translate(124.788 73.143)"
        />
        <path
          className={classes.a}
          d="M6.468,0S.119,4.239.025,6.07s-.059,1.7,2.544,3.747S8.5,14.523,8.5,14.523"
          transform="translate(124.788 73.143)"
        />
        <path
          className={classes.b}
          d="M6.468,0S.119,4.239.025,6.07s-.059,1.7,2.544,3.747S8.5,14.523,8.5,14.523"
          transform="translate(124.788 73.143)"
        />
        <path
          className={classes.a}
          d="M0,0S14.343,11.937,20.065,17.3s10.4,10,10.655,10.91-2.772,2.9-3.391,2.544"
          transform="translate(135.246 84.259)"
        />
        <path
          className={classes.b}
          d="M0,0S14.343,11.937,20.065,17.3s10.4,10,10.655,10.91-2.772,2.9-3.391,2.544"
          transform="translate(135.246 84.259)"
        />
        <path
          className={classes.a}
          d="M0,0S14.343,11.937,20.065,17.3s10.4,10,10.655,10.91-2.772,2.9-3.391,2.544"
          transform="translate(135.246 84.259)"
        />
        <path
          className={classes.b}
          d="M0,0S14.343,11.937,20.065,17.3s10.4,10,10.655,10.91-2.772,2.9-3.391,2.544"
          transform="translate(135.246 84.259)"
        />
        <path
          className={classes.a}
          d="M0,1.709S5.688-.181,7.18.014a43.211,43.211,0,0,1,9.6,2.729c3.62,1.577,5.137,2.018,6.375,1.7s1.466-.848,1.466-.848l5.01,2.755s-2.068.848-1.424,1.78S33.187,11.28,34.9,13.1s2.026,2.221,1.695,2.9a17.461,17.461,0,0,1-2.73,2.544"
          transform="translate(34.179 95.97)"
        />
        <path
          className={classes.b}
          d="M0,1.709S5.688-.181,7.18.014a43.211,43.211,0,0,1,9.6,2.729c3.62,1.577,5.137,2.018,6.375,1.7s1.466-.848,1.466-.848l5.01,2.755s-2.068.848-1.424,1.78S33.187,11.28,34.9,13.1s2.026,2.221,1.695,2.9a17.461,17.461,0,0,1-2.73,2.544"
          transform="translate(34.179 95.97)"
        />
        <path
          className={classes.a}
          d="M28.423,4.965,33.729.116ZM0,1.709S5.688-.181,7.18.014a43.21,43.21,0,0,1,9.6,2.73c3.62,1.577,5.137,2.018,6.375,1.7s1.466-.848,1.466-.848l5.01,2.755s-2.068.848-1.424,1.78S33.187,11.28,34.9,13.1s2.026,2.221,1.695,2.9a17.463,17.463,0,0,1-2.73,2.544"
          transform="translate(34.179 95.97)"
        />
        <path
          className={classes.b}
          d="M0,1.709S5.688-.181,7.18.014a43.211,43.211,0,0,1,9.6,2.729c3.62,1.577,5.137,2.018,6.375,1.7s1.466-.848,1.466-.848l5.01,2.755s-2.068.848-1.424,1.78S33.187,11.28,34.9,13.1s2.026,2.221,1.695,2.9a17.461,17.461,0,0,1-2.73,2.544M28.423,4.965,33.729.116Z"
          transform="translate(34.179 95.97)"
        />
      </g>
    </svg>
  );
}

export default Plane;
