import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import MediaQueries from './MediaQueries';

type Props = {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  type?: 'button' | 'submit';
};

const useStyles = createUseStyles({
  buttonRoot: {
    fontFamily: ["'Roboto'", 'sans-serif'],
    borderRadius: '5px',
    color: '#fff',
    fontSize: 14,
    lineHeight: '48px',
    backgroundColor: '#269f92',
    backgroundImage: 'linear-gradient(90deg, #269F92 0%, #1fd0bd 100%)',
    width: '100%',
    cursor: 'pointer',
    '&:hover, &:disabled': {
      backgroundImage: 'none',
    },
    '&:disabled': {
      backgroundColor: '#c3c9c8',
    },
  },
  [MediaQueries.DESKTOP]: {
    buttonRoot: {
      fontSize: 18,
      lineHeight: '56px',
    },
  },
});

const Button = ({
  className = '',
  type = 'button',
  disabled = false,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <button
      className={classNames(classes.buttonRoot, className)}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
