import config from '../../config';
import getRedirectSearchParam from '../../util/getRedirectSearchParam';
import { LOCALHOST } from '../../constants/constants';
import isFreshUrl from '../../util/isFreshUrl';

const LOGIN = '/login';
const TRUSTED_HOSTS = /^https:\/\/[a-z-.]+hummingbirdtech.com/gi;

function getRedirectURL(url: string): string {
  if (url && (url.startsWith(LOCALHOST) || url.match(TRUSTED_HOSTS))) {
    return url.replace(LOGIN, '');
  }
  return config.appUrl;
}

export default (
  token: string,
  userId: string,
  language: string,
  unitSystem: string,
) => {
  const redirectSearchParam = getRedirectSearchParam();
  const url = getRedirectURL(redirectSearchParam);
  const concatenator = url.includes('?') ? '&' : '?';

  if (isFreshUrl(redirectSearchParam)) {
    window.location.replace(
      `${url}${concatenator}token=${token}&user=${userId}&unitSystem=${unitSystem}`,
    );
  } else {
    window.location.replace(
      `${url}${concatenator}token=${token}&user=${userId}&lang=${language}`,
    );
  }
};
