import React, { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { Variant, useStyles } from './SquareLink';

type Props = {
  children: ReactNode;
  className?: string;
  onClick: (event: any) => void;
  variant?: Variant;
};

const SquareLink = ({
  children,
  className = '',
  onClick = () => {},
  variant = 'normal',
}: Props) => {
  const classes = useStyles();

  const combinedClassnames = classNames(
    classes.squareLinkRoot,
    classes[variant],
    className,
  );

  // get the element dynamically some how?

  return (
    <button onClick={onClick} className={combinedClassnames}>
      {children}
    </button>
  );
};

export default SquareLink;
