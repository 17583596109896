import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

function useLocation() {
  const url = window.location.pathname + window.location.search;
  return url;
}

function GoogleAnalytics() {
  const url = useLocation();

  const { platformAnalyticsCode } = useConfig();

  useEffect(() => {
    ReactGA.initialize(platformAnalyticsCode, {
      // set this to true when debugging in the console.
      debug: false,
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(url);
  }, [url]);

  return null;
}

export default GoogleAnalytics;
